import {ComponentShowModal} from "./ComponentShowModal";
import {useContext, useState} from "react";
import {MainContext} from "../../context/MainContext";
import CLoadingAnimation from "../CLoadingAnimation";
import {useNavigate} from "react-router-dom";
import {TProject} from "../../types/TProject";
import {TProjectOffer} from "../../types/TProjectOffer";
import {currencies} from "../../service/commonRequest";
import {parsePrice} from "../../utils/currency";
import {JobStatus} from "../../types/TJob";

interface IProps {
  isOpen: boolean
  project: TProject
  onClose: (refresh?: boolean) => void
  projectTranslations: (key: string) => string
  onOfferSelected: (offer: TProjectOffer) => void
  offersData: TProjectOffer[]
  projectState: JobStatus
}

export function CProjectOfferListByCompanyModal({
                                                  projectTranslations,
                                                  isOpen,
                                                  onClose,
                                                  project,
                                                  offersData,
                                                  projectState,
                                                  onOfferSelected,
                                                }: IProps) {
  const mainContext = useContext(MainContext)

  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  return <>
    <ComponentShowModal onClose={onClose}
                        rightSpacing={18}
                        componentClass={'!overflow-y-hidden !pr-0'}
                        title={`${projectTranslations('gelen-teklifler')} (${project.offerCount})`}
                        component={
                          isOpen ? <div className={'w-full pt-0 pb-[40px] flex-col overflow-y-hidden'}>
                            {isLoading ? <CLoadingAnimation size={100}/> :
                              <div className={'flex-col pt-[20px] overflow-y-hidden'}>
                                <div className={'flex pb-[32px]'}>
                                  {projectState &&
                                      <div
                                          className={'div-lightest-purple p-[10px] flex gap-[10px] rounded-[8px]'}>
                                     <span
                                         className={'text-purple text-14-24-700'}>{projectTranslations('proje-durumu')}:&nbsp;</span>
                                          <span
                                              className={'text-grey text-14-24-400'}>{mainContext?.getBackendTranslation(`status_${projectState}`)}</span>
                                      </div>
                                  }
                                </div>

                                <div className={'flex-col gap-[32px] pt-0 overflow-y-auto pr-[18px]'}>
                                  {offersData?.map((offer, index) => {
                                    return <div
                                      className={'flex justify-between items-center gap-[16px]'}>
                                      <div className={'flex-col gap-[8px] items-center flex-1'}>
                                        <img
                                          id={'ppImage'}
                                          className="w-[62px] h-[62px] rounded-full bg-gray-300"
                                          src={offer.professionalPicture}
                                        />
                                        <div className={'flex-col items-center'}>
                                          <p className={'text-12-24-500 text-grey'}>{offer.professionalName}</p>
                                          <p
                                            className={'text-12-24-500 text-grey'}>{offer.professionalProfession}</p>
                                        </div>
                                        <div role={'button'}
                                             onClick={() => navigate(`/profile/Professional/${offer.professionalKeyId}`,{state:{goBack:true}})}
                                             className={'div-lightest-purple p-[10px] flex gap-[10px] rounded-[8px]'}>
                                             <span className={'text-purple text-14-24-700'}>
                                               {projectTranslations('profili-incele')}
                                             </span>
                                        </div>
                                      </div>

                                      <div className={'flex-1 flex justify-center'}>
                                        <div className={'flex-col gap-[12px] justify-center px-[16px]'}>
                                          <p
                                            className={'text-12-24-700'}>{projectTranslations('gelen-teklif-tutar')}</p>
                                          <div className={'flex gap-[16px]'}>

                                            <div
                                              className={'w-[218px] rounded-[16px] border-purple py-[6px] px-[16px] flex justify-center'}>
                                              <p className={'text-12-24-500 text-purple'}>
                                                {(offer.price && offer.price > 0) ?
                                                  `${parsePrice(offer.price)} ${currencies.find(c => c.id == offer.currencyId)?.code}` :
                                                  projectTranslations('teklif-bulunmamakta')
                                                }
                                              </p>
                                            </div>

                                            <div role={'button'}
                                                 onClick={() => {
                                                   onOfferSelected(offer)
                                                   navigate(`#offer=${offer.offerKeyId}`, {replace: true})
                                                 }}
                                                 className={'w-[150px] text-center div-green py-[12px] px-[24px] flex justify-center gap-[10px] rounded-[8px]'}>
                                              <p className={'text-white text-center text-14-16-600'}>
                                                {projectTranslations('teklifi-incele')}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  })}
                                </div>
                              </div>
                            }
                          </div> : undefined
                        }/>
  </>
}
