import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { TSwiper3Loop } from "../types/TComponents";
import { isDesktop } from "../utils/window";

interface PSwiper3Loop {
  data: TSwiper3Loop;
  notActiveEffect?: boolean;
  swipeAmount?: number;
}

function CSwiper1Dukkan({ data, notActiveEffect, swipeAmount }: PSwiper3Loop) {
  const [swiper, setSwiper] = useState<any | undefined>(undefined);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0, 0);
    }
  }, [swiper]);

  useEffect(() => {
    if (!isDesktop()) {
      const els = document.getElementsByClassName("swiper-initialized");
      if (els && els.length > 0) {
        // const padding = (window.innerWidth - 312) / 2;
        const padding = window.innerWidth * 0.05;

        for (let index = 0; index < els.length; index++) {
          const element = els[index];
          // @ts-ignore
          element.style.paddingLeft = `${padding}px`;
          // @ts-ignore
          element.style.paddingRight = `${padding}px`;
        }
      }
    }
  }, []);

  return (
    <div className="w-full flex">
      <Swiper
        // slidesPerView={1.5}
        slidesPerView={"auto"}
        spaceBetween={0}
        className={`swiper `}
        onSwiper={(swiper) => {
          setSwiper(swiper);
        }}
      >
        {data.cards.map((card) => {
          return (
            <SwiperSlide>
              <div className="contentHolder" style={{ height: "100%" }}>
                {card}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default CSwiper1Dukkan;
