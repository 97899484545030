export interface TranslationModel {
    name: string;
    parent: string;
}

export const DokumanTranslation: TranslationModel = {
    name: 'dokuman-sayfa',
    parent: 'sayfa'
}

export const DilTranslation: TranslationModel = {
    name: 'dil',
    parent: 'dil'
}

export const ProfilTranslation: TranslationModel = {
    name: 'profil-sayfa',
    parent: 'sayfa'
}
export const ProjeTranslation: TranslationModel = {
    name: 'proje-sayfa',
    parent: 'sayfa'
}
