interface IProps {
  project: any
  navigate: any
  setShowOffers: any
  getTranslation: any
  mini: boolean
}

export default function CInspectOffersButton({project, navigate, setShowOffers, getTranslation, mini}: IProps) {
  return <div role={(project?.offerCount && project.offerCount > 0) ? 'button' : ''}
              className={`mt-[40px] purpleCard py-[16px] px-[20px] flex-col gap-[24px] ${mini?'!rounded-[28px] !p-[4px]':''}`}
              onClick={() => {
                if (project?.offerCount && project.offerCount > 0) {
                  setShowOffers(true)
                  navigate(`#offers`, {replace: true})
                }
              }}>
    {!mini &&
        <p className={'text-white text-20-24-700'}>{getTranslation('gelen-teklifleri-incele')}</p>
    }
    <div
      className={'rounded-[24px] bg-white py-[17px] px-[16px] max-w-[228px] flex justify-center items-center gap-[16px] text-15-16-500'}>
      {(project?.offerCount ?? 0) > 0
        ? getTranslation('adet-teklifin-var').replace('{val}', '' + project!.offerCount)
        : getTranslation('teklif-bulunmuyor')}
    </div>
  </div>
}
