import { useContext, useEffect, useState } from "react";
import CContactUs from "src/components/CContactUs";
import CHelpToWorkRow from "src/components/CHelpToWorkRow";
import CJobsSwiper from "src/components/CJobsSwiper";
import CLoadingAnimation from "src/components/CLoadingAnimation";
import COnesWithPofft from "src/components/COnesWithPofft";
import CPartnersRow from "src/components/CPartnersRow";
import CTopBlogs from "src/components/CTopBlogs";
import CWhyMoveToPofft from "src/components/CWhyMoveToPofft";
import { MainContext } from "src/context/MainContext";
import { DataProps } from "src/types/DataProps";
import { Translation } from "src/types/Translation";
import { filter } from "src/utils/request";
import CDukkanRow from "../components/CDukkanRow";
import CHeroHome from "../components/CHeroHome";
import CPageSection from "../components/CPageSection";
import {
  OPofftHelpToWorkSection,
  OPofftPartnersSection
} from "../objects/OSections";
import { getTranslations } from "../service/commonRequest";
import { PageState } from "../types/Context";

export default function Home() {
  const mainContext = useContext(MainContext);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);


  const [data, setData] = useState<DataProps[] | undefined>(undefined);
  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);

  function getTranslation(parent: string, child: string): string {
    return translations?.get(parent)?.sentences.get(child) ?? "";
  }

  useEffect(() => {
    if (mainContext?.publicToken) {
      getTranslations('ana-sayfa', (translations, data) => {
        setData(data);
        setTranslations(translations);
      })
    }
  }, [mainContext?.publicToken]);

  const loading =
    data == undefined ||
    mainContext?.translationsLoaded != true ||
    translations == undefined;

  function handleTarget(target: string) {
    debugger
    if (target.includes('registerKey')) {
      mainContext?.setExtra({ registerKey: target.split('=')[1] })
      mainContext?.setSigninupState(PageState.Signup);
    } else {
      var element = document.getElementById(target);
      if (element) {
        const scrollToY = element!.offsetTop - 150;
        console.log(`Y: ${target} px: ${scrollToY}`);
        window.scrollTo(0, scrollToY);
      }
    }
  }

  useEffect(() => {
    if (
      data != undefined &&
      mainContext?.translationsLoaded == true &&
      translations != undefined
    ) {
      setTimeout(() => {
        const refs = window.location.href.split("#");
        if (refs !== null && refs.length > 1) {
          handleTarget(refs[1]);
        }
      }, 250);
    }
  }, [data, translations, mainContext?.translationsLoaded]);

  return loading ? (
    <div className="h-[100vh] w-[100vw] flex-col justify-center">
      <CLoadingAnimation />
    </div>
  ) : (
    <>
      <CHeroHome
        data={filter(data, "hero")}
        getTranslation={(key: string) => getTranslation("hero", key)}
      />
      <CPageSection
        data={{
          title: getTranslation("iste-pofft", "text1"),
          header: getTranslation("iste-pofft", "text2"),
          sub: getTranslation("iste-pofft", "text3"),
          component: <CJobsSwiper />,
          componentClass: "no-max-width-mobile",
        }}
      />

      <CPageSection
        data={{
          title: getTranslation("pofft-dukkan", "text1"),
          header: getTranslation("pofft-dukkan", "text2"),
          sub: getTranslation("pofft-dukkan", "text3"),
          component: (
            <div className="flex-col">
              <CDukkanRow getTranslation={getTranslation} />
              {/*<CLoadingAnimation />*/}
            </div>
          ),
          componentClass: "no-max-width-mobile",
        }}
      />

      {/*<CPageSection*/}
      {/*  data={{*/}
      {/*    title: getTranslation("pofft-web3", "text1"),*/}
      {/*    header: getTranslation("pofft-web3", "text2"),*/}
      {/*    sub: getTranslation("pofft-web3", "text3"),*/}
      {/*    component: <CJobsSwiperSpecific tag="web3"/>,*/}
      {/*    componentClass: "no-max-width-mobile",*/}
      {/*  }}*/}
      {/*/>*/}

      <a id="pofft-partner">
        <CPageSection
          data={{
            title: getTranslation("pofft-partner", "text1"),
            header: getTranslation("pofft-partner", "text2"),
            sub: getTranslation("pofft-partner", "text3"),
            component: (
              <CPartnersRow
                data={{
                  ...OPofftPartnersSection(
                    (key: string) => getTranslation("pofft-partner", key),
                    filter(data, "pofft-partner")
                  ),
                  parentClass: "mobileonly:pt-0",
                }}
              />
            ),
            parentClass: "!pt-[0px] mobileonly:!pb-0",
            componentClass: "no-max-width-mobile",
          }}
        />
      </a>
      <CPageSection
        data={{ component: <CWhyMoveToPofft translation={translations} /> }}
      />
      <a id="pofft-gelecek-katar">
        <CPageSection
          data={{
            header: getTranslation("pofft-gelecek-katar", "title"),
            sub: getTranslation("pofft-gelecek-katar", "text"),
            component: (
              <CHelpToWorkRow
                data={{
                  ...OPofftHelpToWorkSection(
                    (key: string) => getTranslation("pofft-gelecek-katar", key),
                    filter(data!, "pofft-gelecek-katar")
                  ),
                  parentClass: "mobileonly:pt-0",
                }}
              />
            ),
            parentClass: "!pt-[0px] mobileonly:!pb-0",
            componentClass: "no-max-width-mobile",
          }}
        />
      </a>

      <a id="blog">
        <CPageSection
          data={{
            miniHeader: mainContext?.getTranslation("common", "blog"),
            header: getTranslation("blog", "text2"),
            component: <CTopBlogs />,
            componentClass: "no-max-width-mobile",
          }}
        />
      </a>

      <CPageSection
        data={{
          miniHeader: getTranslation("pofftlu-olanlar", "text1"),
          header: getTranslation("pofftlu-olanlar", "text2"),
          sub: getTranslation("pofftlu-olanlar", "text3"),
          component: <COnesWithPofft parentClass="mobileonly:pt-0" />,
          parentClass: "!pt-[0px]",
          componentClass: "no-max-width-mobile",
        }}
      />
      <CPageSection
        data={{
          component: <CContactUs />,
        }}
      />
    </>
  );
}
