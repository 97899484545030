export function DocumentGizlilikBeyani() {
    return <>
        <div className="documentContainer">

            <p className="documentText documentTextBig">
                Web sitemiz üzerindeki mesajlaşma arayüzleri aracılığıyla iletilen her türlü bilginin, tarafımızca ticari sır olarak addedileceğini ve bunun <a href="https://www.pofft.com" style={{ color: '#333' }}>pofft.com</a> tarafından gizlilik prensiplerine uygun şekilde saklanarak 3.kişiler ile paylaşılmayacağını taahhüt etmekle beraber, hangi mecra üzerinden iletilmiş olursa olsun, söz konusu bilgilerin iletildiği tarafların (hizmet alan ve hizmet verenin) birbirlerine karşı olan gizlilik yükümlülüklerine dair hiçbir taahhütte bulunmadığımızı ve bu konuda herhangi bir sorumluluğumuzun da bulunmadığını beyan ederiz.
            </p>

        </div>
    </>
}