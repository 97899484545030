export function DocumentCerezPolitikasi() {
    return <>
        <div className="documentContainer">

            <p className="documentText">
                <a href="https://www.pofft.com" style={{ color: '#333' }}>pofft.com</a> olarak web sitemizde yalnızca Operasyonel (Zorunlu) Çerezler kullanmaktayız. Operasyonel çerezler, web sitemizin bazı temel özelliklerinden yararlanmanızı sağlar. Tarayıcı ayarlarınız aracılığıyla çerezlerimizi engeller veya başka bir şekilde reddederseniz bazı özellikler ve hizmetler teknik engeller nedeniyle çalışmayabilir. Örneğin, ödeme aşamasına geçemez veya oturum açmanızı gerektiren hizmetleri kullanamazsınız. Bu bakımdan Operasyonel Çerezeler, seçime bağlı olmaksızın zorunlu olarak kullanılmaktadır.
            </p>

        </div>
    </>
}