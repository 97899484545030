export interface Translation {
    sentences: Map<string, string>
    pictres?: any
}

export interface TranslationParent {
    translation: Translation
}

export function parseTranslation(resp: any, language?: string | null): Map<string, Translation> {

    const map = new Map<string, Translation>();

    for (var i = 0; i < resp.length; i++) {
        const obj = resp[i];
        const tmap = new Map<string, string>();
        for (var o = 0; o < obj.pageWordModelList.length; o++) {
            const tObj = obj.pageWordModelList[o];
            for (var p = 0; p < tObj.valueList.length; p++) {
                const iObj = tObj.valueList[p];
                if (iObj.languageCode == (language ?? 'tr')) {
                    tmap.set(tObj.parentWord, iObj.value.replace("\\n", "<br/>"));
                    break;
                }
            }
        }
        const trans: Translation = { sentences: tmap };
        map.set(obj.name, trans);
    }

    return map;
}
