import {useContext} from "react";
import {MainContext} from "../context/MainContext";
import {isDesktop} from "../utils/window";
import {TDukkanJob} from "../types/TComponents";
import {Button} from "@mui/material";
import {PageState} from "../types/Context";

interface PDukkanJobCard {
  data: TDukkanJob;
  getTranslation: (parent: string, child: string) => string;
}

function CDukkanJobCard({
                          data,
                          getTranslation,
                        }: PDukkanJobCard) {

  const mainContext = useContext(MainContext);
  const IsDesktop = isDesktop();

  function seeJob() {
    if (mainContext?.userToken != undefined && mainContext?.userData != undefined) {
      debugger
      if (mainContext?.isExpired('')) {
        //Token is expired and will automatically show login modal
      } else {
        window.location.href = 'https://dukkan.pofft.com'
      }
    } else {
      mainContext?.setSigninupState(PageState.Signin)
    }
  }

  return (
    <div
      className={`mt-[24px] h-[250px] flex-1 overflow-hidden rounded-[16px] p-[16px] bg-white flex-col w-full`}
      style={{boxShadow: "0px 20px 80px #EBECF0"}}
    >
      <div className={'flex-col gap-[24px] flex-1'}>
        <div className={'flex gap-[16px]'}>
          <div className={'w-[40px] h-[40px] rounded-full'}>
            <img
              className="w-full h-full rounded-full bg-gray-300"
              src={data.image}/>
          </div>
          <div className={'flex-col gap-[4px] items-start'}>
            <div className={'text-16-24-500 text-black'}>
              {data.relationInfo??`-`}
            </div>
            <div className={'text-14-18-500 text-grey'}>
              Şirket
            </div>
          </div>
        </div>
        <div className={'flex-col gap-[12px] items-start flex-1'}>
          <p className={'text-black text-16-24-600'}>
            {data.jobTitle}
          </p>
          <p className={'text-light-grey text-14-18-400'}>
            {data.jobDescription}
          </p>
        </div>
        <div className={'flex gap-[12px] justify-between'}>
          <div className={'flex items-end flex-wrap'}>
            <span
              className={'text-light-grey text-12-22-600'}>{getTranslation('pofft-dukkan', 'den-baslayan-before')}</span>
            <span className={'text-black text-18-24-600'}>{`₺${data.price}`}</span>
            <span
              className={'text-light-grey text-12-22-600'}>{getTranslation('pofft-dukkan', 'den-baslayan-after')}</span>
          </div>
          <Button className="purpleButton purpleButtonMiniMobile" onClick={seeJob}>
            <span>{mainContext!.getTranslation('common', 'incele')}</span>
          </Button>
        </div>
      </div>
    </div>

  );
}

export default CDukkanJobCard;
