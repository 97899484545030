export function DocumentKisiselVerileriKayitSaklama() {
    return <>
        <div className="documentContainer">
            <h1 className="documentHeader">KİŞİSEL VERİLERİ KAYIT, SAKLAMA VE İMHA PROSEDÜRÜ</h1>

            <h2 className="documentTitle">1. GİRİŞ</h2>
            <div className="subSection">1.1. Amaç</div>
            <p className="documentText">
                Kişisel Verileri Saklama, Kayıt ve İmha Prosedürü (“Prosedür”), POFFT DİJİTAL ÇÖZÜMLER TİCARET ANONİM ŞİRKETİ (“Şirket”) tarafından gerçekleştirilmekte olan saklama ve imha faaliyetlerine ilişkin iş ve işlemler konusunda usul ve esasları belirlemek amacıyla hazırlanmıştır.
            </p>
            <p className="documentText">
                Kişisel verilerin kaydı, saklanması ve imhasına ilişkin iş ve işlemler, Şirket tarafından bu doğrultuda hazırlanmış olan işbu Prosedüre uygun olarak gerçekleştirilir.
            </p>

            <div className="subSection">1.2. Kapsam</div>
            <p className="documentText">
                Şirket çalışanları, çalışan adayları, hizmet sağlayıcıları, tedarikçiler, müşteriler, ziyaretçiler ve diğer üçüncü kişilere ait kişisel veriler, işbu Prosedür kapsamında olup; Şirket’in sahip olduğu ya da Şirketçe yönetilen kişisel verilerin işlendiği tüm kayıt ortamları ve kişisel veri işlenmesine yönelik faaliyetlerde işbu Prosedür uygulanır.
            </p>

            <div className="subSection">1.3. Kısaltmalar ve Tanımlar</div>
            <ul className="documentList">
                <li><strong>Alıcı Grubu:</strong> Veri sorumlusu Şirket tarafından kişisel verilerin aktarıldığı gerçek veya tüzel kişi kategorisi.</li>
                <li><strong>Açık Rıza:</strong> Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rıza.</li>
                <li><strong>Anonim Hale Getirme:</strong> Kişisel verilerin, başka verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hale getirilmesi.</li>
                <li><strong>Çalışan:</strong> Şirket personeli.</li>
                <li><strong>Elektronik Ortam:</strong> Kişisel verilerin elektronik aygıtlar ile oluşturulabildiği, okunabildiği, değiştirilebildiği ve yazılabildiği ortamlar.</li>
                <li><strong>Elektronik Olmayan Ortam:</strong> Elektronik ortamların dışında kalan tüm yazılı, basılı, görsel vb. diğer ortamlar.</li>
                <li><strong>Hizmet Sağlayıcı:</strong> Şirket ile belirli bir sözleşme çerçevesinde hizmet sağlayan gerçek veya tüzel kişi.</li>
                <li><strong>İlgili Kişi:</strong> Kişisel verisi işlenen gerçek kişi.</li>
                <li><strong>İlgili Kullanıcı:</strong> Verilerin teknik olarak depolanması, korunması ve yedeklenmesinden sorumlu olan kişi ya da birim hariç olmak üzere veri sorumlusu organizasyonu içerisinde veya veri sorumlusundan aldığı yetki ve talimat doğrultusunda kişisel verileri işleyen kişiler.</li>
                <li><strong>İmha:</strong> Kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesi.</li>
                <li><strong>Kanun:</strong> 6698 Sayılı Kişisel Verilerin Korunması Kanunu.</li>
                <li><strong>Kayıt Ortamı:</strong> Tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla işlenen kişisel verilerin bulunduğu her türlü ortam.</li>
                <li><strong>Kişisel Veri:</strong> Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi.</li>
                <li><strong>Kişisel Veri İşleme Envanteri:</strong> Veri sorumlularının iş süreçlerine bağlı olarak gerçekleştirmekte oldukları kişisel verileri işleme faaliyetlerini; kişisel verileri işleme amaçları ve hukuki sebebi, veri kategorisi, aktarılan alıcı grubu ve veri konusu kişi grubuyla ilişkilendirerek oluşturdukları ve kişisel verilerin işlendikleri amaçlar için gerekli olan azami muhafaza edilme süresini, yabancı ülkelere aktarımı öngörülen kişisel verileri ve veri güvenliğine ilişkin alınan tedbirleri açıklayarak detaylandırdıkları envanter.</li>
                <li><strong>Kişisel Verilerin İşlenmesi:</strong> Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, saklanması, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hale getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlem.</li>
                <li><strong>Kurul:</strong> Kişisel Verileri Koruma Kurulu.</li>
                <li><strong>Özel Nitelikli Kişisel Veri:</strong> Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri.</li>
                <li><strong>Periyodik İmha:</strong> Kanun’da yer alan kişisel verilerin işlenme şartlarının tamamının ortadan kalkması durumunda kişisel verileri saklama ve imha politikasında belirtilen ve tekrar eden aralıklarla re’sen gerçekleştirilecek silme, yok etme veya anonim hale getirme işlemi.</li>
                <li><strong>Prosedür:</strong> İşbu Kişisel Verileri Kayıt, Saklama ve İmha Prosedürü.</li>
                <li><strong>Veri İşleyen:</strong> Veri sorumlusunun verdiği yetkiye dayanarak veri sorumlusu adına kişisel verileri işleyen gerçek veya tüzel kişi.</li>
                <li><strong>Veri Kayıt Sistemi:</strong> Kişisel verilerin belirli kriterlere göre yapılandırılarak işlendiği kayıt sistemi.</li>
                <li><strong>Veri Sorumlusu:</strong> Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasında ve yönetilmesinden sorumlu Şirket’i.</li>
                <li><strong>Veri Sorumluları Sicil Bilgi Sistemi:</strong> Veri sorumlularının Sicile başvuruda ve Sicile ilişkin ilgili diğer işlemlerde kullanacakları, internet üzerinden erişilebilen, Kişisel Verileri Koruma Kurumu tarafından oluşturulan ve yönetilen bilişim sistemi.</li>
                <li><strong>VERBİS:</strong> Veri Sorumluları Sicil Bilgi Sistemi.</li>
                <li><strong>Yönetmelik:</strong> 28.10.2017 tarihli Resmi Gazete’de yayımlanan Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi Hakkında Yönetmelik.</li>
            </ul>


            <h2 className="documentTitle">2. SORUMLULUK VE GÖREV DAĞILIMLARI</h2>
            <p className="documentText">
                Şirket’in tüm birimleri ve çalışanları, sorumlu birimlerce Prosedür kapsamında alınmakta olan teknik ve idari tedbirlerin gereği gibi uygulanması, birim çalışanlarının eğitimi ve farkındalığının arttırılması, izlenmesi ve sürekli denetimi ile kişisel verilerin hukuka aykırı olarak işlenmesinin önlenmesi, kişisel verilere hukuka aykırı olarak erişilmesinin önlenmesi ve kişisel verilerin hukuka uygun saklanmasının sağlanması amacıyla kişisel veri işlenen tüm ortamlarda veri güvenliğini sağlamaya yönelik teknik ve idari tedbirlerin alınması konularında sorumlu birimlere aktif olarak destek verir.
            </p>
            <table className="documentTable">
                <caption>Tablo 1: Sorumlu Personel ve Görev Dağılımı</caption>
                <thead>
                    <tr>
                        <th>PERSONEL</th>
                        <th>GÖREVİ</th>
                        <th>SORUMLULUK</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Adı-Soyadı<br />
                            Unvan
                        </td>
                        <td>İrtibat Kişisi</td>
                        <td>Her bir departmanın iş süreçlerinin saklama ve periyodik imha sürelerine uygun gerçekleşip gerçekleşmediğinin gözetimi</td>
                    </tr>
                    <tr>
                        <td>
                            Adı-Soyadı<br />
                            Unvan
                        </td>
                        <td>BİLGİ İŞLEM<br />Kişisel veri saklama ve imha politikası uygulama sorumlusu</td>
                        <td>Görevi dahilinde olan süreçlerin saklama süresine uygunluğunun sağlanması ile periyodik imha süresi uyarınca kişisel veri imha sürecinin yönetimi</td>
                    </tr>
                    <tr>
                        <td>
                            Adı-Soyadı<br />
                            Unvan
                        </td>
                        <td>SATIŞ ve PAZARLAMA<br />Kişisel veri saklama ve imha politikası uygulama sorumlusu</td>
                        <td>Görevi dahilinde olan süreçlerin saklama süresine uygunluğunun sağlanması ile periyodik imha süresi uyarınca kişisel veri imha sürecinin yönetimi</td>
                    </tr>
                    <tr>
                        <td>
                            Adı-Soyadı<br />
                            Unvan
                        </td>
                        <td>FİNANS VE MUHASEBE<br />Kişisel veri saklama ve imha politikası uygulama sorumlusu</td>
                        <td>Görevi dahilinde olan süreçlerin saklama süresine uygunluğunun sağlanması ile periyodik imha süresi uyarınca kişisel veri imha sürecinin yönetimi</td>
                    </tr>
                </tbody>
            </table>

            <h2 className="documentTitle">3. KAYIT ORTAMLARI</h2>
            <p className="documentText">
                Kişisel veriler, Şirket tarafından aşağıdaki ortamlarda hukuka uygun olarak güvenli bir şekilde saklanır:
            </p>

            <table className="documentTable">
                <caption>Tablo 2: Kişisel Veri Saklama Ortamları</caption>
                <thead>
                    <tr>
                        <th>Elektronik Ortamlar</th>
                        <th>Elektronik Olmayan Ortamlar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <ul>
                                <li>Sunucular (Etki alanı, yedekleme, e-posta, veritabanı, web, dosya paylaşım, vb.)</li>
                                <li>Yazılımlar (ofis yazılımları ve Şirketçe kullanılan diğer yazılımlar)</li>
                                <li>Bilgi güvenliği cihazları (güvenlik duvarı, saldırı tespit ve engelleme, günlük kayıt dosyası, antivirüs vb.)</li>
                                <li>Kişisel bilgisayarlar (Masaüstü, dizüstü)</li>
                                <li>Mobil cihazlar (telefon, tablet vb.)</li>
                                <li>Optik diskler (CD, DVD vb.)</li>
                                <li>Çıkartılabilir bellekler (USB, Hafıza Kart vb.)</li>
                                <li>Yazıcı, tarayıcı, fotokopi makinesi</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Kağıt</li>
                                <li>Manuel veri kayıt sistemleri (anket formları, ziyaretçi giriş defteri)</li>
                                <li>Yazılı, basılı, görsel ortamlar</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h2 className="documentTitle">4. SAKLAMA VE İMHAYA İLİŞKİN AÇIKLAMALAR</h2>
            <p className="documentText">
                Şirket tarafından; çalışanlar, çalışan adayları, ziyaretçiler, tedarikçiler, müşteriler, hizmet sağlayıcıları, diğer üçüncü kişiler, kurumların veya kuruluşların çalışanlarına ait kişisel veriler Kanun’a uygun olarak saklanır ve imha edilir. Bu kapsamda saklama ve imhaya ilişkin detaylı açıklamalara aşağıda sırasıyla yer verilmiştir.
            </p>
            <h2 className="documentTitle">4.1. Saklamaya İlişkin Açıklamalar</h2>
            <p className="documentText">
                Kanun’un 3. maddesinde kişisel verilerin işlenmesi kavramı tanımlanmış, 4. maddesinde işlenen kişisel verinin işlendikleri amaçla
                bağlantılı, sınırlı ve ölçülü olması ve ilgili mevzuatta öngörülen veya işlendikleri amaç için gerekli süre kadar muhafaza edilmesi
                gerektiği belirtilmiş, 5. ve 6. maddelerinde ise kişisel verilerin işleme şartları sayılmıştır. Buna göre, Şirket faaliyetleri çerçevesinde
                kişisel veriler, ilgili mevzuatta öngörülen veya işleme amaçlarına uygun süre kadar saklanır.
            </p>

            <h2 className="documentTitle">4.1.1. Kişisel Sağlık Verileri</h2>
            <p className="documentText">
                Şirket tarafından toplanan ve saklanan çalışanlara ilişkin sağlık verileri, yalnızca Şirket bünyesinde görev alan işyeri hekimi
                tarafından işlenebilecek ve işyeri hekimine ait özel odada ve kilitli dolaplarda saklanabilecektir.
            </p>
            <p className="documentText">
                Zorunlu hallerde, söz konusu sağlık verilerinin bulunduğu dolaba erişim, yalnızca İrtibat Kişisi tarafından sağlanabilecek, başkaca hiçbir
                çalışan veya yetkilinin söz konusu verilere erişimi söz konusu olmayacak ve verilerin kullanılması ile ilgili yapılması gereken işin sona
                ermesi ile birlikte, söz konusu veriler aynı yere kaldırılacak ve aynı şekilde kilitli olarak muhafaza edilecektir.
            </p>

            <h2 className="documentTitle">4.1.2. Diğer Özel Nitelikli Kişisel Veriler</h2>
            <p className="documentText">
                Gerek Şirket çalışanları gerekse 3. Kişilere ait olan özel nitelikli kişisel veriler, işlenme sebebine bakılmaksızın, yalnızca İrtibat Kişisinin
                erişebileceği ve sadece bu verilerin yer aldığı belge, kayıt vb. ortamları saklamak üzere özel olarak ayrılmış kilitli dolaplarda
                muhafaza edilecektir.
            </p>

            <h2 className="documentTitle">4.1.3. Saklamayı Gerektiren Hukuki Sebepler</h2>
            <p className="documentText">
                Şirket’in faaliyetleri çerçevesinde işlenen kişisel veriler, ilgili mevzuatta öngörülen süre kadar Şirket nezdinde muhafaza edilir.
            </p>
            <p className="documentText">
                Bu kapsamda kişisel veriler;
            </p>

            <ul className="documentList">
                <li>6698 sayılı Kişisel Verilerin Korunması Kanunu,</li>
                <li>6098 sayılı Türk Borçlar Kanunu,</li>
                <li>213 sayılı Vergi Usul Kanunu ve ilgili diğer vergi mevzuatı,</li>
                <li>5510 sayılı Sosyal Sigortalar ve Genel Sağlık Sigortası Kanunu,</li>
                <li>5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun,</li>
                <li>6331 sayılı İş Sağlığı ve Güvenliği Kanunu,</li>
                <li>4857 sayılı İş Kanunu,</li>
                <li>6102 sayılı Türk Ticaret Kanunu,</li>
                <li>İşyeri Bina ve Eklentilerinde Alınacak Sağlık ve Güvenlik Önlemlerine İlişkin Yönetmelik.</li>
            </ul>
            <p className="documentText">
                Bu kanunlar uyarınca yürürlükte olan diğer ikincil düzenlemeler çerçevesinde öngörülen saklama süreleri kadar saklanmaktadır.
            </p>

            <h2 className="documentTitle">4.1.4. Saklamayı Gerektiren İşleme Amaçları
            </h2>
            <p className="documentText">
                Şirket, faaliyetleri çerçevesinde işlemekte olduğu kişisel verileri aşağıdaki amaçlar doğrultusunda saklar.
            </p>

            <ul className="documentList">
                <li>İnsan kaynakları süreçlerini yürütmek,</li>
                <li>İş sağlığı ve güvenliği süreçlerini yürütmek,</li>
                <li>Şirket güvenliğini sağlamak,</li>
                <li>Bilgi teknolojileri süreçlerini yürütmek,</li>
                <li>Sistem erişimini ve güvenliğini sağlamak,</li>
                <li>İmzalanan sözleşmeler ve protokoller neticesinde iş ve edimleri ifa edebilmek,</li>
                <li>Müşteri raporlamalarını yapabilmek,</li>
                <li>Satış ve pazarlama süreçlerini yerine getirmek,</li>
                <li>Sponsorluk ve kurumsal iletişim faaliyetlerini yerine getirmek,</li>
                <li>Müşteri ilişkileri ve memnuniyeti süreçlerini yürütmek,</li>
                <li>Bayi kanal yönetimi süreçlerini yürütmek,</li>
                <li>Yasal düzenlemelerin gerektirdiği veya zorunlu kıldığı şekilde, hukuki yükümlülüklerin yerine getirilmesini sağlamak,</li>
                <li>Şirket ile iş ilişkisinde bulunan gerçek/tüzel kişilerle irtibat sağlamak,</li>
                <li>Yasal raporlamalar yapmak,</li>
                <li>İleride doğabilecek hukuki uyuşmazlıklarda delil olarak ispat yükümlülüğünü yerine getirmek.</li>
            </ul>

            <h2 className="documentTitle">4.2. İmhayı Gerektiren Sebepler
            </h2>
            <p className="documentText">
                Kişisel veriler;
            </p>

            <ul className="documentList">
                <li>İşlenmesine esas teşkil eden ilgili mevzuat hükümlerinin değiştirilmesi veya ilgası,</li>
                <li>İşlenmesini veya saklanmasını gerektiren amacın ortadan kalkması,</li>
                <li>Kişisel verileri işlemenin sadece açık rıza şartına istinaden gerçekleştiği hallerde, ilgili kişinin açık rızasını geri alması,</li>
                <li>Kanun’un 11. maddesi gereği ilgili kişinin hakları çerçevesinde kişisel verilerinin silinmesi ve yok edilmesine ilişkin yaptığı başvurunun Şirket tarafından kabul edilmesi,</li>
                <li>Şirket’in, ilgili kişi tarafından kişisel verilerinin silinmesi, yok edilmesi veya anonim hale getirilmesi talebi ile kendisine yapılan başvuruyu reddetmesi, verdiği cevabı yetersiz bulması veya Kanun’da öngörülen süre içinde cevap vermemesi hallerinde; Kurul’a şikâyette bulunması ve bu talebin Kurul tarafından uygun bulunması,</li>
                <li>Kişisel verilerin saklanmasını gerektiren azami sürenin geçmiş olması ve kişisel verileri daha uzun süre saklamayı haklı kılacak herhangi bir şartın mevcut olmaması,</li>
            </ul>

            <p className="documentText">
                durumlarında, Şirket tarafından ilgili kişinin talebi üzerine silinir, yok edilir ya da re’sen silinir, yok edilir veya anonim hale getirilir.
            </p>



            <h2 className="documentTitle">4.3. İmhanın Tutanak Altına Alınması
            </h2>
            <p className="documentText">
                Her bir imha sürecinin sonunda ilgili imha işlemi, bir tutanak ile imha sürecini gerçekleştiren departman sorumlusu ve İrtibat Kişisi tarafından kayıt altına alınır. Söz konusu tutanak 5 yıl süreyle Şirket bünyesinde saklanır.
            </p>

            <h2 className="documentTitle">5. TEKNİK VE İDARİ TEDBİRLER</h2>
            <p className="documentText">
                Kişisel verilerin güvenli bir şekilde saklanması, hukuka aykırı olarak işlenmesi ve erişilmesinin önlenmesi ile kişisel verilerin hukuka uygun olarak imha edilmesi için Kanun’un 12. maddesiyle 6. maddesinin dördüncü fıkrası gereği özel nitelikli kişisel veriler için Kurul tarafından belirlenerek ilan edilen yeterli önlemler çerçevesinde ve Şirket tarafından ihdas edilen Özel Nitelikli Kişisel Veri İşleme Prosedürü kapsamında, tarafından teknik ve idari tedbirler alınır.
            </p>
            <h2 className="documentTitle">5.1.  Teknik Tedbirler</h2>
            <p className="documentText">Şirket tarafından, işlediği kişisel verilerle ilgili olarak alınan teknik tedbirler aşağıda sayılmıştır:
            </p>

            <ul className="documentList">
                <li>Sızma (Penetrasyon) testleri ile Şirket bilişim sistemlerine yönelik risk, tehdit, zafiyet ve varsa açıklıklar ortaya çıkarılarak gerekli önlemler alınmaktadır.</li>
                <li>Bilgi güvenliği olay yönetimi ile gerçek zamanlı yapılan analizler sonucunda bilişim sistemlerinin sürekliliğini etkileyecek riskler ve tehditler sürekli olarak izlenmektedir.</li>
                <li>Bilişim sistemlerine erişim ve kullanıcıların yetkilendirilmesi, erişim ve yetki matrisi ile kurumsal aktif dizin üzerinden güvenlik politikaları aracılığı ile yapılmaktadır.</li>
                <li>Şirket’in bilişim sistemleri teçhizatı, yazılım ve verilerin fiziksel güvenliği için gerekli önlemler alınmaktadır.</li>
                <li>Çevresel tehditlere karşı bilişim sistemleri güvenliğinin sağlanması için, donanımsal (sistem odasına sadece yetkili personelin girişini sağlayan erişim kontrol sistemi, 7/24 çalışan izleme sistemi, yerel alan ağını oluşturan kenar anahtarların fiziksel güvenliğinin sağlanması, yangın söndürme sistemi, iklimlendirme sistemi vb.) ve yazılımsal (güvenlik duvarları, atak önleme sistemleri, ağ erişim kontrolü, zararlı yazılımları engelleyen sistemler vb.) önlemler alınmaktadır.</li>
                <li>Kişisel verilerin hukuka aykırı işlenmesini önlemeye yönelik riskler belirlenmekte, bu risklere uygun teknik tedbirlerin alınması sağlanmakta ve alınan tedbirlere yönelik teknik kontroller yapılmaktadır.</li>
                <li>Şirket içerisinde erişim prosedürleri oluşturularak kişisel verilere erişim ile ilgili raporlama ve analiz çalışmaları yapılmaktadır.</li>
                <li>Kişisel verilerin bulunduğu saklama alanlarına erişimler kayıt altına alınarak uygunsuz erişimler veya erişim denemeleri kontrol altında tutulmaktadır.</li>
                <li>Şirket, silinen kişisel verilerin ilgili kullanıcılar için erişilemez ve tekrar kullanılamaz olması için gerekli tedbirleri almaktadır.</li>
                <li>Güvenlik açıkları takip edilerek uygun güvenlik yamaları yüklenmekte ve bilgi sistemleri güncel halde tutulmaktadır.</li>
                <li>Kişisel verilerin işlendiği elektronik ortamlarda güçlü parolalar kullanılmaktadır.</li>
                <li>Kişisel verilerin işlendiği elektronik ortamlarda güvenli kayıt tutma (loglama) sistemleri kullanılmaktadır.</li>
                <li>Kişisel verilerin güvenli olarak saklanmasını sağlayan veri yedekleme programları kullanılmaktadır.</li>
                <li>Elektronik olan ortamlarda saklanan kişisel verilere erişim, erişim prensiplerine göre sınırlandırılmaktadır.</li>
                <li>Şirket internet sayfasına erişimde güvenli protokol (https) kullanılarak SHA 256 Bit RSA algoritmasıyla şifrelenmektedir.</li>
                <li>Özel nitelikli kişisel verilerin güvenliğine yönelik ayrı bir prosedür belirlenmiştir.</li>
                <li>Özel nitelikli kişisel veri işleme süreçlerinde yer alan çalışanlara yönelik özel nitelikli kişisel veri güvenliği konusunda eğitimler verilmiş, gizlilik sözleşmeleri yapılmış, verilere erişim yetkisine sahip kullanıcıların yetkileri tanımlanmıştır.</li>
                <li>Özel nitelikli kişisel verilerin işlendiği, muhafaza edildiği ve/veya erişildiği elektronik ortamlar kriptografik yöntemler kullanılarak muhafaza edilmekte, kriptografik anahtarlar güvenli ortamlarda tutulmakta, tüm işlem kayıtları loglanmakta, ortamların güvenlik güncellemeleri sürekli takip edilmekte, gerekli güvenlik testlerinin düzenli olarak yaptırılmakta ve test sonuçları kayıt altına alınmaktadır.</li>
                <li>Özel nitelikli kişisel veriler e-posta yoluyla aktarılması gerekiyorsa şifreli olarak kurumsal e-posta adresiyle veya KEP hesabı kullanılarak aktarılmaktadır. Taşınabilir bellek, CD, DVD gibi ortamlar yoluyla aktarılması gerekiyorsa kriptografik yöntemlerle şifrelenmekte ve kriptografik anahtar farklı ortamda tutulmaktadır. Farklı fiziksel ortamlardaki sunucular arasında aktarma gerçekleştiriliyorsa, sunucular arasında VPN kurularak veya sFTP yöntemiyle veri aktarımı gerçekleştirilmektedir. Kağıt ortamı yoluyla aktarımı gerekiyorsa evrakın çalınması, kaybolması ya da yetkisiz kişiler tarafından görülmesi gibi risklere karşı gerekli önlemler alınmakta ve evrak “gizli” formatta gönderilmektedir.</li>
            </ul>


            <h2 className="documentTitle">5.2 İdari Tedbirler</h2>
            <p className="documentText">
                Şirket tarafından, işlediği kişisel verilerle ilgili olarak alınan idari tedbirler aşağıda sayılmıştır:
            </p>

            <ul className="documentList">
                <li>Çalışanların niteliğinin geliştirilmesine yönelik, kişisel verilerin hukuka aykırı olarak işlenmenin önlenmesi, kişisel verilerin hukuka aykırı olarak erişilmesinin önlenmesi, kişisel verilerin muhafazasının sağlanması, iletişim teknikleri, teknik bilgi beceri ve ilgili diğer mevzuat hakkında belli aralıklarla eğitimler verilmektedir.</li>
                <li>Çalışanlar için yetki matrisi oluşturulmuştur.</li>
                <li>Kişisel veriler mümkün olduğunca azaltılmaktadır.</li>
                <li>Şirket tarafından yürütülen faaliyetlere ilişkin çalışanlara gizlilik ve kişisel verilerin korunması taahhütnameleri imzalatılmaktadır.</li>
                <li>Güvenlik politika ve prosedürlerine uymayan çalışanlara yönelik uygulanmak üzere gerekli hükümler Disiplin Yönetmeliğine eklenmiştir.</li>
                <li>Kişisel veri işlemeye başlamadan önce Şirket tarafından, ilgili kişileri aydınlatma yükümlülüğü yerine getirilmektedir.</li>
                <li>Kişisel veri işleme envanteri hazırlanmıştır.</li>
                <li>Kişisel verilerin işlenmesine ilişkin genel politika ve bu konuya ilişkin prosedürler hazırlanarak yayımlanmıştır.</li>
                <li>Şirket içi periyodik ve rastgele denetimler yapılmaktadır.</li>
                <li>Özel nitelikli kişisel verilerin güvenliğine yönelik ayrı bir prosedür belirlenmiştir.</li>
                <li>Özel nitelikli kişisel verilerin işlendiği, muhafaza edildiği ve/veya erişildiği fiziksel ortamların yeterli güvenlik önlemleri alınmakta, fiziksel güvenliği sağlanarak yetkisiz giriş çıkışlar engellenmektedir.</li>
                <li>Kağıt ortamı yoluyla aktarımı gerekiyorsa evrakın çalınması, kaybolması ya da yetkisiz kişiler tarafından görülmesi gibi risklere karşı gerekli önlemler alınmakta ve evrak “gizli” formatta gönderilmektedir.</li>
                <li>Çalışanlara yönelik bilgi güvenliği eğitimleri verilmektedir.</li>
            </ul>


            <h2 className="documentTitle">6. KİŞİSEL VERİLERİ İMHA TEKNİKLERİ</h2>
            <p className="documentText">
                İlgili mevzuatta öngörülen süre ya da işlendikleri amaç için gerekli olan saklama süresinin sonunda kişisel veriler, Şirket tarafından re’sen veya ilgili kişinin başvurusu üzerine yine ilgili mevzuat hükümlerine uygun olarak aşağıda belirtilen tekniklerle imha edilir.
            </p>

            <h2 className="documentTitle">6.1.	Kişisel Verilerin Silinmesi
            </h2>
            <p className="documentText">Kişisel veriler Şirket tarafından Tablo 3’te verilen yöntemlerle silinir.
            </p>

            <table className="documentTable">
                <caption>Tablo 3: Kişisel Verilerin Silinmesi</caption>
                <thead>
                    <tr>
                        <th>Veri Kayıt Ortamı</th>
                        <th>Açıklama</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Sunucularda Yer Alan Kişisel Veriler</td>
                        <td>Sunucularda yer alan kişisel verilerden saklanmasını gerektiren süresi sona erenler için sistem yöneticisi tarafından ilgili kullanıcıların erişim yetkisi kaldırılarak silme işlemi yapılır.</td>
                    </tr>
                    <tr>
                        <td>Elektronik Ortamda Yer Alan Kişisel Veriler</td>
                        <td>Elektronik ortamda yer alan kişisel verilerden saklanmasını gerektiren süresi sona erenler, veritabanı yöneticisi hariç diğer çalışanlar (ilgili kullanıcılar) için hiçbir şekilde erişilemez ve tekrar kullanılamaz hale getirilir.</td>
                    </tr>
                    <tr>
                        <td>Fiziksel Ortamda Yer Alan Kişisel Veriler</td>
                        <td>Fiziksel ortamda tutulan kişisel verilerden saklanmasını gerektiren süresi sona erenler için evrak arşivinden sorumlu birim yöneticisi hariç diğer çalışanlar için hiçbir şekilde erişilemez ve tekrar kullanılamaz hale getirilir. Arşivde kilitli dolaplarda saklanır ve bu dolapların kilitleri yalnızca evrak arşivinden sorumlu birim yöneticisinde bulunur.</td>
                    </tr>
                    <tr>
                        <td>Taşınabilir Medyada Bulunan Kişisel Veriler</td>
                        <td>Flash tabanlı saklama ortamlarında tutulan kişisel verilerden saklanmasını gerektiren süresi sona erenler, sistem yöneticisi tarafından şifrelenerek ve erişim yetkisi sadece sistem yöneticisine verilerek anahtarları güvenli ortamlarda saklanır.</td>
                    </tr>
                </tbody>
            </table>


            <h2 className="documentTitle">6.2.	Kişisel Verilerin Yok Edilmesi
            </h2>
            <p className="documentText">Kişisel veriler, Şirket tarafından Tablo 4’te verilen yöntemlerle yok edilir.</p>

            <table className="documentTable">
                <caption>Tablo 4: Kişisel Verilerin Yok Edilmesi</caption>
                <thead>
                    <tr>
                        <th>Veri Kayıt Ortamı</th>
                        <th>Açıklama</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Fiziksel Ortamda Yer Alan Kişisel Veriler</td>
                        <td>Kağıt ortamında yer alan kişisel verilerden saklanmasını gerektiren süresi sona erenler, <strong>kağıt kırpma makinelerinde geri döndürülemeyecek şekilde</strong> yok edilir.</td>
                    </tr>
                    <tr>
                        <td>Optik/Manyetik Medyada Yer Alan Kişisel Veriler</td>
                        <td>Optik medya ve manyetik medyada yer alan kişisel verilerden saklanmasını gerektiren süresi sona erenlerin <strong>eritilmesi, yakılması veya toz haline getirilmesi</strong> gibi fiziksel olarak yok edilmesi işlemi uygulanır. Ayrıca, manyetik medya özel bir cihazdan geçirilerek yüksek değerde manyetik alana maruz bırakılması suretiyle üzerindeki veriler okunamaz hale getirilir.</td>
                    </tr>
                </tbody>
            </table>


            <h2 className="documentTitle">6.3.	Kişisel Verilerin Anonim Hale Getirilmesi
            </h2>
            <p className="documentText">Kişisel verilerin anonim hale getirilmesi, kişisel verilerin başka verilerle eşleştirilse dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hale getirilmesidir.</p>
            <p className="documentText">Kişisel verilerin anonim hale getirilmiş olması için; kişisel verilerin, veri sorumlusu veya üçüncü kişiler tarafından geri döndürülmesi ve/veya verilerin başka verilerle eşleştirilmesi gibi kayıt ortamı ve ilgili faaliyet alanı açısından uygun tekniklerin kullanılması yoluyla dahi kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemez hale getirilmesi gerekir.            </p>

            <h2 className="documentTitle">7. SAKLAMA VE İMHA SÜRELERİ</h2>
            <p className="documentText">
                Şirket tarafından, faaliyetleri kapsamında işlenmekte olan kişisel verilerle ilgili olarak;
            </p>

            <ul className="documentList">
                <li>Süreçlere bağlı olarak gerçekleştirilen faaliyetler kapsamındaki tüm kişisel verilerle ilgili kişisel veri bazında saklama süreleri Kişisel Veri İşleme Envanterinde,</li>
                <li>Veri kategorileri bazında saklama süreleri VERBİS kaydında yer alır.</li>
            </ul>

            <p className="documentText">Söz konusu saklama süreleri üzerinde, gerekmesi halinde Şirket tarafından güncellemeler yapılır. Saklama süreleri sona eren kişisel veriler için re’sen silme, yok etme veya anonim hale getirme işlemi işbu Prosedür’ün 2. maddesinde belirtilen sorumlular tarafından yerine getirilir.
            </p>


            <h2 className="documentTitle">8.	PERİYODİK İMHA SÜRESİ
            </h2>
            <p className="documentText">Yönetmelik’in 11. maddesi gereğince Şirket, periyodik imha süresini altı (6) ay olarak belirlemiştir. Buna göre, Şirket nezdinde her yıl Haziran ve Aralık aylarında periyodik imha işlemi gerçekleştirilir.
            </p>
            <h2 className="documentTitle">9.	PROSEDÜR’ÜN YAYINLANMASI VE SAKLANMASI
            </h2>
            <p className="documentText">Prosedür, ıslak imzalı (basılı kâğıt) ve elektronik ortamda olmak üzere iki farklı ortamda yayımlanır, internet sayfasında kamuya açıklanır. Basılı kâğıt nüshası da Şirket nezdinde dosyasında saklanır.
            </p>
            <h2 className="documentTitle">10.	PROSEDÜR’ÜN GÜNCELLENME PERİYODU
            </h2>
            <p className="documentText">Prosedür, mevzuat değişikliklerinde ve ihtiyaç duyuldukça gözden geçirilir ve gerekli olan bölümler güncellenir.
            </p>
            <h2 className="documentTitle">11.	PROSEDÜR’ÜN YÜRÜRLÜĞÜ VE YÜRÜRLÜKTEN KALDIRILMASI
            </h2>
            <p className="documentText">Prosedür, 01.05.2022 tarihi itibariyle yürürlüğe girmiştir. Yürürlükten kaldırılmasına karar verilmesi halinde, Prosedür’ün ıslak imzalı eski nüshaları Şirket yetkili organlarınca iptal edilir ve en az beş (5) yıl süre ile Şirketçe dosyasında saklanır.</p>


        </div >

    </>
}