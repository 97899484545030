import { Box, Button, Modal } from "@mui/material";
import { absoluteCenter } from "src/utils/window";

export interface IProps {
  onClose: () => void;
  onAccept: () => void;
  buttonText?: string;
  document?: any;
}

export function CDocumentPopup({
  onClose,
  onAccept,
  buttonText,
  document,
}: IProps) {
  return (
    <Modal
      open={document != undefined}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={absoluteCenter}>
        <div className="w-[95vw] lg:w-[690px] p-[24px] bg-white rounded-[8px] flex-col justify-center items-start">
          <div className="w-full flex justify-between pb-[24px]">
            <div
              onClick={() => {
                onClose();
              }}
            >
              <img src="/images/close.svg" />
            </div>
          </div>
          <div className="y-scroll h-[70vh]">
            {document}
          </div>
          <div className="h-[24px]"></div>
          <Button
            className="purpleButtonMini pb-[32px]"
            onClick={() => {
              onAccept();
            }}
          >
            {buttonText}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
