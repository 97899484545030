export function DocumentKisiselVerilerinKorunmasiIslenmesiPolitikasi() {
    return <>
        <div className="documentContainer">
            <h1 className="documentHeader">KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ POLİTİKASI</h1>

            <h2 className="documentTitle">1. AMAÇ</h2>
            <p className="documentText">
                POFFT DİJİTAL ÇÖZÜMLER TİCARET ANONİM ŞİRKETİ (“Şirket”) ve tüm çalışanları, kişisel verilerin korunmasına ilişkin T.C. Anayasası ve
                6698 sayılı Kişisel Verilerin Korunması Kanunu başta olmak üzere yürürlükteki diğer ilgili mevzuat tarafından getirilmiş ilkelere,
                kararlara ve kurallara uymayı ve Şirket tarafından verileri işlenen bireylerin haklarını korumayı taahhüt etmektedir.
            </p>
            <p className="documentText">
                Politikanın amacı, kişisel verilerin dahili olarak yönetiminde kuralların oluşturulması, hedef ve yükümlülüklerin belirlenmesi,
                makul risk seviyesiyle uyumlu olarak kontrol mekanizmalarının tesis edilmesi, kişisel verilerin korunması alanında tabi olunan
                yasal yükümlülüklerin yerine getirilmesi ve bireylerin menfaatlerinin mümkün olan en iyi şekilde korunmasıdır.
            </p>

            <h2 className="documentTitle">2. KAPSAM</h2>
            <p className="documentText">
                Politika hükümleri, başta Şirket yönetim kurulu olmak üzere, Şirket’in tüm birimlerine destek hizmeti veren firma çalışanlarını,
                alt çalışanları ve stajyerleri kapsamaktadır. 6698 sayılı Kişisel Verilerin Korunması Kanunu veya bu Politikayı ihlal edici her türlü
                eylem ilgili mevzuat kapsamında değerlendirilir ve bu doğrultuda yaptırımlar uygulanır.
            </p>

            <h2 className="documentTitle">3. TANIMLAR</h2>
            <ul className="documentList">
                <li><strong>Açık rıza:</strong> Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rıza.</li>
                <li><strong>Anonim hale getirme:</strong> Kişisel verilerin başka verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya
                    belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hale getirilmesi.</li>
                <li><strong>Kanun:</strong> 6698 sayılı Kişisel Verilerin Korunması Kanunu.</li>
                <li><strong>Kişisel veri:</strong> Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi.</li>
                <li><strong>Kişisel Veri Envanteri:</strong> Veri sorumlularının iş süreçlerine bağlı olarak gerçekleştirmekte oldukları kişisel veri işleme faaliyetlerini; kişisel veri işleme amaçları ve hukuki sebebi, veri kategorisi, aktarılan alıcı grubu ve veri konusu kişi grubuyla ilişkilendirerek oluşturdukları ve kişisel verilerin işlendikleri amaçlar için gerekli olan azami muhafaza edilme süresini, yabancı ülkelere aktarımı öngörülen kişisel verileri ve veri güvenliğine ilişkin alınan tedbirleri açıklayarak detaylandırdıkları envanteri,</li>
                <li><strong>Kişisel verilerin işlenmesi:</strong> Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi,</li>
                <li><strong>Kurum:</strong> Kişisel Verileri Koruma Kurumu’nu,</li>
                <li><strong>Kurul:</strong>Kişisel Verileri Koruma Kurulu’nu,</li>
                <li><strong>KVK Komitesi:</strong>Veri sorumlusu tarafından atanmış, Kanun kapsamında oluşturulmuş süreçlerin idari takibi ve koordinasyonu yapan gerçek kişi veya kişilerden oluşan yapıyı,</li>
                <li><strong>KVK Taahhütnamesi:</strong>Veri paylaşımı yapılan üçüncü tarafların kanuni yükümlülüklerinin belirlendiği dokümanı,</li>
                <li><strong>Sicil:</strong>Kurum tarafından tutulan veri sorumluları sicilini,</li>
                <li><strong>Veri İşleyen:</strong>Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri işleyen gerçek veya tüzel kişiyi,</li>
                <li><strong>Veri sorumlusu:</strong>Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi,</li>
            </ul>
            <p className="documentText">
                ifade eder.
            </p>

            <h2 className="documentTitle">4. SORUMLULUKLAR</h2>
            <p className="documentText">
                Şirket, Kanun uyarınca Veri Sorumlusu sıfatını haizdir. Şirket çalışanı olan herkes, Şirket bünyesinde kişisel verilerin işlenmesinde doğru uygulamaların geliştirilmesi, teşvik edilmesi ve diğer yükümlülüklerden sorumludur.
            </p><p className="documentText">
                Şirket’in kişisel veri işleyen tüm çalışanları, Kişisel Verilerin Korunması mevzuatına uygun davranmak sorumluluğundadır.
            </p><p className="documentText">
                Şirket, tüm çalışanlarının, kişisel verilerin korunması alanında sahip olduğu sorumlulukları bilmesi ve gerekli farkındalığa sahip olması için gerekli bildirim ve eğitimlerin gerçekleştirilmesinden sorumludur.
            </p><p className="documentText">
                Şirket çalışanları, Şirket’e kendileri tarafından sağlanan veya kendilerine ilişkin olan tüm kişisel verilerin doğruluğunu ve güncelliğini sağlamakla yükümlüdür.
            </p>

            <h3 className="subSection">4.1. KVK Komitesi</h3>
            <p className="documentText">
                KVK Komitesi üyeleri, Yönetim Kurulu tarafından atanır ve doğrudan Yönetim Kurulu’na rapor sunar. Komite, kişisel veri koruma sisteminin yönetilmesi
                ve mevzuata uyumun sağlanmasından sorumludur.
            </p>

            <h3 className="subSection">4.2. KVK Komitesi Görev ve Sorumlulukları</h3>
            <ul className="documentList">
                <li>KVK Komitesi, Yönetim Kurulu’nu, kişisel verilerin korunması mevzuatı ve gelişmeler konusunda bilgilendirmelidir.</li>
                <li>KVK Komitesi, Şirket politikalarının, prosedürlerinin güncel olduğunu, veri işleme denetimlerinin ve eğitimlerin planlandığı takvime uygun olarak yapıldığını ve bunların ilgili mevzuatla uyumlu olduğunu sağlamakla sorumludur.
                </li>
                <li>KVK Komitesi, kişisel verilerin korunması ile ilgili konuların tümünde ilgili çalışanlarla birlikte hareket eder.
                </li>
                <li>KVK Komitesi, işleme amacı için açıkça gerekli olmayan kişisel verilerin toplanmaması ve işlenmemesini sağlamakla yükümlüdür.
                </li>
                <li>KVK Komitesi her yıl güncellenen kişisel veri envanteri üzerinden işlenen verilerin uygun ve ilgili olduğunu denetler.
                </li>
                <li>KVK Komitesi yıllık bazda yapacağı/yaptıracağı iç denetim/dış denetim ile tüm veri işleme yöntemlerinin uygun ve ilgili olduğunu denetler.
                </li>
                <li>KVK Komitesi, uygun ya da ilgili olmadığı veya işleme amacı bakımından gereğinden fazla olduğunu tespit ettiği kişisel veriler bakımından veri işleme faaliyetinin durdurulmasından ve işlenmiş verilerin saklama ve imha sürecinin tanımlandığı prosedür uyarınca güvenli bir şekilde imha edilmesinden sorumludur.
                </li>
                <li>KVK Komitesi veri envanteri üzerinden, işlenen verinin türü, saklama süresi ve miktarı üzerinde yapacağı değerlendirme ile belirli verilerin doğruluğunun veya güncelliğinin gözden geçirilmesi için ilgili birime talimat vermekle yükümlüdür.
                </li>
            </ul>

            <h2 className="documentTitle">5. UYGULAMA ESASLARI</h2>
            <div className="documentVGap" />
            <h3 className="subSection">5.1. VERİ İŞLEME İLKELERİ</h3>
            <p className="documentText">
                Şirket, kişisel verilerin korunması mevzuatı ve veri koruma ilkelerine uyacaktır. Şirket’in benimsediği veri işleme ilkeleri
                şunları içermektedir:
            </p>
            <ul className="documentList">
                <li>Kişisel verileri yalnızca meşru kurumsal amaçlar bakımından açıkça gerekli olması halinde işlemek,</li>
                <li>Bu amaçlar için gerekli olan kadar kişisel veriyi işlemek ve gereğinden fazla veriyi işlememek (veri minimizasyonu sağlamak),
                </li>
                <li>Bireylere kişisel verilerinin kimler tarafından ve ne şekilde kullanıldığı konusunda açık bilgi vermek,
                </li>
                <li>Yalnızca ilgili ve uygun kişisel verileri işlemek,
                </li>
                <li>Kişisel verileri hakkaniyete ve hukuka uygun olarak işlemek,
                </li>
                <li>Şirket tarafından işlenen kişisel veri kategorilerinin envanterini tutmak,
                </li>
                <li>Kişisel verileri doğru ve gerektiğinde güncel tutmak,
                </li>
                <li>Kişisel verileri yalnızca yasal düzenlemeler, Şirket’in hukuki yükümlülükleri veya meşru kurumsal menfaatlerinin gerektirdiği süre kadar saklamak,
                </li>
                <li>Kişisel verileri, Veri Sahiplerinin kimlik bilgilerine, kişisel verilerin işlendiği amaçlar için makul ölçüde gereken süreden daha fazla erişilmesine izin vermeyecek bir şekilde saklamak,
                </li>
                <li>Veri gizliliğini, herhangi bir proje ya da faaliyetin başlangıç aşamasında ve ardından hizmet ömrü boyunca bir kilit faktör olarak sağlamak (Gizliliğin Baştan Sağlanması İlkesi),
                </li>
                <li>Bireylerin, erişim hakkı dahil olmak üzere, kişisel verileriyle ilgili haklarına saygılı olmak,
                </li>
                <li>Kişisel verileri yurtdışına, yalnızca kişilerin açık rızasına uygun olarak veya yeterli korumanın bulunması halinde transfer etmek,
                </li>
                <li>Mevzuat uyarınca izin verilen istisnaları uygulamak,
                </li>
                <li>Politikanın uygulanması için kişisel veri koruma sistemini tesis etmek ve uygulamak,
                </li>
                <li>Gerektiğinde kişisel veri koruma sistemine taraf olan iç ve dış paydaşları ve bunların Şirket’in kişisel veri koruma sistemine ne ölçüde dahil olduklarını belirlemek,
                </li>
                <li>Kişisel verilerin korunması sistemiyle ilgili özel yetki ve sorumluluklara sahip çalışanları belirlemek.
                </li>
            </ul>
            <p className="documentText">
                Tüm kişisel veri işleme faaliyetleri aşağıdaki veri koruma ilkeleriyle uyumlu olarak yapılmalıdır. Şirket’in politika ve prosedürleri bu ilkelerle uyumluluğu sağlamayı amaçlamaktadır:
            </p>
            <ul className="documentList">
                <li>Hukuka ve dürüstlük kurallarına uygun olma
                </li>
                <li>Doğru ve gerektiğinde güncel olma
                </li>
                <li>Belirli, açık ve meşru amaçlar için işlenme
                </li>
                <li>İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olma
                </li>
                <li>İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilme.
                </li>
            </ul>
            <p className="documentText">
                Bu doğrultuda Şirket, gerçekleştirdiği kişisel veri işleme faaliyetlerine ilişkin, veri toplama kanallarında ve ilgili formlarda aydınlatma ve gizlilik bildirimlerine yer verir. Şirket tarafından kimlere ilişkin hangi verilerin hangi amaçlarla işlendiğine yönelik açık ve anlaşılabilir bilgilerin yer aldığı bildirimlerin yer alacağı ve ilan edileceği alanlar KVK Komitesi görüşü alınarak belirlenir. Bu bildirimlerde şu hususlara yer verilir:
            </p>
            <ul className="documentList">
                <li>Şirket’in veri sorumlusu olarak kimliği ve iletişim bilgileri,</li>
                <li>İşlenen kişisel veri türleri,</li>
                <li>Kişisel verinin işlenme amaçları,</li>
                <li>Kişisel verilerin toplanma yöntemleri,</li>
                <li>Kişisel verilerin hangi hukuki sebebe dayalı olarak işlendiği,</li>
                <li>Veri sahibinin hakları,</li>
                <li>Verinin paylaşılabileceği üçüncü taraflar.</li>
            </ul>
            <p className="documentText">Kişisel veri envanterinde, kişisel verilerin işlenme gerekçeleri/amaçları belirlenir ve kişisel veriler başka bir hukuki gerekçe veya veri sahibinin açık rızası olmaksızın belirtilen amaç dışında kullanılamazlar. Bir kişisel verinin kişisel veri envanterinde belirtilmiş amaçlar dışında kullanılmasını gerektiren koşulların doğması halinde, bu durum ilgili çalışan/birim/müdürlük tarafından KVK Komitesi’ne bildirilir. KVK Komitesi yeni amacın uygunluğunu denetler ve gerekliyse veri sahibinin yeni amaçla ve yeni veri işleme faaliyeti konusunda bilgilendirilmesini sağlar.
            </p>
            <p className="documentText">Kişisel veriler; işlenme amaçlarıyla uygun, ilgili ve sınırlı ölçüde işlenmeli ve doğru ve güncel olmalıdır. Uzun süre boyunca tutulan verilerin doğruluğu ve güncelliği gözden geçirilmelidir. Şirket, tüm çalışanların, verilerin doğru ve güncel olarak toplanması ve saklanması konusunda eğitilmesinden sorumludur.
            </p><p className="documentText">Tüm veri toplama kanalları hakkında KVK Komitesi’ne bilgilendirme yapılmalıdır.
            </p><p className="documentText">Çalışanlara ilişkin tutulan verilerin doğruluğu ve güncelliği ilgili çalışanın kendi sorumluluğundadır.
            </p><p className="documentText">Çalışanlar/müşteriler/ilişki içerisinde bulunulan kurumlar ve diğer ilgili kişiler işlenen kişisel verilerin güncellenmesi için Şirket’i bilgilendirmelidirler.
            </p><p className="documentText">Kişisel veriler, ilgili kişi, yalnızca veri işleme amacı bakımından gerekliyse teşhis edilebilecek şekilde işlenmelidir.
            </p><p className="documentText">Kişisel verilerin yedekleme vb. gereklilikler nedeniyle, belirlenen sürenin ötesinde saklanması veya veri güvenliği zafiyeti durumlarında, bireylerin hak ve özgürlüklerinin korunması için, kişisel veriler hakkında Kurul tarafından belirlenmiş güvenli imha etme yöntemleri uygulanır.
            </p><p className="documentText">Kişisel verilerin saklama ve imha sürecinin tanımlandığı prosedür uyarınca belirlenmiş sürelerden daha fazla süre işlenmesi gerektiğinde, KVK Komitesi’nin yazılı onayı alınır.
            </p><p className="documentText">Kişisel Verileri işleyen tüm Şirket birimleri, hem yukarıda belirtilen ilkeleri hem de geçerli veri koruma kanunlarını yürürlüğe koyan tedbirlere uymaktan sorumludur ve uyduğunu kanıtlayabilmelidir.
            </p>

            <h3 className="subSection">5.2. RİSK DEĞERLENDİRME</h3>
            <p className="documentText">
                Şirket, kişisel veri türlerinin işlenmesiyle bağlantılı riskleri belirler. Belirli türdeki veri işleme faaliyetinin; yapısı, bağlamı ve amaçları doğrultusunda kişisel hak ve özgürlükler üzerinde yüksek risk doğurması muhtemel ise Şirket, veri işleme faaliyeti öncesinde bir etki analizi gerçekleştirerek potansiyel riskleri yönetmelidir. Benzer riskler içeren birden fazla veri işleme faaliyeti için tek bir değerlendirmeye dayanılabilir.
            </p>
            <p className="documentText">
                Etki analizi sonunda Şirket’in kişisel hak ve özgürlükler üzerinde yüksek risk doğurabilecek bir veri işleme faaliyetine başlamak üzere olduğu anlaşılırsa, bu konuyla ilgili KVK Komitesi’nin onayı aranır. KVK Komitesi gerekli görmesi halinde Kurul’dan konuyla ilgili görüş alır.
            </p>
            <h3 className="subSection">5.3. AÇIK RIZA ALINMASI</h3>
            <p className="documentText">Şirket, veri sahibi tarafından belirli veri işleme faaliyetlerine ilişkin ve Kanun tarafından gerekli kılınan durumlarda, bilgilendirilmeye dayanan ve özgür iradeyle hakkında veri işlenmesine ilişkin iradeyi ortaya koyan, yazılı/sözlü beyan veya açık doğrulayıcı eylemle açıklanan rızayı açık rıza olarak kabul etmektedir. Açık rızalar yazılı olarak veya sistemsel olarak ispata elverişli bir şekilde alınır. Açık rıza, veri sahibi tarafından her zaman geri alınabilir.
            </p>
            <p className="documentText">Açık rızaya dayanan veri işleme faaliyetinin süreklilik arz edecek veya tekrarlanacak olması halinde alınmış açık rızalar kontrol edilir. Bu açık rızaların güncelliği ve doğrulu ilgili birimin sorumluluğundadır. Açık rızaya dayanan veri işleme faaliyetine ilişkin açık rıza formları veya diğer ilgili ispat araçları ilgili birimce saklanır.
            </p>

            <h3 className="subSection">5.4. VERİ GÜVENLİĞİ</h3>
            <p className="documentText">
                Tüm çalışanlar, Şirket tarafından işlenen ve kendi sorumluluklarında olan verilerin güvenli olarak tutulmasını ve gizlilik sözleşmesi imzalamadıkça üçüncü tarafa açıklanmamasını sağlamakla yükümlüdür.
            </p><p className="documentText">Kişisel verilere, yalnızca bunlara erişimi gerekli olanlar erişebilmelidir.
            </p><p className="documentText">Kişisel verilere ilişkin güvenliği tehdit eden olaylar, KVK Komitesi’nce kesin olarak tespit edildiğinden itibaren en kısa süre ve her halükarda olayın öğrenilmesinden itibaren en geç 72 saat içerisinde Kurul’a ve ilgili kişiye bildirilir.
            </p>
            <h3 className="subSection">5.5. VERİ PAYLAŞIMI</h3>
            <p className="documentText">
                Kişisel veriler ancak hukuka ve hakkaniyete uygun olarak üçüncü kişilerle paylaşılabilir. Buna göre kişisel verilerin paylaşılabilmesi için aşağıdaki koşullardan birinin bulunması aranır:
            </p>
            <ul className="documentList">
                <li>Veri sahibin açık rızasının alınması,</li>
                <li>Kanunlarda açıkça öngörülmesi,</li>
                <li>Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması,</li>
                <li>Şirket’in taraf olduğu ya da olacağı bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması,</li>
                <li>Şirket’in hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,</li>
                <li>İlgili kişinin kendisi tarafından alenileştirilmiş olması,</li>
                <li>Şirket’in haklarının tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması,</li>
                <li>İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Şirket’in meşru menfaatleri için veri işlenmesinin zorunlu olması.</li>
            </ul>
            <p className="documentText">Kişisel veriler, ancak yukarıdaki koşulların sağlanması ve hedef ülkede yeterli korumanın bulunması veya veri sahibinin bu aktarım konusunda açık rızasının alınması koşuluyla yurtdışına aktarılabilir.
            </p><p className="documentText">Kişisel verilerin yurtdışına aktarılmasında Kurul tarafından belirlenen yeterli korumanın bulunduğu ülkeler listesi dikkate alınır.
            </p><p className="documentText">Kişisel verilerin yurtdışına aktarılması söz konusu olduğunda Kanun ve ilgili mevzuat uyarınca KVK Komitesi, Kurul nezdinde gerekli izin ve bildirimleri sağlar.
            </p><p className="documentText">Kişisel verilerin paylaşımına ilişkin tüm işlemler gerekçeleriyle birlikte yazılı olarak kayıt altına alınmalıdır. Bu kayıtlar KVK Komitesi’nce belirli periyodlarla denetlenir.
            </p><p className="documentText">Yasal bir dayanak veya hukuki yükümlülük olmaksızın düzenli bir veri paylaşma ilişkisinin söz konusu olması halinde, söz konusu tarafla veri paylaşımının koşullarını belirleyen bir KVK Taahhütnamesi yapılır.
            </p>

            <h3 className="subSection">5.7. VERİ SAHİPLERİNİN HAKLARI
            </h3>
            <p className="documentText">Veri sahipleri, Şirket nezdindeki veri işleme faaliyetleri ve kayıtlara ilişkin olarak aşağıdaki haklara sahiptir:
            </p>
            <ul className="documentList">
                <li>Kişisel verisinin işlenip işlenmediğini öğrenme,</li>
                <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
                <li>KVKK veya bu politika uyarınca işlenmesi için hukuka uygun bir gerekçe veya dayanak bulunmayan kişisel verilerin silinmesini veya yok edilmesini isteme,</li>
                <li>İsteği üzerine yapılan düzeltme veya silme işlemlerinin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</li>
            </ul>

            <p className="documentText" style={{ textDecoration: "underline" }}>Veri Sahibinin Başvuru Usulü</p>
            <p className="documentText">Veri sahipleri, yukarıda sayılan haklarına ilişkin taleplerini Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’de öngörülen başvuru usullerine uygun olarak Şirket’e başvurabilir.</p>
            <p className="documentText">Bu durumda Şirket, talebi, niteliğine göre en kısa sürede ve en geç 30 (otuz) gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi hâlinde Şirket, Kurul tarafından belirlenen tarifedeki ücreti talep edebilecektir.</p>
            Taleplerin alınması, iletilmesi ve sonuçlandırılmasına ilişkin süreçler, Veri Sahibi Başvurularının Alınması, Değerlendirilmesi ve Yanıtlanması Prosedürü uyarınca gerçekleştirilir.
            <p className="documentText">Veri sahiplerinin taleplerini yöneltebilmesi için bildirimlerde ve web adresinde, veri sahiplerinin erişim hakkı ve iletişim bilgilerine yer verilir.</p>
            <p className="documentText">Şirket’in tüm çalışanları, görev tanımı ne olursa olsun kendisine yönelmiş veri sahibi erişim taleplerine yönelik olarak doğru başvuru yöntemi konusunda veri sahiplerini yönlendirmekle yükümlüdür.
                Şirket çalışanları, veri sahiplerinden gelecek talepler konusunda nasıl hareket etmeleri konusunda KVK Komitesi’nce bilgilendirilmelidir.</p>

            <p className="documentText">Bu kapsamda başvurular;</p>
            <ul className="documentList">
                <li>Veri Sahibi’nin şahsen başvurusuyla,</li>
                <li>Noter vasıtasıyla yapılabilir.</li>
            </ul>


            <h2 className="documentTitle">6. YÜRÜRLÜK VE GÜNCEL TUTMA</h2>
            <p className="documentText">
                İşbu Politika, 01.05.2022 tarihinde yürürlüğe girmiş olup; Kanun, ilgili ikincil mevzuat, Kurul Kararları ve Şirket iş süreçleri doğrultusunda KVK Komitesi tarafından her yıl başında yeniden değerlendirilecek ve gerekmesi halinde güncellenecektir.
            </p>
        </div >

    </>
}