export function DocumentInternetPlatformuAydinlatmaMetni() {
    return <>
        <div className="documentContainer">
            <h1 className="documentHeader">İNTERNET PLATFORMU AYDINLATMA METNİ</h1>

            <h2 className="documentTitle">1. VERİ SORUMLUSUNUN KİMLİĞİ</h2>
            <p className="documentText">
                POFFT DİJİTAL ÇÖZÜMLER TİCARET ANONİM ŞİRKETİ (“Şirketimiz”) 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) kapsamında veri sorumlusu
                sıfatını haiz olup Kanun ve diğer uygulanabilir mevzuatta yer alan düzenlemelere uygun olarak kişisel veri işleme faaliyetlerini yürütmektedir.
            </p>

            <h2 className="documentTitle">2. KİŞİSEL VERİLERİN TOPLANMASI, İŞLENMESİ VE İŞLEME AMAÇLARI</h2>
            <p className="documentText">
                Aşağıda yer verilen kişisel verileriniz, elektronik ortamda toplanmakta olup yine aşağıda yer alan amaçlar dâhilinde işlenmektedir:
            </p>
            <ul className="documentList">
                <li>İnternet sitemiz üzerindeki “İletişimde Kalalım” bölümünü veya “İletişim” sekmesi altında yer alan formu kullanmak suretiyle bizlerle paylaşacağınız ad, soyad ve e-posta bilgileriniz,</li>
                <li>İnternet sitemizi ziyaret etmeniz ya da bu sitelerde gezinmeniz halinde çerez verileriniz de işlenecektir.</li>
            </ul>
            <p className="documentText">
                Anılan kişisel verileriniz; Şirketimizin hizmetlerinin sunulması, müşteri memnuniyetinin artırılması, şikâyet ve önerilerin değerlendirilerek
                yanıtlanması, istatistikî analizler yapılabilmesi, yasal ve düzenleyici gereksinimlerin yerine getirilmesi ve veri güvenliğinin sağlanması amaçlarıyla
                ve meşru menfaat hukuki sebebine dayalı olarak işlenmektedir.
            </p>
            <p className="documentText">
                Öte yandan, açık rıza vermeniz durumunda, ad, soyad ve e-posta verileriniz ayrıca tanıtım ve pazarlama amaçlarıyla işlenecektir.
            </p>

            <h2 className="documentTitle">3. KİŞİSEL VERİLERİN AKTARILMASI</h2>
            <p className="documentText">
                Kişisel verileriniz, Kanun ve sair mevzuat kapsamında ve işbu Aydınlatma Metni’nin 2. maddesinde açıklanan amaçlarla, aktarılmasını gerektiren
                sebebe bağlı ve bu sebeple sınırlı olmak kaydıyla; Kanun ve ilgili düzenlemeler kapsamında; denetleyici ve düzenleyici kamu kurum ve kuruluşlarına
                (BTK, TÜİK, mahkemeler, bankalar vb.), denetçilere, yazılım ve donanım destek hizmeti sunan şirketlere ve avukatlar gibi kanunen yetkili özel kişilere,
                hukuki yükümlülük ve meşru menfaat hukuki nedenlerine dayalı olarak aktarılabilmektedir.
            </p>

            <h2 className="documentTitle">4. KİŞİSEL VERİLERİN KORUNMASINA YÖNELİK HAKLARINIZ</h2>
            <p className="documentText">
                Kişisel verisi işlenen gerçek kişilerin hakları Kanun’un 11. maddesi sayılmıştır. Kişisel veri sahibi olarak ilgili Kanun maddesinde sayılan
                haklarınıza ilişkin taleplerinizi, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’de öngörülen başvuru usullerine uygun olarak
                Maslak Meydan Sk. Beybi Giz Plaza A Blok No: 1 İç Kapı No: 65 Sarıyer / İstanbul adresine kimlik bilgilerinizin yazılı olarak ibrazı ve Noter
                kanalıyla resmi usulde iletilmesi durumunda, talebiniz incelenip sonuçlandırılacaktır. Ayrıca 30 gün içinde ücretsiz olarak dönüş yapılacaktır.
            </p>
        </div>
    </>
}