import {TDukkanJob, TDukkanJobCardsRow, TMediaTextCardsRow} from "../types/TComponents";
import CMediaTextCard from "./CMediaTextCard";
import {isDesktop} from '../utils/window';
import CSwiper1Partner from "./CSwiper1Partner";
import CSwiper1Dukkan from "./CSwiper1Dukkan";
import CDukkanJobCard from "./CDukkanJobCard";
import {useContext, useEffect, useState} from "react";
import CLoadingAnimation from "./CLoadingAnimation";
import {getDukkanJobList} from "../service/dukkanRequest";
import {TError} from "../types/TError";
import {MainContext} from "../context/MainContext";

interface PDukkanRow {
  getTranslation: (parent: string, child: string) => string;
}

function CDukkanRow({getTranslation}: PDukkanRow) {
  const mainContext = useContext(MainContext);
  const [data, setData] = useState<undefined | TDukkanJob[]>(undefined);

  useEffect(() => {
    getDukkanJobList(mainContext?.getTranslation).then((dataa) => {
      if (dataa instanceof TError) {
        return
      }
      setData(dataa);
    })
  }, []);

  return (
    data === undefined ? <CLoadingAnimation/> :
      <>
        {/*<CFilters*/}
        {/*  filters={*/}
        {/*    OPofftMarketplaceSection(mainContext!.getTranslation).filters*/}
        {/*  }*/}
        {/*/>*/}
        <div className={`flex justify-between gap-[40px] pt-[48px] !pt-[0px] mobileonly:!pb-0`}>
          {isDesktop() ? data!.map((card, index) => {
              return <CDukkanJobCard getTranslation={getTranslation} key={'desktop_dukkan' + index} data={card}/>;
            }) :
            <CSwiper1Dukkan
              data={{
                cards: data!.map((card, i) => {
                  return (
                    <CDukkanJobCard getTranslation={getTranslation} key={'mobile_dukkan' + i} data={card}/>
                  );
                }),
              }}
            />}
        </div>
      </>
  );
}

export default CDukkanRow;
