import { useContext, useEffect, useState } from "react";
import { TranslationModel } from "src/objects/TranslationModel";
import { WordData } from "src/types/DataProps";
import { MainContext } from "../context/MainContext";
import { getTranslations } from "../service/commonRequest";
import { Translation } from "../types/Translation";

export function useTranslation(translation: TranslationModel) {

  const name = translation.name;
  const parent = translation.parent;

  const mainContext = useContext(MainContext)

  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);
  function getTranslation(key: string): string {
    return translations?.get(parent)?.sentences.get(key) ?? "";
  }

  const [words, setWords] = useState<WordData[]>([]);

  useEffect(() => {

    if (mainContext?.publicToken) {
      getTranslations(name, (translations, data) => {
        setTranslations(translations);
        setWords(data[0].pageWordModelList ?? []);
      })
    }
  }, [mainContext?.publicToken]);

  function getWordsFor(key: string) {
    const values = words.find((item) => item.parentWord === key)?.valueList ?? [];
    return values;
  }

  return { getTranslation, translations, getWordsFor };
}