import { Checkbox, FormControlLabel } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainContext } from "src/context/MainContext";
import { useTranslation } from "src/hooks/TranslationHook";
import { ORegisterCheckboxes } from "src/objects/Register/ORegisterCheckboxes";
import { DokumanTranslation } from "src/objects/TranslationModel";
import { CDocumentPopup } from "./CDocumentPopup";
import { DocumentSirketAydinlatmaMetni } from "./Documents/document_sirket_aydinlatma_metni";
import { DocumentUzmanAydinlatmaMetni } from "./Documents/document_uzman_aydinlatma_metni";


interface IProps {
    checkboxes: ORegisterCheckboxes
    setCheckboxes: (checkboxes: ORegisterCheckboxes) => void
    requesting: boolean
}

export function CExpertAgreements({ checkboxes, setCheckboxes, requesting }: IProps) {

    const mainContext = useContext(MainContext);
    const navigate = useNavigate();
    const documentsTranslations = useTranslation(DokumanTranslation)
    const [selectedDocument, setSelectedDocument] = useState<any | undefined>(undefined);


    return <>
        <div className="flex flex-col pb-[16px] gap-[24px]">

            <div className="text-12-16-400 text-black">
                {documentsTranslations.getTranslation('aydinlatma-metni-oncesi')}
                <span
                    className="text-purple underline"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setSelectedDocument(<DocumentUzmanAydinlatmaMetni noHorizontalSpacing={true} />);
                    }}
                >
                    {documentsTranslations.getTranslation('aydinlatma-metni')}
                </span>
                {documentsTranslations.getTranslation('aydinlatma-metni-sonrasi')}
            </div>

            <div
                className={`basicCheckbox !pb-0 ${checkboxes.acikRiza ? "selected" : ""}`}
                style={requesting ? { pointerEvents: "none" } : {}}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxes.acikRiza}
                            onChange={(e) => {
                                setCheckboxes({ ...checkboxes, acikRiza: e.target.checked });
                            }}
                        />
                    }
                    label={
                        <div className="text-12-16-400 text-black">
                            {documentsTranslations.getTranslation('uzman-acik-riza-beyani')}
                        </div>
                    }
                />
            </div>

            <div className="flex flex-col">

                <div className="text-12-16-400 text-black">
                    {mainContext?.getTranslation(
                        "giris-kayit",
                        "ticari-ileti-onayi"
                    )}
                </div>
                <div
                    className={`flex justify-start text-left items-center`}
                >

                    <div className={`basicCheckbox !pb-0 ${checkboxes.ticariIletiSms ? "selected" : ""}`}>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.ticariIletiSms}
                                    onChange={(e) => {
                                        setCheckboxes({ ...checkboxes, ticariIletiSms: e.target.checked });
                                    }}
                                />
                            }
                            label={
                                <div
                                    className="text-12-16-400 text-black flex-col justify-center"
                                    onClick={() => {
                                        // setCheckKvkk(checkKvkk);
                                    }}
                                >
                                    {mainContext
                                        ?.getTranslation("common", "sms")
                                        .toUpperCase()}

                                </div>
                            }
                        />
                    </div>
                    <div className={`basicCheckbox !pb-0 ${checkboxes.ticariIletiCall ? "selected" : ""}`}>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.ticariIletiCall}
                                    onChange={(e) => {
                                        setCheckboxes({ ...checkboxes, ticariIletiCall: e.target.checked });
                                    }}
                                />
                            }
                            label={
                                <div
                                    className="text-12-16-400 text-black flex-col justify-center"
                                    onClick={() => {
                                        // setCheckKvkk(checkKvkk);
                                    }}
                                >
                                    {mainContext
                                        ?.getTranslation("common", "telefon")}
                                </div>
                            }
                        />
                    </div>
                    <div className={`basicCheckbox !pb-0 ${checkboxes.ticariIletiEmail ? "selected" : ""}`}>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.ticariIletiEmail}
                                    onChange={(e) => {
                                        setCheckboxes({ ...checkboxes, ticariIletiEmail: e.target.checked });
                                    }}
                                />
                            }
                            label={
                                <div
                                    className="text-12-16-400 text-black flex-col justify-center"
                                    onClick={() => {
                                        // setCheckKvkk(checkKvkk);
                                    }}
                                >
                                    {mainContext
                                        ?.getTranslation("common", "eposta")}
                                </div>
                            }
                        />
                    </div>


                </div>
            </div>

        </div>
        <CDocumentPopup
            document={selectedDocument}
            onClose={() => {
                setSelectedDocument(undefined);
            }}
            onAccept={() => {
                setSelectedDocument(undefined);
            }}
            buttonText={'Tamam'}
        />
    </>
}


export function CCompanyAgreements({ checkboxes, setCheckboxes, requesting }: IProps) {
    const documentsTranslations = useTranslation(DokumanTranslation)
    const mainContext = useContext(MainContext);
    const navigate = useNavigate();
    const [selectedDocument, setSelectedDocument] = useState<any | undefined>(undefined);

    return <>

        <div className="flex flex-col pb-[16px] gap-[24px]">

            <div className="text-12-16-400 text-black">
                {documentsTranslations.getTranslation('aydinlatma-metni-oncesi')}
                <span
                    className="text-purple underline"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setSelectedDocument(<DocumentSirketAydinlatmaMetni noHorizontalSpacing={true} />);
                    }}
                >
                    {documentsTranslations.getTranslation('aydinlatma-metni')}
                </span>
                {documentsTranslations.getTranslation('aydinlatma-metni-sonrasi')}
            </div>

            <div
                className={`basicCheckbox !pb-0 ${checkboxes.acikRiza ? "selected" : ""}`}
                style={requesting ? { pointerEvents: "none" } : {}}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxes.acikRiza}
                            onChange={(e) => {
                                setCheckboxes({ ...checkboxes, acikRiza: e.target.checked });
                            }}
                        />
                    }
                    label={
                        <div className="text-12-16-400 text-black">
                            {documentsTranslations.getTranslation('sirket-acik-riza-beyani')}

                        </div>
                    }
                />
            </div>

            <div className="flex flex-col">

                <div className="text-12-16-400 text-black">
                    {mainContext?.getTranslation(
                        "giris-kayit",
                        "ticari-ileti-onayi"
                    )}
                </div>
                <div
                    className={`flex justify-start text-left items-center`}
                // style={requesting ? { pointerEvents: "none" } : {}}
                >

                    <div className={`basicCheckbox !pb-0 ${checkboxes.ticariIletiSms ? "selected" : ""}`}>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.ticariIletiSms}
                                    onChange={(e) => {
                                        setCheckboxes({ ...checkboxes, ticariIletiSms: e.target.checked });
                                    }}
                                />
                            }
                            label={
                                <div
                                    className="text-12-16-400 text-black flex-col justify-center"
                                    onClick={() => {
                                        // setCheckKvkk(checkKvkk);
                                    }}
                                >
                                    {mainContext
                                        ?.getTranslation("common", "sms")
                                        .toUpperCase()}

                                </div>
                            }
                        />
                    </div>
                    <div className={`basicCheckbox !pb-0 ${checkboxes.ticariIletiCall ? "selected" : ""}`}>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.ticariIletiCall}
                                    onChange={(e) => {
                                        setCheckboxes({ ...checkboxes, ticariIletiCall: e.target.checked });
                                    }}
                                />
                            }
                            label={
                                <div
                                    className="text-12-16-400 text-black flex-col justify-center"
                                    onClick={() => {
                                        // setCheckKvkk(checkKvkk);
                                    }}
                                >
                                    {mainContext
                                        ?.getTranslation("common", "telefon")}
                                </div>
                            }
                        />
                    </div>
                    <div className={`basicCheckbox !pb-0 ${checkboxes.ticariIletiEmail ? "selected" : ""}`}>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.ticariIletiEmail}
                                    onChange={(e) => {
                                        setCheckboxes({ ...checkboxes, ticariIletiEmail: e.target.checked });
                                    }}
                                />
                            }
                            label={
                                <div
                                    className="text-12-16-400 text-black flex-col justify-center"
                                    onClick={() => {
                                        // setCheckKvkk(checkKvkk);
                                    }}
                                >
                                    {mainContext
                                        ?.getTranslation("common", "eposta")}
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
        <CDocumentPopup
            document={selectedDocument}
            onClose={() => {
                setSelectedDocument(undefined);
            }}
            onAccept={() => {
                setSelectedDocument(undefined);
            }}
            buttonText={'Tamam'}
        />
    </>
}


export function CGeneralAgreements({ checkboxes, setCheckboxes, requesting }: IProps) {

    const mainContext = useContext(MainContext);
    const navigate = useNavigate();
    const documentsTranslations = useTranslation(DokumanTranslation)


    return <div className="flex flex-col pt-[24px] gap-[24px]">

        <div className="text-12-16-400 text-black">
            {documentsTranslations.getTranslation('aydinlatma-metni-oncesi')}
            <span
                className="text-purple underline"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    window.open('/sozlesmeler/#internet-platformu-aydinlatma', '_blank')
                }}
            >
                {documentsTranslations.getTranslation('aydinlatma-metni')}
            </span>
            {documentsTranslations.getTranslation('aydinlatma-metni-sonrasi')}
        </div>

        <div
            className={`basicCheckbox !pb-0 ${checkboxes.acikRiza ? "selected" : ""}`}
            style={requesting ? { pointerEvents: "none" } : {}}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checkboxes.acikRiza}
                        onChange={(e) => {
                            setCheckboxes({ ...checkboxes, acikRiza: e.target.checked });
                        }}
                    />
                }
                label={
                    <div className="text-12-16-400 text-black">
                        {documentsTranslations.getTranslation('acik-riza-beyani')}
                    </div>
                }
            />
        </div>

        <div className="flex flex-col">

            <div className="text-12-16-400 text-black">
                {mainContext?.getTranslation(
                    "giris-kayit",
                    "ticari-ileti-onayi"
                )}

            </div>
            <div
                className={`flex justify-start text-left items-center`}
            // style={requesting ? { pointerEvents: "none" } : {}}
            >

                <div className={`basicCheckbox !pb-0 ${checkboxes.ticariIletiSms ? "selected" : ""}`}>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkboxes.ticariIletiSms}
                                onChange={(e) => {
                                    setCheckboxes({ ...checkboxes, ticariIletiSms: e.target.checked });
                                }}
                            />
                        }
                        label={
                            <div
                                className="text-12-16-400 text-black flex-col justify-center"
                                onClick={() => {
                                    // setCheckKvkk(checkKvkk);
                                }}
                            >
                                {mainContext
                                    ?.getTranslation("common", "sms")
                                    .toUpperCase()}

                            </div>
                        }
                    />
                </div>
                <div className={`basicCheckbox !pb-0 ${checkboxes.ticariIletiCall ? "selected" : ""}`}>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkboxes.ticariIletiCall}
                                onChange={(e) => {
                                    setCheckboxes({ ...checkboxes, ticariIletiCall: e.target.checked });
                                }}
                            />
                        }
                        label={
                            <div
                                className="text-12-16-400 text-black flex-col justify-center"
                                onClick={() => {
                                    // setCheckKvkk(checkKvkk);
                                }}
                            >
                                {mainContext
                                    ?.getTranslation("common", "telefon")}
                            </div>
                        }
                    />
                </div>
                <div className={`basicCheckbox !pb-0 ${checkboxes.ticariIletiEmail ? "selected" : ""}`}>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkboxes.ticariIletiEmail}
                                onChange={(e) => {
                                    setCheckboxes({ ...checkboxes, ticariIletiEmail: e.target.checked });
                                }}
                            />
                        }
                        label={
                            <div
                                className="text-12-16-400 text-black flex-col justify-center"
                                onClick={() => {
                                    // setCheckKvkk(checkKvkk);
                                }}
                            >
                                {mainContext
                                    ?.getTranslation("common", "eposta")}
                            </div>
                        }
                    />
                </div>


            </div>
        </div>



    </div>
}
