import { ProfilTranslation } from "src/objects/TranslationModel";
import { useTranslation } from "../hooks/TranslationHook";
import { FilterConfig } from "../types/TFilter";
import { CDashboardFilters } from "./CDashboardFilters";
import { CProfileCard } from "./Profile/CProfileCard";

interface IProps {
  onFiltersApplied: (filters: Map<string, FilterConfig[]>) => void;
  hideFilters?: boolean;
}

export function CProfile({ onFiltersApplied, hideFilters }: IProps) {
  const profileTranslations = useTranslation(ProfilTranslation)

  return (
    <div id={'dashboardprofile'} className="flex-col min-w-[240px] w-[240px]">
      <div className="text-32-40-500 pb-[24px]">
        pofft’a
        <br />
        Hoş Geldin 👋
      </div>
      <div>
        <CProfileCard />
        {hideFilters != true &&
          <CDashboardFilters onFiltersApplied={onFiltersApplied} />
        }
      </div>
    </div>
  );
}
