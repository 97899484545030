import { Checkbox } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProfilTranslation, ProjeTranslation } from 'src/objects/TranslationModel';
import CLoadingAnimation from "../../components/CLoadingAnimation";
import { CAboutEditModalCompany } from "../../components/Profile/CAboutEditModalCompany";
import { CProfileReachPermissionModal } from "../../components/Profile/CProfileReachPermissionModal";
import { CProfileSocialModal } from "../../components/Profile/CProfileSocialModal";
import { ComponentShowModal } from "../../components/Profile/ComponentShowModal";
import cstyles from '../../components/Profile/Profile.module.scss';
import { MainContext } from "../../context/MainContext";
import { useTranslation } from "../../hooks/TranslationHook";
import { UserQuery } from "../../pages/Profile/Profile";
import { cities, countries, getCities, getCountries } from "../../service/commonRequest";
import { getMyCompanyData, parseCompanyDataRequest } from "../../service/companyRequest";
import { PageState } from "../../types/Context";
import { CompanyContactPerson, CompanyProfileData, deepCopyCompany } from "../../types/TCompanyData";
import { TError } from "../../types/TError";
import { mergeCss } from "../../utils/cssMerge";
import { postV2 } from "../../utils/request";
import { CCompanyContactEditModal } from "../Profile/CCompanyContactEditModal";
import { CCompanyJobsModal } from "../Profile/CCompanyJobsModal";
import { CCompanyPassUpdateModal } from "../Profile/CCompanyPassUpdateModal";
import { CContactPersonEditModal } from "../Profile/CContactPersonEditModal";
import { CUserEditModalCompany } from "../Profile/CUserEditModalCompany";
import { NotificationType } from "../Toast/Toast";
import styles from './Company.module.scss';

interface iProps {
  userQuery?: UserQuery
  editProfileModal?: boolean
}
export function Company({ userQuery, editProfileModal }: iProps) {
  const mainContext = useContext(MainContext);
  const profileTranslations = useTranslation(ProfilTranslation)
  const projectTranslations = useTranslation(ProjeTranslation)

  const navigate = useNavigate();
  const [editable, setEditable] = useState(true)
  const [modalState, setModalState] = useState('')

  const [profileData, setProfileData] = useState<CompanyProfileData | undefined>(undefined)

  const [showModal, setShowModal] = useState<undefined | JSX.Element>(undefined)

  const isAllLoaded =
    profileData != undefined;

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 150);
    if (localStorage.getItem('userData') == null) {
      navigate('/')
      return
    } else {
      if (editProfileModal === true) {
        setModalState('user')
        window.history.replaceState({}, '')
      }
    }
  }, []);

  async function fetchCommonData() {
    await getCountries(mainContext).then((resp) => {
      if (resp instanceof TError) {
        return
      }
    });
    await getCities(mainContext).then((resp) => {
      if (resp instanceof TError) {
        return
      }
    });
    fetchProfileData()
  }

  function fetchProfileData(callback?: any) {
    setProfileData(undefined)
    getMyCompanyData().then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'getData')
      } else {
        setProfileData(r)
        if (callback) {
          callback(r)
        }
        if (window.location.href.endsWith('/settings')) {
          setModalState('user')
        }
        const refs = window.location.href.split("?p=");
        if (refs !== null && refs.length > 1) {
          const targetto = refs[1];
          if (targetto === 'offers') {
            setModalState('ilanlarim')
            window.history.replaceState(null, document.title, refs[0])
          }
        }
      }
    })
  }

  useEffect(() => {
    if (mainContext?.userData?.token) {
      const isExpired = mainContext.isExpired('company')
      if (!isExpired) {
        fetchCommonData()
      } else {
        setTimeout(() => {
          mainContext.setSigninupState(PageState.Signin)
        }, 1500)
      }
    }
  }, [mainContext?.userData?.token]);

  function getLeftCardRow(editKey: string, title?: string, subs?: string[], texts?: string[], customComponent?: JSX.Element, addKey?: string) {
    return <div className={'text-start flex-col items-start w-full'}>
      <div className={'pb-[12px] w-full gap-[16px] flex justify-between'}>
        <h1>{title}</h1>
        <div className={'flex gap-[16px]'}>
          {editable && addKey &&
            <div role={'button'} onClick={() => setModalState(addKey)}>
              <img src={'/images/addButton.svg'} />
            </div>
          }
          {editable &&
            <div role={'button'} onClick={() => setModalState(editKey)}
              className={mergeCss([styles.editButton, '!relative'])}>
              <img src={'/images/editButton.svg'} />
            </div>
          }
        </div>
      </div>
      {subs &&
        subs.map(sub =>
          <h4>{sub}</h4>
        )
      }
      {texts &&
        texts.map(o => <h5>{o}</h5>)
      }
      {customComponent}
    </div>
  }

  function getAboutCard(profileData: CompanyProfileData | undefined) {
    return <div className={'whiteShadowCard py-[36px] px-[24px]'}>
      <div className={'flex justify-between'}>
        <div className={'flex gap-[14px]'}>
          <p className={styles.header}>{profileTranslations.getTranslation('sirket-hakkinda')}</p>
          {editable &&
            <div onClick={() => setModalState('hakkimda')} role={'button'}
              className={mergeCss([styles.editButton, '!relative'])}>
              <img src={'/images/editButton.svg'} />
            </div>
          }
        </div>
      </div>
      <div className={'h-[24px]'}></div>
      <p className={'text-15-24-500 text-black'}>{profileData?.about}</p>
    </div>
  }

  function getCompanyContactCard(profileData: CompanyProfileData | undefined) {
    return <div className={'w-full whiteShadowCard py-[36px] px-[24px] flex flex-col gap-[24px]'}>
      <div className={'flex gap-[14px]'}>
        <div className={styles.skills}>{profileTranslations.getTranslation('sirket-iletisimi')}</div>
        {editable &&
          <div onClick={() => {
            setModalState('sirket-iletisimi')
          }} role={'button'} className={mergeCss([styles.editButton, '!relative'])}>
            <img src={'/images/editButton.svg'} />
          </div>
        }
      </div>
      <div className={'overflow-x-auto flex gap-[28px]'}>
        {profileData?.phone && profileData?.phone.length > 0 &&
          <div className={mergeCss([cstyles.jobCard, '!h-auto !px-[24px] !py-[32px] flex flex-col gap-[12px]'])}>
            <p className={'text-16-24-700'}>{profileTranslations.getTranslation('sirket-telefon-numarasi')}</p>
            <p className={'text-14-24-500'}>{profileData?.phone}</p>
          </div>
        }
        {profileData?.address && profileData?.address.length > 0 &&
          <div className={mergeCss([cstyles.jobCard, '!h-auto !px-[24px] !py-[32px] flex flex-col gap-[12px]'])}>
            <p className={'text-16-24-700'}>{profileTranslations.getTranslation('sirket-adresi')}</p>
            <p className={'text-14-24-500'}>{profileData?.address} {profileData?.cityID > 0 &&
              <span
                className={'text-14-24-400'}>{cities.find(c => c.id == (profileData?.cityID ?? 0))?.label}</span>
            } {profileData?.countryID > 0 &&
              <span
                className={'text-14-24-400'}>{countries.find(c => c.id == (profileData?.countryID ?? 0))?.label}</span>
              }</p>
          </div>
        }
      </div>
    </div>
  }

  function getContactPersonCard(contactPersonData: CompanyContactPerson | undefined) {
    return <div className={'w-full whiteShadowCard py-[36px] px-[24px] flex flex-col gap-[24px]'}>
      <div className={'flex gap-[14px]'}>
        <div className={styles.skills}>{profileTranslations.getTranslation('sirket-yetkilisi')}</div>
        {editable &&
          <div onClick={() => {
            setModalState('sirket-yetkilisi')
          }} role={'button'} className={mergeCss([styles.editButton, '!relative'])}>
            <img src={'/images/editButton.svg'} />
          </div>
        }
      </div>
      <div className={'overflow-x-auto flex gap-[28px]'}>
        {contactPersonData?.name && contactPersonData?.name.length > 0 && contactPersonData?.surname && contactPersonData?.surname.length > 0 &&
          <div className={mergeCss([cstyles.jobCard, '!h-auto !px-[24px] !py-[32px] flex flex-col gap-[12px]'])}>
            <p className={'text-16-24-700'}>{profileTranslations.getTranslation('yetkili-adsoyad')}</p>
            <p className={'text-14-24-500'}>{contactPersonData.name} {contactPersonData.surname}</p>
          </div>
        }
        {contactPersonData?.email && contactPersonData?.email.length > 0 &&
          <div className={mergeCss([cstyles.jobCard, '!h-auto !px-[24px] !py-[32px] flex flex-col gap-[12px]'])}>
            <p className={'text-16-24-700'}>{profileTranslations.getTranslation('yetkili-eposta')}</p>
            <p className={'text-14-24-500'}>{contactPersonData.email}</p>
          </div>
        }
        {contactPersonData?.email && contactPersonData?.phone.length > 0 &&
          <div className={mergeCss([cstyles.jobCard, '!h-auto !px-[24px] !py-[32px] flex flex-col gap-[12px]'])}>
            <p className={'text-16-24-700'}>{profileTranslations.getTranslation('yetkili-telefon')}</p>
            <p className={'text-14-24-500'}>{contactPersonData.phone}</p>
          </div>
        }
      </div>
    </div>
  }

  return <div
    className="main-width1200 m-auto main-padding pt-[96px] pb-[48px] lg:pb-[72px] items-center h-full flex-col gap-[30px]">
    {!isAllLoaded ? <div className={'h-[100vh]'}><CLoadingAnimation /></div> : <>
      <div
        className={'flex flex-col gap-[24px] w-full h-[188px] bg-gray-300 px-[30px] pt-[72px] rounded-[16px] overflow-hidden whiteShadowCard relative'}>
        <div className={'absolute top-0 left-0 bottom-0 right-0 flex'}>
          <img className={'w-full object-cover'} src={profileData?.pagePhoto?.url} />
        </div>
        <p className={'z-20 text-white text-24-24-900'}>{profileData?.name}</p>
        <p className={'z-20 text-white text-24-24-500'}>{profileData?.bannerText}</p>

      </div>


      <div className={'flex w-full justify-between px-[24px] py-[48px] whiteShadowCard items-center'}>
        <div className={'flex gap-[11px]'}>
          <div className="relative w-[83px] h-[82px] p-[4px] flex justify-center items-center">
            <img
              className="w-full h-full rounded-full bg-gray-300"
              src={profileData.profilePhoto?.url}
            />
            <div role={'button'} onClick={() => setModalState('user')} className={styles.editButton}>
              <img src={'/images/editButton.svg'} />
            </div>
          </div>
          <div>
            <div className="text-black text-18-24-600 underline">
              {profileData.name}
            </div>
            <div className="flex gap-[5px] text-grey text-14-24-500 pt-[4px]">
              <img src={'/images/locationSmall.svg'} />
              {cities.find(c => c.id == (profileData?.cityID ?? 0))?.label + " " + countries.find(c => c.id == (profileData?.countryID ?? 0))?.label}
            </div>
          </div>
        </div>
        <div className={'flex gap-[10px]'}>
          {profileData?.socialMedias.instagram && profileData?.socialMedias.instagram.link &&
            <div style={{ cursor: 'pointer' }} className={cstyles.socialmedia} onClick={() => {
              window.open(
                profileData?.socialMedias.instagram!.link!,
                "_blank"
              );
            }}><img src={'/images/social_Instagram.svg'} /></div>
          }
          {profileData?.socialMedias.twitter && profileData?.socialMedias.twitter.link &&
            <div style={{ cursor: 'pointer' }} className={cstyles.socialmedia} onClick={() => {
              window.open(
                profileData?.socialMedias.twitter!.link!,
                "_blank"
              );
            }}><img src={'/images/social_X.svg'} /></div>
          }
          {profileData?.socialMedias.linkedin && profileData?.socialMedias.linkedin.link &&
            <div style={{ cursor: 'pointer' }} className={cstyles.socialmedia} onClick={() => {
              window.open(
                profileData?.socialMedias.linkedin!.link!,
                "_blank"
              );
            }}><img src={'/images/social_Linkedin.svg'} /></div>
          }
          {profileData?.socialMedias.facebook && profileData?.socialMedias.facebook.link &&
            <div style={{ cursor: 'pointer' }} className={cstyles.socialmedia} onClick={() => {
              window.open(
                profileData?.socialMedias.facebook!.link!,
                "_blank"
              );
            }}><img src={'/images/social_Facebook.svg'} /></div>
          }
          {profileData?.socialMedias.behance && profileData?.socialMedias.behance.link &&
            <div style={{ cursor: 'pointer' }} className={cstyles.socialmedia} onClick={() => {
              window.open(
                profileData?.socialMedias.behance!.link!,
                "_blank"
              );
            }}><img src={'/images/social_Behance.svg'} /></div>
          }
          {profileData?.socialMedias.dribble && profileData?.socialMedias.dribble.link &&
            <div style={{ cursor: 'pointer' }} className={cstyles.socialmedia} onClick={() => {
              window.open(
                profileData?.socialMedias.dribble!.link!,
                "_blank"
              );
            }}><img src={'/images/social_Dribble.svg'} /></div>
          }
        </div>
      </div>
      <div className={'flex w-full gap-[24px]'}>
        <div
          className={'max-w-[283px] flex-col gap-[40px] px-[24px] py-[36px] whiteShadowCard !text-start !items-start mb-auto'}>
          <div className={'text-start flex-col items-start w-full'}>
            <div className={'pb-0 w-full gap-[16px] flex justify-between'}>
              <h1>{mainContext?.getTranslation('menu', 'ilanlarim')}</h1>
              <div className={'flex gap-[12px]'}>
                <div role={'button'} onClick={() => navigate('/project-upsert')}>
                  <img src={'/images/addButton.svg'} />
                </div>
                <div role={'button'} onClick={() => setModalState('ilanlarim')}
                  className={mergeCss([styles.editButton, '!relative'])}>
                  <img src={'/images/editButton.svg'} />
                </div>
              </div>
            </div>
          </div>
          {getLeftCardRow('reachPermission', mainContext?.getTranslation('filters', 'ticari-ileti-onayi'), undefined, undefined,
            <div className={'w-[85%]'}>
              <div className={'flex justify-between items-center'}>
                <h3>Sms</h3>
                <Checkbox
                  className={'checkBox basicCheckbox'}
                  checked={profileData?.smsPermission}
                />
              </div>
              <div className={'flex justify-between items-center'}>
                <h3>Arama</h3>
                <Checkbox
                  className={'checkBox basicCheckbox'}
                  checked={profileData?.callPermission}
                />
              </div>
              <div className={'flex justify-between items-center'}>
                <h3>E-posta</h3>
                <Checkbox
                  className={'checkBox basicCheckbox'}
                  checked={profileData?.emailPermission}
                />
              </div>
            </div>)}
          {getLeftCardRow('social', mainContext?.getTranslation('profile', 'sosyal-medya'), undefined, undefined,
            <div className={'w-[85%]'}>
            </div>)}
          {getLeftCardRow('pass', mainContext?.getTranslation('profile', 'sifre-degistir'), undefined, undefined,
            <div className={'w-[85%]'}>
            </div>)}
        </div>
        <div className={'flex-col gap-[12px] flex-1 w-full min-w-0'}>
          {getAboutCard(profileData)}
        </div>
      </div>

      {getCompanyContactCard(profileData)}
      {getContactPersonCard(profileData?.contactPerson)}

      {(mainContext && profileData) &&
        <CUserEditModalCompany getTranslation={profileTranslations.getTranslation} data={profileData} isOpen={modalState == 'user'}
          close={() => setModalState('')}
          updated={(callback?: any) => {
            fetchProfileData(callback)
          }} />
      }
      {(mainContext && profileData) &&
        <CCompanyPassUpdateModal getTranslation={profileTranslations.getTranslation} data={profileData} isOpen={modalState == 'pass'}
          close={() => setModalState('')}
          updated={(callback?: any) => {
            fetchProfileData(callback)
          }} />
      }
      <CCompanyContactEditModal getTranslation={profileTranslations.getTranslation}
        company={profileData}
        isOpen={modalState == 'sirket-iletisimi'}
        updated={() => {
          fetchProfileData()
          setModalState('')
        }}
        close={() => {
          setModalState('')
        }} />
      <CContactPersonEditModal getTranslation={profileTranslations.getTranslation}
        company={profileData}
        isOpen={modalState == 'sirket-yetkilisi'}
        updated={() => {
          fetchProfileData()
          setModalState('')
        }}
        close={() => {
          setModalState('')
        }} />
      <CAboutEditModalCompany getTranslation={profileTranslations.getTranslation} isOpen={modalState == 'hakkimda'}
        company={profileData}
        close={() => {
          setModalState('')
        }}
        update={(newCompany) => {
          setProfileData(newCompany)
          setModalState('')
        }} />
      <CProfileReachPermissionModal isOpen={modalState == 'reachPermission'} sms={profileData?.smsPermission}
        phone={profileData?.callPermission} email={profileData?.emailPermission}
        onClose={() => setModalState('')}
        onUpdate={(s, p, e) => {
          setProfileData({
            ...profileData,
            smsPermission: s,
            callPermission: p,
            emailPermission: e
          })
        }} />

      {profileData != undefined &&
        <CProfileSocialModal getTranslation={mainContext?.getTranslation} isOpen={modalState == 'social'}
          data={profileData.socialMedias}
          close={() => {
            setModalState('')
          }}
          update={(tempData) => {
            const tempCompany = deepCopyCompany(profileData)
            tempCompany.socialMedias.twitter = tempData.twitter
            tempCompany.socialMedias.linkedin = tempData.linkedin
            tempCompany.socialMedias.facebook = tempData.facebook
            tempCompany.socialMedias.dribble = tempData.dribble
            tempCompany.socialMedias.behance = tempData.behance
            tempCompany.socialMedias.instagram = tempData.instagram
            let data = parseCompanyDataRequest(tempCompany);
            return postV2('/Company/update', data)
          }}
          updated={() => {
            fetchProfileData()
          }} />
      }
      <ComponentShowModal onClose={() => setShowModal(undefined)} component={showModal} />

      {projectTranslations &&
        <CCompanyJobsModal projectTranslations={projectTranslations!.getTranslation}
          onClose={() => setModalState('')}
          isOpen={modalState == 'ilanlarim'} />
      }
    </>}
  </div>
}
