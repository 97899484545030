export function DocumentBasvuruYonetimProseduru() {
    return <>
        <div className="documentContainer">
            <h1 className="documentHeader">VERİ SAHİBİ BAŞVURULARININ ALINMASI, DEĞERLENDİRİLMESİ VE YANITLANMASI PROSEDÜRÜ</h1>

            <p className="documentText">
                İşbu Veri Sahibi Başvurularının Alınması, Değerlendirilmesi ve Yanıtlanması Prosedürü (“Prosedür”), veri sahipleri tarafından bilgi edinme
                amacıyla POFFT DİJİTAL ÇÖZÜMLER TİCARET ANONİM ŞİRKETİ’ne (“Şirket”) yapılan başvuruların alınması, değerlendirilmesi ve yanıtlanmasına
                ilişkin iş ve işlemler konusunda usul ve esasları belirlemek amacıyla hazırlanmıştır.
            </p>
            <p className="documentText">Kişisel verilere ilişkin olarak veri sahipleri tarafından yapılan başvuruların alınması, değerlendirilmesi ve yanıtlanmasına ilişkin iş ve işlemler, Şirket tarafından bu doğrultuda hazırlanmış olan işbu Prosedür’e uygun olarak gerçekleştirilir.
            </p>

            <h2 className="documentTitle">1. TANIMLAR</h2>
            <ul className="documentList">
                <li><strong>Kanun:</strong> 6698 sayılı Kişisel Verilerin Korunması Kanunu</li>
                <li><strong>Kurul:</strong> Kişisel Verileri Koruma Kurulu</li>
                <li><strong>Veri Sahibi:</strong> Kişisel verisi işlenen gerçek kişi</li>
                <li><strong>Kişisel Veri:</strong> Kanun kapsamında bulunduğu sürece, kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi</li>
            </ul>

            <h2 className="documentTitle">2. BAŞVURUNUN ALINMASI</h2>
            <h3 className="subSection">2.1. Başvurunun Şekli</h3>
            <p className="documentText">
                Veri Sahipleri, Şirket tarafından toplanan kişisel verilerine ilişkin bilgi almak ve Kanun’un 11. maddesinde belirtilen haklarını kullanmak için yapacakları başvurularına Kanun’un 13. maddesi uyarınca yazılı olarak Şirket irtibat kişisine ileteceklerdir.
            </p>
            <p className="documentText">
                Buna göre Veri Sahiplerince yapılacak başvurular yazılı olarak aşağıdaki şekilde yapılabilir:
            </p>
            <ul className="documentList">
                <li>Maslak Mah. Maslak Meydan Sk. Beybi Giz Plaza A Blok No: 1 İç Kapı No: 65 Sarıyer / İstanbul adresine kimlik teyidinizin yapılması sağlanarak bizzat;</li>
                <li>Noter kanalıyla resmi usulde.</li>
            </ul>


            <h3 className="subSection">2.2. Başvurunun İçeriği</h3>
            <p className="documentText">Veri Sahibi taleplerinin değerlendirilebilmesi için öncelikle Veri Sahibinin, Şirket nezdinde işlenen kişisel verilerin sahibi olup olmadığı tespit edilecektir. Bu bakımından, Kanun kapsamında Şirketimize yapılacak başvurularda Veri Sahibinin kimlik bilgilerinin açıkça ve gerçeğe uygun şekilde belirtilmesi gerekmektedir.</p>

            <p className="documentText">Şarta bağlı olan taleplerde Veri Sahibi, bu şartın nasıl gerçekleştiği konusunda gerekli bilgileri vermeli ve bu iddiasını kanıtlayacak belgeleri Şirket’e iletmelidir.</p>

            <p className="documentText">İşbu Prosedür’de belirlenen yollarla alınmayan başvurular, Veri Sahibinin kimliği tespit edilmiş ve Şirket tarafından Kanun kapsamında başvuru için istenen bilgi ve/veya belgeler sağlanmış ise bu tür yolarla yapılan başvurular değerlendirmeye alınabilecektir. Aksi takdirde, yapılan başvurular usule aykırılık sebebiyle reddedilecektir.</p>

            <p className="documentText">İşbu maddede belirtilen nitelikleri taşımayan başvurular değerlendirilerek, istenen bilgiler elde edilene kadar Veri Sahibi ile iletişimde olunacak; ancak talep edilen bilgi ve/veya belgelerin Veri Sahibi tarafından temin edilmemesi halinde Veri Sahibi’nin başvurusu usule aykırılık sebebiyle reddedilecektir.</p>


            <h2 className="documentTitle">3. DİĞER DURUMLAR</h2>
            <h3 className="subSection">3.1. Vekil veya Yasal Temsilci Tarafından Yapılan Başvuru</h3>
            <p className="documentText">
                Kanun kapsamında Şirket’e yapılacak başvurular, kanıtlayacak resmi belgenin ibrazı halinde Veri Sahibi’nin vekili veya kanuni temsilcisi tarafından da yapılabilecektir.
            </p>

            <h3 className="subSection">3.2. Başvuru Ücreti</h3>
            <p className="documentText">
                Kanun’da Veri Sorumlusu’nun kendisine iletilen talebi ücretsiz olarak sonuçlandırması öngörülmüştür. Ancak, işlemin ayrıca bir maliyeti gerekmesi halinde Kurul’un belirleyeceği esaslar doğrultusunda bir ücretlendirme yapılmasının da mümkün olabileceği ifade edilmiştir. Bu kapsamda, Şirket’e yapılacak başvuruların sonuçlandırılmasının herhangi bir ek maliyet gerektirmesi halinde, Şirket Veri Sahibi’nden ücret talep edebilecektir.
            </p>

            <h2 className="documentTitle">4. BAŞVURU DEĞERLENDİRME SÜRECİ</h2>
            <p className="documentText">
                Veri Sahibi tarafından yapılan başvurularda eksik bilgi ve/veya belge olduğunun tespiti halinde, bu husus Veri Sahibi’ne bildirilecektir. Talep edilen bilge ve/veya belgelerin Veri Sahibi tarafından temin edilmemesi halinde Veri Sahibi’nin başvurusu usule aykırılık sebebiyle reddedilecektir.
            </p>
            <p className="documentText">
                Üçüncü kişilere ait kişisel verileri paylaşmadan Veri Sahibi’nin başvurusunun yanıtlanması mümkün olmayan durumlarda Şirket tarafından aşağıdaki üç adımlı değerlendirme süreci uygulanacaktır:
            </p>

            <ul className="documentList">
                <li>Başvurunun üçüncü kişiye ait kişisel verileri paylaşmadan yanıtlanmasının (örneğin, üçüncü kişiye ait kişisel verilerin silinmesi veya karartılması) mümkün olup olmadığı değerlendirilecektir.</li>
                <li>Üçüncü kişinin kişisel verilerinin paylaşılmasına açık rıza verip vermediği tespit edilecektir.</li>
                <li>Üçüncü kişinin açık rızasının alınmayacak olması halinde, söz konusu kişisel verilerin açık rıza alınmaksızın paylaşılıp paylaşılamayacağı değerlendirilecektir.</li>
            </ul>

            <p className="documentText">Üçüncü kişinin verisi paylaşılmaksızın başvurunun sonuçlandırılmasının mümkün olmaması halinde; öncelikle, kişisel verisi paylaşılmak durumunda kalınan Veri Sahibi’nden açık rıza alınması yoluna başvurulacaktır. Üçüncü kişinin verilerinin paylaşılmasına rıza vermemesi halinde, üçüncü kişinin bilgileri tamamen ayıklanarak başvuru yanıtlanacaktır.
            </p>
            <p className="documentText">Kişisel verileri paylaşılacak olan üçüncü kişiye ulaşılamaması halinde, üçüncü kişi kişisel verilerini içeren bilginin paylaşılması konusunda Şirket tarafından azami dikkat ve hassasiyet gösterilecektir. Bu şekilde, zorunlu olması halinde üçüncü kişilere ait kişisel veriler de paylaşılabilecektir.
            </p>



            <h2 className="documentTitle">5. BAŞVURULARIN DEĞERLENDİRME SÜRELERİ</h2>
            <p className="documentText">
                Veri Sahibi’nin talepleri, Şirket tarafından en kısa sürede ve en geç otuz (30) gün içerisinde değerlendirilerek sonuçlandırılacaktır.
            </p><p className="documentText">
                Şirket’e yapılan başvurular, azami üç (3) gün içerisinde Şirket’in ilgili departmanına yönlendirilecek; başvurunun yönlendirildiği departman tarafından yapılacak araştırmalar azami bir (1) hafta içerisinde sonuçlandırılacaktır.
            </p>

            <h2 className="documentTitle">6. BAŞVURULARIN YANITLANMASI</h2>
            <p className="documentText">
                Veri Sahibi tarafından Şirket’e yapılan başvurular, Şirket nezdinde atanan irtibat kişisi tarafından yanıtlanır ve başvurulara ilişkin yanıtlarda aşağıdaki bilgilere yer verilir:
            </p>
            <ul className="documentList">
                <li>Talebi Yapan Başvuru Sahibi</li>
                <li>Talepler</li>
                <li>Talepler Neticesinde Sağlanan Bilgi ve Belgeler</li>
                <li>Talebin Alınma Tarihi</li>
                <li>Taleple ilgili ekstra bilgi ve belge talep edilmiş ise; bu taleplerin tarihi ve ilgili cevapların alınması tarihi</li>
                <li>Taleple ilgili yapılan işlemler</li>
                <li>Taleplere Karşı Şirketin Cevapları</li>
                <li>Talep Yanıtlama Tarihi</li>
                <li>Yetkili İmza</li>
            </ul>
            <p className="documentText">İlgili başvuruyla ilişkin oluşan olay kayıtları, evraklar ve sonuçları bu konuda oluşturulan elektronik dizinde saklanır. Yazılı gönderi kaydının bir kopyası da arşivde saklanır.
            </p>


            <h2 className="documentTitle">7. YÜRÜRLÜK VE GÜNCEL TUTMA</h2>
            <p className="documentText">
                İşbu Prosedür, 01.05.2022 tarihinde yürürlüğe girmiş olup; Kanun, ilgili ikincil mevzuat, Kurul Kararları ve Şirket iş süreçleri doğrultusunda KVK Komitesi tarafından her yıl başında yeniden değerlendirilecek ve gerekmesi halinde güncellenecektir.
            </p>
        </div>

    </>
}