import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'src/hooks/TranslationHook';
import { DokumanTranslation } from 'src/objects/TranslationModel';

const CookiesPopup = () => {

    const cookiesTranslations = useTranslation(DokumanTranslation)

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Check if user has already accepted the policy
        const consentGiven = localStorage.getItem('cookiesConsent');
        if (!consentGiven) {
            setIsVisible(true);
        }
    }, []);

    const acceptCookies = () => {
        localStorage.setItem('cookiesConsent', 'true'); // Save consent in localStorage
        setIsVisible(false);
    };

    const style: Record<string, string | number> = {
        "--tw-bg-opacity": 0.5,
        zIndex: 99999999,
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    };

    return (
        isVisible ? (
            <div className='border-1 border-gray-200 rounded-[16px] p-[16px]' style={{ zIndex: 99999999, position: 'fixed', bottom: '16px', right: '16px', width: 'min(450px,70vw)', background: 'white', padding: '10px', paddingLeft: '16px', boxShadow: '-2px -2px 5px 3px rgba(0, 0, 0, 0.1)' }}>
                <div className="text-14-16-400 text-black">
                    {cookiesTranslations.getTranslation('cookies-policy-before')}
                    <span
                        className="text-purple underline"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            window.open('/sozlesmeler#cerez-politikasi', '_blank')
                        }}
                    >
                        {cookiesTranslations.getTranslation('cookies-policy-link')}
                    </span>
                    {cookiesTranslations.getTranslation('cookies-policy-after')}
                </div>
                <div className='h-[16px]' />
                <Button
                    onClick={() => {
                        acceptCookies();
                    }}
                    className="purpleButtonMini"
                >
                    {cookiesTranslations.getTranslation("cerez-kabul-et")}
                </Button>
            </div>
        ) : <></>
    );
};

export default CookiesPopup;
