import {getV2} from "../utils/request";
import {TError} from "../types/TError";
import {TEducation} from "../types/TEducation";
import {parseToCertificate, TCertificate} from "../types/TCertificate";
import {parseToExperience, TExperience} from "../types/TExperience";
import {parseToPortfolio, TPortfolio} from "../types/TPortfolio";
import {parseMedioObjectToRequest, parseToMediaObject, TMediaObject} from "../types/TMediaObject";
import {yyyymmToDate, yyyymmToString} from "../utils/date";
import {EnumProfileUpdateType, SocialMediaId} from "../types/EnumProfileUpdateType";
import {parsePhone} from "../utils/util";
import {
  CompanyContactPerson,
  CompanyProfileData,
  parseContactPerson,
  SocialMedia,
  SocialMedias
} from "../types/TCompanyData";

export const initialSocialmedias: SocialMedias = {
  twitter: {socialMediaID: SocialMediaId.Twitter, socialMedia: 'Twitter', link: ''},
  linkedin: {socialMediaID: SocialMediaId.Linkedin, socialMedia: 'Linkedin', link: ''},
  facebook: {socialMediaID: SocialMediaId.Facebook, socialMedia: 'Facebook', link: ''},
  dribble: {socialMediaID: SocialMediaId.Dribble, socialMedia: 'Dribble', link: ''},
  behance: {socialMediaID: SocialMediaId.Behance, socialMedia: 'Behance', link: ''},
  instagram: {socialMediaID: SocialMediaId.Instagram, socialMedia: 'Instagram', link: ''},
}

export interface ProfessionalProfileData {
  professionalId: number,
  active: boolean
  title: string,
  address: string,
  paymentType?: number,
  workingTypes: string[],
  professions: string[]
  languages: string[],
  educations: TEducation[],
  certificates: TCertificate[],
  experiences: TExperience[],
  portfolios: TPortfolio[],
  about: string,
  skills: string[],
  name: string
  surname: string
  email: string
  phone: string
  profileScore: number,
  searchingJob: boolean,
  smsPermission: boolean,
  callPermission: boolean,
  emailPermission: boolean,
  country: string,
  city: string,
  socialMedias: SocialMedias,

  profilePhoto?: TMediaObject,
  allProfilePhotos?: TMediaObject[],

}

export async function deleteMedias(url: string, keys: string[]): Promise<TError | undefined> {
  for (let i = 0; i < keys.length; i++) {
    const resp = await getV2(url + '?fileKey=' + keys[i])
    if (resp.data.result == false) {
      return new TError(-1, resp.data.error ?? 'error')
    }
  }
  return undefined
}

export function getProfessionalData(customKeyId?: string): Promise<ProfessionalProfileData | TError> {
  return getV2('/Professional/getdata' + (customKeyId ? ('?professionalKey=' + customKeyId) : '')).then(resp => {
    if (resp instanceof TError) {
      return resp
    }
    const r = resp.data

    let profilePhotos = []
    if (r.fileList && r.fileList.length > 0)
      profilePhotos = r.fileList.filter((f: any) => f.type == 'ProfilePhoto');
    const profilePhoto = (profilePhotos.length > 0) ? parseToMediaObject(profilePhotos[profilePhotos.length - 1]) : undefined;
    const certificates = r.certificateList ? r.certificateList.map((c: any) => parseToCertificate(c)) : [];
    const experiences = r.experienceList ? r.experienceList.map((c: any) => parseToExperience(c)) : [];
    const portfolios = r.portfolioList ? r.portfolioList.map((c: any) => parseToPortfolio(c)) : [];

    let socialMedias: SocialMedias = {}
    if (r.socialMediaList && r.socialMediaList.length > 0) {
      r.socialMediaList.forEach((s: any) => {
        const l = {
          socialMedia: s.socialMedia,
          socialMediaID: s.socialMediaID,
          link: s.value,
          oldLink: s.value,
        }
        socialMedias = {...socialMedias, [s.socialMedia.toLowerCase()]: l}
      })
    }

    let profile: ProfessionalProfileData = {
      professionalId: 1,
      active: r.loginActive,
      address: r.adress,
      workingTypes: r.workingTypes,
      paymentType: r.workingPriceTypeID == 0 ? undefined : r.workingPriceTypeID,
      professions: r.professions ?? [],
      skills: r.skills ?? [],
      languages: r.languages ?? [],
      experiences: experiences,
      certificates: certificates,
      portfolios: portfolios,
      profilePhoto: profilePhoto,
      allProfilePhotos: profilePhotos ? profilePhotos.map((p: any) => parseToMediaObject(p)) : [],
      profileScore: r.profileScore,
      name: r.name,
      surname: r.surname,
      email: r.email,
      phone: r.phone,
      smsPermission: r.smsPermission,
      callPermission: r.callPermission,
      emailPermission: r.emailPermission,
      searchingJob: r.activeSearchProject == 'Yes',
      country: r.countrySrt,
      city: r.cityStr,
      title: r.remarkTitle??'',
      educations: r.educationList ? r.educationList.map((e: any) => {
        return {
          id: e.key,
          educationType: e.relationType,
          details: [yyyymmToString(e.beginYear, e.beginMonth) + " - " + yyyymmToString(e.endYear, e.endMonth)],
          beginMonth: e.beginMonth,
          beginYear: e.beginYear,
          endMonth: e.endMonth,
          endYear: e.endYear,
          beginDateYM: yyyymmToDate(e.beginYear, e.beginMonth),
          endDateYM: yyyymmToDate(e.endYear, e.endMonth),
          schoolId: e.relationID,
          departmentId: e.relationItemID,
          schoolName: e.relationName,
          departmentName: e.relationItemName,
        }
      }) : [],
      about: r.remark??'',
      socialMedias: socialMedias
    }
    return profile
  })
}


export function parseSocialMediaToRequest(social?: SocialMedia) {
  return {
    socialMediaID: social?.socialMediaID,
    value: social?.link ?? ''
  }
}
