
import {TError} from "../types/TError";
import {getV2} from "../utils/request";
import {TDukkanJob} from "../types/TComponents";
import {useContext} from "react";
import {MainContext} from "../context/MainContext";


export async function getDukkanJobList(getTranslation:any): Promise<TDukkanJob[] | TError> {
  return getV2('/PublishProduct/randomproduct?count=3', true).then(async (resp) => {
    if (resp instanceof TError) {
      return resp
    }
    const r = resp.data
    return parseDukkanJobListResponse(r,getTranslation)
  })
}

export async function parseDukkanJobListResponse(responseData: any[],getTranslation:any): Promise<TDukkanJob[]> {


  let items = responseData.map((item: any): TDukkanJob => {
    return {
      id: item.id,
      key: item.key,
      jobTitle: item.title,
      jobDescription: item.remark,
      relationInfo: '',
      relationType: '',
      image: '',
      price: 0,
    }
  });

  for (let i = 0; i < items.length; i++) {
    let item = items[i];
    await getV2(`/PublishProduct/getdata?key=${item.key}`, true).then(resp => {
      if (resp instanceof TError) {
        return item
      }
      const r = resp.data
      if (r.productTypeList && r.productTypeList.length > 0) {
        item.price = r.productTypeList[0].price
      }
      if (r.fileList && r.fileList.length > 0) {
        item.image = r.fileList[0].fileUrl
      }
      item.relationInfo = r.relationInfo
      item.relationType = (r.relationType == 'Company' ?
        getTranslation('common', 'sirket') :
        (r.relationType == 'Professional' ?
            getTranslation('common', 'uzman') :
            getTranslation('common', 'sirket')
        )) ?? ''
    })
  }

  return items
}
