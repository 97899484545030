export function DocumentVeriIsleyenTaahhutnamesi() {
    return <>
        <div className="documentContainer">
            <h1 className="documentHeader">KİŞİSEL VERİLERİN KORUNMASI TAAHHÜTNAMESİ</h1>

            <p className="documentText">
                İşbu Kişisel Verilerin Korunmasına ilişkin Taahhütname (“<strong>Taahhütname</strong>") 6698 sayılı Kişisel Verilerin Korunması Kanunu (“<strong>Kanun</strong>") kapsamında Maslak Mah. Maslak Meydan Sk. Beybi Giz Plaza A Blok No: 1 İç Kapı No: 65 Sarıyer / İstanbul adresinde mukim POFFT DİJİTAL ÇÖZÜMLER TİCARET ANONİM ŞİRKETİ (kısaca “<strong>Şirket</strong>”) ile […Adres…] adresinde mukim […………] (“<strong>Hizmet Veren</strong>”) arasındaki iş ilişkisi çerçevesinde iletilen kişisel verilerin korunması, işlenmesi, aktarılması, saklanması ve silinmesine ilişkin tarafların hak ve yükümlülüklerini düzenlemek amacıyla akdedilmiş olup, işbu Taahhütname Şirket ve Hizmet Veren arasında akdedilen [.] tarihli [.] Sözleşmesi’nin (“<strong>Sözleşme</strong>”) devamı mahiyetinde olup, işbu taahhütname Sözleşme’nin eki ve ayrılmaz bir parçasıdır.
            </p>
            <p className="documentText">
                Sözleşme’deki tüm diğer hükümler geçerliliğini aynen koruyacak olup, işbu Taahhütname'den etkilenmeyecektir. İşbu Taahhütname Veri İşleyen Hizmet Veren’in taahhütlerini içermektedir.
            </p>

            <h2 className="documentTitle">1. Tanımlar</h2>
            <ul className="documentGrid pt-[16px]">
                <p><strong>Kanun:</strong></p> <p>6698 sayılı Kişisel Verilerin Korunması Kanunu.</p>
                <p><strong>Kişisel Veri:</strong></p> <p>Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi.</p>
                <p><strong>Kişisel Verilerin İşlenmesi:</strong></p> <p>Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, saklanması, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hale getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlem.</p>
                <p><strong>Kurul:</strong></p> <p>Kişisel Verileri Koruma Kurulu.</p>
                <p><strong>Özel Nitelikli Kişisel Veri:</strong></p> <p>Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri.</p>
                <p><strong>Veri İşleyen:</strong></p> <p>Veri sorumlusunun verdiği yetkiye dayanarak veri sorumlusu adına kişisel verileri işleyen gerçek veya tüzel kişi.</p>
            </ul>

            <h2 className="documentTitle">2. Amaç ve Kapsam</h2>
            <p className="documentText">
                Yukarıda belirtilen sözleşme ilişkisi kapsamında, Şirket veri sorumlusu iken, Hizmet Veren, Sözleşme amacı kapsamında işlenen kişisel veriler bakımından Şirket’in Veri İşleyeni konumundadır. Hizmet Veren, Şirket tarafından kendisine aktarılan kişisel verilerin Şirket adına ve Kanun ile Sözleşme hükümlerine uygun olarak işleneceği yönünde talimat ve icazet vermektedir.
            </p>

            <h2 className="documentTitle">3. Hizmet Veren’in Yükümlülükleri</h2>
            <p className="documentText"><strong>3.1.</strong> Kişisel Veriler, işbu Taahhütname kapsamında Şirket ve Hizmet Veren arasında münakit Sözleşme ilişkisi ile sınırlı olarak aşağıdaki koşullarda işlenecektir.</p>
            <p className="documentText"><strong>3.2.</strong> Hizmet Veren, Kanun kapsamında kişisel verilerin ve özel nitelikli kişisel verileri, Sözleşme konusu edim ve işlerin ifası ve Şirket’in faaliyetleri kapsamında tabi olduğu mevzuattan doğan yükümlülüklerinin yerine getirilmesi amacıyla işleyecek olup, bu kapsam dışında hiçbir üçüncü kişi ile paylaşmayacaktır.</p>
            <p className="documentText"><strong>3.3.</strong> Hizmet Veren, Şirket tarafından toplanarak aktarılan kişisel verileri, Şirket adına, onun verdiği talimatlara ve Sözleşme’ye uygun olarak işlemekle yükümlüdür. Herhangi bir sebeple Şirket’in talimatlarına ya da Sözleşme hükümlerine uygunluk sağlayamaması halinde Hizmet Veren, Şirket’i derhal bilgilendirmekle yükümlüdür. Hizmet Veren, bu halde Şirket’in veri aktarımını askıya alma yahut Sözleşme’yi feshetme hakkına sahip olacağını kabul eder.</p>
            <p className="documentText"><strong>3.4.</strong> Hizmet Veren, aktarılan kişisel verilere ilişkin olarak; Sözleşme tarihinde Sözleşme’ye aykırı ulusal düzenleme olup olmadığını araştırmakla ve böyle bir düzenlemenin bulunduğunu fark etmesi ya da Sözleşme’de yer alan taahhütlerini yerine getirmesini etkilemesi muhtemel bir mevzuat değişikliği yapılması hallerinde durumu Şirket’e bildirmekle yükümlüdür. Hizmet Veren, bu halde veri sorumlusunun veri aktarımını askıya alma ve Sözleşme’yi feshetme hakkına sahip olacağını kabul eder.</p>
            <p className="documentText"><strong>3.5.</strong> Veri sahibinin herhangi bir şekilde Hizmet Veren’den ilgili mevzuat kapsamındaki haklarıyla ilgili bir talepte bulunması halinde, Hizmet Veren söz konusu talebe ilişkin olarak derhal (her halükarda ertesi iş günü) Şirket’e yazılı bildirimde bulunacak ve gerekli aksiyonları derhal alacaktır. Yine Hizmet Veren'e gelen taleplere cevap verilmesi ve taleplerin gereğinin yapılması (bilgi verilmesi talebi, silme talebi vb.) için Hizmet Veren tarafından Şirket’e yazılı bildirimde bulunulacak, bu bildirimin gereği Hizmet Veren tarafından en geç beş (5) iş günü içerisinde yerine getirilecektir. Hizmet Veren aktarıma konu kişisel verilerin işlenmesi hususunda Kurul’un karar ve görüşlerine uyacaktır.</p>
            <p className="documentText"><strong>3.6.</strong> Hizmet Veren, taraflar arasındaki sözleşme kapsamında işlenen kişisel verilere herhangi bir şekilde yetkisiz erişim gerçekleşmesi ve/veya adli bir makamdan gelen ve kişisel verilerin söz konusu adli makama açıklanmasını gerektiren yasal olarak bağlayıcı taleplerin bulunması durumlarında, derhal (aynı gün içerisinde) Şirket’e söz konusu durumu bildirecek ve bu durumdan doğabilecek zararların minimize edilmesi ve doğan zararın giderilmesi için Şirket ile işbirliği içerisinde çalışarak talep edilen her türlü bilgi, belge ve desteği gecikmeksizin Şirket’e sağlayacaktır.</p>

            <h2 className="documentTitle">4. Şirket’in Yükümlülükleri</h2>
            <p className="documentText"><strong>4.1.</strong> Şirket tarafından Hizmet Veren’e aktarılacak kişisel verilerin, Hizmet Veren tarafından işlenmesi için ilgili veri sahiplerine yürürlükte bulunan kişisel verilerin korunması mevzuatı kapsamında gerekli bilgilendirmelerin yapılması ve veri sahiplerinden anılan mevzuata uygun içerik ve formatta gerekli izin ve onayların alınması münhasıran Şirket’in sorumluluğunda olacaktır.</p>

            <h2 className="documentTitle">5. Kişisel Verilerin İadesi</h2>
            <p className="documentText">Hizmet Veren, taraflar arasındaki Sözleşme’nin herhangi bir sebeple feshedilmesi veya yürürlük süresinin sona ermesi gibi herhangi bir nedenle sözleşmesel ilişkinin son bulması durumunda, Şirket’in tercihine bağlı olarak, kendisi ve/veya taşeronlarında bulunan aktarıma konu tüm kişisel verileri ve bilgi ve verinin herhangi bir parçasının tüm kopyalarını yedekleri ile birlikte Şirket’e geri göndereceğini ya da kişisel verileri tamamen yok edeceğini, mevzuatta veri işleyenin bu yükümlülüğü yerine getirmesini engelleyen hükümler varsa, aktarıma konu kişisel verilerin gizliliğini güvence altına almak için gerekli her türlü teknik ve idari tedbiri alacağını ve veri işleme faaliyetini durduracağını kabul eder.
            </p>

            <h2 className="documentTitle">6. Mevzuata Aykırılık ve Tazmin Yükümlülüğü</h2>
            <p className="documentText"><strong>6.1.</strong> Hizmet Veren, kişisel verilerin korunmasına ilişkin herhangi bir mevzuatı ihlal etmesi halinde, Şirket’in bundan kaynaklanan zararını tüm tazmin edeceğini ve Şirket nezdinde meydana gelebilecek her türlü bir masraf, adli veya idari para cezaları ile ilgili üçüncü kişiler tarafından Şirket’e yöneltilecek her türlü tazminat talebi ile bu yönde yapılan tüm masrafları karşılamakla yükümlü olduğunu gayrikabili rücu beyan, kabul ve taahhüt eder.</p>
            <p className="documentText"><strong>6.2.</strong> Hizmet Veren, tüm çalışanlarının, temsilcilerinin, ortaklarının ve taşeronlarının işbu Taahhütname’nin bir parçası olarak, kişisel veri işlerken yukarıda belirtilen tüm koşullara ve Kanun ile ikincil mevzuata uyacaklarını temin ve taahhüt eder. Hizmet Veren, kendi süreçlerinin ve çalışanlarının işlemlerinin Kanun ve ilgili ikincil düzenleme hükümlerine uyumlu olacağını ve bu konuda gerekli tüm teknik ve idari önlemleri alacağını kabul, beyan ve taahhüt eder. Hizmet Veren, işbu maddede belirtilen taahhüt ve yükümlülüklerine aykırı davranması, bir başka ifadeyle Hizmet Veren’in çalışanlarının, temsilcilerinin, ortaklarının ve taşeronlarının işbu Taahhütname ile üstlenilen edimlerini ve yükümlülüklerini yerine getirmemesi ve/veya Kanun ile ilgili ikincil düzenleme hükümlerine aykırı davranması halinde, Şirket nezdinde meydana gelebilecek tüm zararlardan işbu Taahhütname’nin 6.1. maddesi gereğince sorumlu olacağını gayrikabili rücu beyan, kabul ve taahhüt eder.</p>

            <h2 className="documentTitle">7. Denetim Yetkisi</h2>
            <p className="documentText">Şirket’in, kişisel verileri koruma mevzuatı ile Hizmet Veren’in işbu Taahhütname ile üstlenmiş olduğu edim ve yükümlülüklerine uyumluluğu konusunda, Hizmet Veren’i her zaman denetleme ve bilgi talep etme hakkı da bulunmaktadır. Şirket dilediğinde işbu denetleme hakkını uygun gördüğü üçüncü kişilere veya yetkilendirilmiş çalışanlarına devredebilir. Bu kapsamda Şirket, Hizmet Veren’in ilgili mevzuat ve Taahhütname hükümlerine uyumluluğu konusunda yönlendirme ve uyarılarda bulunabilecektir. Hizmet Veren, gerek kişisel verilerin korunması mevzuatına gerekse işbu Taahhütname hükümlerine aykırı eylemlerini sürdürmesi halinde, Şirket’in Sözleşme’yi haklı nedenle derhal ve tazminatsız feshetme hakkı olduğunu bildiğini ve Şirket’in bu uğurda meydana gelebilecek tüm zararını tazmin etmekle yükümlü olduğunu beyan, kabul ve taahhüt eder.</p>

            <h2 className="documentTitle">8. Hak ve Yükümlülüklerin Devri</h2>
            <p className="documentText"><strong>8.1.</strong> Hizmet Veren, işbu Taahhütname'den kaynaklanan hak ve yükümlülüklerini üçüncü kişilere devir ve temlik edemez.</p>
            <p className="documentText"><strong>8.2.</strong> Hizmet Veren, işbu Taahhütname kapsamındaki kişisel verilerin işlenmesi kapsamında veri işleyen veya alt yüklenici kullanılması durumunda, Şirket’in ispat edilebilir şekilde bilgilendirilmesi ve yazılı ön onayının alınması gerekmektedir. Bu durumda Taahhütname'de yer alan düzenlemelerinin ilgili veri işleyen sözleşmesine aynen yansıtılması zorunludur. Alt yüklenici kullanılan hallerde dahi Hizmet Veren’in işbu Taahhütname kapsamındaki yükümlülük ve sorumlulukları aynen devam eder.</p>



            <h2 className="documentTitle">9. Uygulanacak Hukuk ve İhtilafların Halli</h2>
            <p className="documentText">İşbu Taahhütname’nin yürürlüğü ve Taahhütname’den kaynaklanan uyuşmazlıkların çözümü Türk hukukuna tabi olacak ve Taahhütname'den kaynaklanan ihtilafların hallinde İstanbul (Çağlayan) Mahkemeleri ve İcra Daireleri yetkili olacaktır.<br />
                İşbu Taahhütname, [.] tarihinde iki (2) asıl nüsha olarak akdolunmuş ve birer ıslak imzalı nüshası taraflara teslim edilmiştir.</p>
        </div >
    </>
}