import { Box, Button, Modal } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "src/context/MainContext";
import { ORegisterCheckboxes } from "src/objects/Register/ORegisterCheckboxes";
import { firstLetterUppercase } from "src/utils/util";
import { postV2 } from "../utils/request";
import { CGeneralAgreements } from "./CAgreements";
import CLoadingAnimation from "./CLoadingAnimation";
import { CSuccess } from "./CSuccess";

const emailRegex = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;

function CContactUs() {
  const mainContext = useContext(MainContext);

  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [eposta, setEposta] = useState("");
  const [requesting, setRequesting] = useState(false);
  const [modal, setModal] = useState(false);

  async function signUp() {
    setRequesting(true);
    postV2("/newsletter/create", {
      ID: 0,
      Active: true,
      Email: eposta,
    }, true).then((resp) => {
      setRequesting(false);
      if (resp.data.result == false) {
        setError(resp.data.message);
      } else {
        setIsSuccess(true);
        setModal(true);
      }
    });
  }

  function checkEmail(email: string) {
    if (email.length == 0) {
      setError("");
      return;
    }

    const valid = emailRegex.test(email);
    if (!valid) {
      setError(
        mainContext?.getTranslation("contact", "gecerli-eposta-giriniz")
      );
      return;
    }
    setError(undefined);
  }

  useEffect(() => {
    checkEmail(eposta);
  }, [eposta]);

  const [kvkkOpen, setKvkkOpen] = useState(false);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "#fff",
    border: "none",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  function showKvkk() {
    setKvkkOpen(true);
  }

  const [checkboxes, setCheckboxes] = useState<ORegisterCheckboxes>({
    acikRiza: false,
    ticariIletiSms: false,
    ticariIletiCall: false,
    ticariIletiEmail: false,
  });

  return (
    <>
      <Modal
        open={kvkkOpen}
        onClose={() => {
          setKvkkOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-32-40-500 text-black">
            {mainContext?.getTranslation("contact", "aydinlatmaTitle")}
          </div>
          <div className="text-16-24-400 text-grey mt-[24px] max-h-[400px] overflow-scroll">
            {mainContext?.getTranslation("contact", "aydinlatmaContent")}
          </div>
          <div className="pt-[24px] w-full flex justify-between">
            <Button
              onClick={() => {
                setKvkkOpen(false);
              }}
              className="purpleButtonMini"
            >
              {mainContext?.getTranslation("common", "kabul")}
            </Button>
            <Button
              onClick={() => {
                setKvkkOpen(false);
              }}
              className="greyButtonMini"
            >
              {mainContext?.getTranslation("common", "kapat")}
            </Button>
          </div>
        </Box>
      </Modal>
      {isSuccess && (
        <CSuccess
          open={modal}
          onClose={() => {
            setModal(false);
          }}
          text="Başarıyla kaydoldunuz"
        />
      )}

      {!isSuccess && (
        <div
          className="bg-white rounded-[16px] p-[16px] lg:p-[56px] flex-col text-cenmter lg:text-start"
          style={{ boxShadow: "0px 20px 80px #EBECF0" }}
        >
          <div className="text-32-40-500 text-black">
            {isSuccess
              ? mainContext?.getTranslation("contact", "tesekkurler")
              : mainContext?.getTranslation("contact", "iletisimde-kalalim")}
          </div>

          {!isSuccess && (
            <>
              <div className="text-16-24-400 text-grey pt-[16px]">
                {mainContext?.getTranslation("contact", "epostani-gonder")}
              </div>
              <div
                className="pt-[24px]"
                style={requesting ? { pointerEvents: "none" } : {}}
              >
                <TextField
                  id="outlined-basic"
                  label={mainContext?.getTranslation("common", "input-eposta")}
                  variant="outlined"
                  className="basicInput w-full"
                  onChange={(e) => {
                    setEposta(e.target.value);
                  }}
                />
                {error && <div className="text-red-400 text-sm">{error}</div>}
              </div>
              <div >
                <CGeneralAgreements checkboxes={checkboxes} setCheckboxes={setCheckboxes} requesting={requesting} />


                {/* <div
                  className={`basicCheckbox ${checkKvkk ? "selected" : ""}`}
                  style={requesting ? { pointerEvents: "none" } : {}}
                >
                  <FormControlLabel
                    style={{ pointerEvents: "none" }}
                    control={
                      <Checkbox
                        checked={checkKvkk}
                        style={{ pointerEvents: "auto" }}
                        onChange={(e) => {
                          setCheckKvkk(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <p
                        style={{ pointerEvents: "auto" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          showKvkk();
                        }}
                      >
                        {mainContext?.getTranslation("contact", "kvkk")}
                      </p>
                    }
                  />
                </div>
                <div
                  className={`basicCheckbox ${checkKisielVeri ? "selected" : ""
                    }`}
                  style={requesting ? { pointerEvents: "none" } : {}}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCheckKisielVeri(e.target.checked);
                        }}
                      />
                    }
                    label={mainContext?.getTranslation(
                      "contact",
                      "ticari-ileti-onayi"
                    )}
                  />
                </div>
                <div
                  className={`basicCheckbox ${checkEposta ? "selected" : ""}`}
                  style={requesting ? { pointerEvents: "none" } : {}}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCheckEposta(e.target.checked);
                        }}
                      />
                    }
                    label={mainContext?.getTranslation(
                      "contact",
                      "eposta-onayi"
                    )}
                  />
                </div> */}




              </div>
              <div className="w-full flex pt-[24px] justify-center lg:justify-start">
                <Button
                  className="purpleButton"
                  disabled={!emailRegex.test(eposta)}
                  onClick={() => {
                    if (requesting) {
                      return;
                    }
                    signUp();
                  }}
                >
                  {requesting ? (
                    <CLoadingAnimation size={24} />
                  ) : (
                    <span>
                      {firstLetterUppercase(
                        mainContext?.getTranslation("common", "kayit-ol") ?? ""
                      )}
                    </span>
                  )}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default CContactUs;
