import VisibilityOff from "@mui/icons-material/VisibilityOffOutlined";
import Visibility from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import AES256 from 'aes-everywhere';
import { enqueueSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login";
import { useNavigate } from "react-router-dom";
import { ORegisterCheckboxes } from "src/objects/Register/ORegisterCheckboxes";
import { PageState } from "src/types/Context";
import { emailRegex, passRegex } from "src/types/Regex";
import { loginWgoogleLink, loginWlinkedinLink, signInRequest, signUpRequest, } from "src/utils/request";
import { MainContext } from "../context/MainContext";
import { TError } from "../types/TError";
import { firstLetterUppercase, isLoggedInPage } from '../utils/util';
import { absoluteCenter } from "../utils/window";
import { CCompanyAgreements, CExpertAgreements } from "./CAgreements";
import { CAgreementTextPopup } from "./CAgreementTextPopup";
import { CForgotPassPopup } from "./CForgotPassPopup";
import CLoadingAnimation from "./CLoadingAnimation";
import { CSignInUpSwitcher } from "./CSignInUpSwitcher";
import { CSignupDetailPopup } from "./CSignupDetailPopup";
import { CSuccessPopup } from "./CSuccessPopup";
import { NotificationType, ShowManualToast } from "./Toast/Toast";

export enum RegisterType {
  None = "",
  Expert = "expert",
  Company = "company",
}

export interface InfoText {
  title: string;
  text: string;
  buttonText: string;
  onAccept?: () => void;
  onClose?: () => void;
}

export function CSignInUp() {
  const mainContext = useContext(MainContext);
  const navigate = useNavigate();
  const pageState = mainContext?.signinupState != PageState.None;

  const [registerType, setRegisterType] = useState(RegisterType.None);
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [forgottenEmail, setForgottenEmail] = useState("");
  const [password, setPassword] = useState("");
  const [requesting, setRequesting] = useState(false);
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [passError, setPassError] = useState<string | undefined>(undefined);
  const [signinError, setSigninError] = useState<string | undefined>(undefined);
  const [forgottenEmailError, setForgottenEmailError] = useState<
    string | undefined
  >(undefined);
  const [resetPassOpen, setResetPassOpen] = useState(false);

  const [infoText, setInfoText] = useState<undefined | InfoText>(undefined);
  const [signupDetail, setSignupDetail] = useState(false);

  const [isRequesting, setIsRequesting] = useState(false);

  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [faceLogin, setFaceLogin] = useState(false);


  const handleClose = () => {
    if (mainContext?.isExpired('signinupclose')) {
      return
    }
    setEmail("");
    setPassword("");
    setRegisterType(RegisterType.None);
    setEmailError(undefined);
    setPassError(undefined);
    setForgottenEmail("");
    setForgottenEmailError(undefined);
    mainContext?.setSigninupState(PageState.None);
    setCheckboxes({ acikRiza: false, ticariIletiSms: false, ticariIletiCall: false, ticariIletiEmail: false });
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const registerContentVisible =
    registerType != RegisterType.None;

  useEffect(() => {
    if (mainContext?.signinupState == PageState.Signin) {
      const email = localStorage.getItem('email')
      if (email) {
        setEmail(email)
        const pass = localStorage.getItem('pass')
        if (pass) {
          const ePass = AES256.decrypt(pass, 'berkPass12345').toString()
          setPassword(ePass)
          setRememberMe(true)
        }
      }
    }
  }, [mainContext?.signinupState]);
  const [enable3rdPartyLogin, setEnable3rdPartyLogin] = useState(false)

  useEffect(() => {
    if (window.location.href.includes('login3rdParty')) {
      setEnable3rdPartyLogin(true)
    }
    if (mainContext?.userData != undefined && mainContext.signinupState == PageState.Signin) {
      if (mainContext?.isExpired('signinup')) {
        return
      }
      mainContext.setSigninupState(PageState.None)
      // if (mainContext.isExpiredLogin == true) {
      mainContext.setIsExpiredLogin(false)
      // } else {
      //   navigate('/dashboard')
      // }
    }
  }, [mainContext?.userData]);

  async function signIn() {
    if (rememberMe) {
      localStorage.setItem('email', email)
      const ePass = AES256.encrypt(password, 'berkPass12345')
      localStorage.setItem('pass', ePass)
    } else {
      localStorage.removeItem('email')
      localStorage.removeItem('pass')
    }
    setSigninError(undefined)
    setRequesting(true);
    const response = await signInRequest(email, password);
    setRequesting(false);
    if (response instanceof TError) {
      setSigninError(response.errorMessage)
      mainContext?.showToast(NotificationType.Fail, 'signIn')
    } else {
      mainContext?.setUserData(response)
      if (window.location.href.endsWith('3000') || window.location.href.endsWith('/') || window.location.href.endsWith('.com')) {
        navigate('/dashboard')
      }
    }
  }

  async function signUp(
    firstname: string,
    lastname: string,
    pass: string,
    // smsPermission: boolean,
    // callPermission: boolean,
    // emailPermission: boolean,
    companyName?: string
  ) {
    debugger
    const isSuccess = await signUpRequest(
      email,
      firstname,
      lastname,
      pass,
      // smsPermission,
      // callPermission,
      // emailPermission,
      checkboxes.ticariIletiSms,
      checkboxes.ticariIletiCall,
      checkboxes.ticariIletiEmail,
      companyName,
      mainContext?.extra?.registerKey ?? ''
    );
    if (isSuccess instanceof TError) {
      const error = isSuccess as TError
      if (error.errorMessage.length > 0) {
        ShowManualToast(enqueueSnackbar, NotificationType.Fail, error.errorMessage)
      } else {
        mainContext?.showToast(NotificationType.Fail, '')
      }
    } else {
      setRegisterSuccess(true);
    }
  }

  const responseMessage = (response: any) => {
    console.log(response);
  };
  const errorMessage = () => {
    console.log("google hatasi");
  };

  useEffect(() => {
    if (email.length == 0) {
      setEmailError(undefined);
    } else {
      emailRegex.test(email)
        ? setEmailError(undefined)
        : setEmailError(mainContext!.getTranslation("giris-kayit", "gecersiz-email"));
    }
  }, [email]);

  useEffect(() => {
    if (password.length == 0) {
      setPassError(undefined);
    } else {
      if (password.length < 7) {
        setPassError(mainContext!.getTranslation('giris-kayit', 'enaz-8karakter'));
      } else {
        passRegex.test(password)
          ? setPassError(undefined)
          : setPassError(
            mainContext!.getTranslation('giris-kayit', 'sifre-ozel-karakter')
          );
      }
    }
  }, [password]);

  function continueEnabled() {
    if (mainContext?.signinupState == PageState.Signup) {
      return (
        email.length > 0 && emailError == undefined
      );
    } else {
      return (
        email.length > 0 &&
        emailError == undefined &&
        password.length > 0 &&
        passError == undefined
      );
    }
  }

  // const rizaMetni: InfoText = {
  //   title: mainContext!.getTranslation('giris-kayit', 'acik-riza'),
  //   text: mainContext!.getTranslation('giris-kayit', 'acik-riza-metni'),
  //   buttonText: mainContext!.getTranslation('giris-kayit', 'okudum-onayliyorum'),
  //   onAccept: () => {
  //     setAcikrizaCheck(true);
  //     setInfoText(undefined);
  //   },
  //   onClose: () => {
  //     console.log("onClose");
  //     setAcikrizaCheck((acikrizaCheck) => !acikrizaCheck);
  //     setInfoText(undefined);
  //   },
  // };

  const responseFacebook = (response: any) => {
    console.log(response);
    setFaceLogin(false);
    setIsRequesting(false);
  };


  const isExpert = (): boolean => {
    return registerType == RegisterType.Expert
  }

  const isCompany = (): boolean => {
    return registerType == RegisterType.Company
  }

  const [checkboxes, setCheckboxes] = useState<ORegisterCheckboxes>({
    acikRiza: false,
    ticariIletiSms: false,
    ticariIletiCall: false,
    ticariIletiEmail: false,
  });

  return (
    <>
      <Modal
        open={pageState && !signupDetail}
        onBackdropClick={(e: any) => {
          if (mainContext?.isExpired('signbackdrop') ?? false) {
            mainContext?.setUserData(undefined)
            mainContext?.setSigninupState(PageState.None)
            handleClose()
            if (isLoggedInPage()) {
              navigate('/')
            }
            // e.preventDefault()
            // e.stopPropagation()
            // return
          }
        }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={absoluteCenter}>
          <div
            className="flex-col max-h-[90vh] overflow-y-hidden w-[360px] lg:w-[420px] py-[24px] bg-white rounded-[8px] justify-center items-center">
            <div className="text-32-40-500 text-black px-[24px] pb-[32px] text-center">
              {mainContext?.signinupState == PageState.Signup
                ? mainContext!.getTranslation('common', 'poffta-katil')
                :
                (mainContext?.userData != undefined && mainContext?.isExpired('signinModal')) ?
                  mainContext!.getTranslation('common', 'devam-etmek-icin-giris-yap') :
                  mainContext!.getTranslation('common', 'hos-geldin')
              }
            </div>
            <div className={'y-scroll px-[24px] mx-[8px]'}>
              {mainContext?.signinupState == PageState.Signup && (
                <div className="flex-col w-full">
                  <RadioGroup
                    row
                    className="flex justify-center w-full gap-[16px]"
                    onChange={(e) =>
                      setRegisterType(e.target.value as unknown as RegisterType)
                    }
                    value={registerType}
                  >
                    <div
                      className={`radioButton ${isExpert() ? "selected" : ""
                        }`}
                    >
                      <FormControlLabel
                        value={RegisterType.Expert}
                        control={<Radio className="defaultRadio" size="small" />}
                        label={mainContext!.getTranslation('common', 'uzman')}
                      />
                    </div>
                    <div
                      className={`radioButton ${isCompany() ? "selected" : ""
                        }`}
                    >
                      <FormControlLabel
                        value={RegisterType.Company}
                        control={<Radio className="defaultRadio" size="small" />}
                        label={mainContext!.getTranslation('common', 'sirket')}
                      />
                    </div>
                  </RadioGroup>

                </div>
              )}
              {(mainContext?.signinupState != PageState.Signup ||
                registerContentVisible) && (
                  <div className="pt-[16px] w-full">

                    {isCompany() && <>
                      <div className="pb-[24px] signContinueWith">
                        <div
                          style={{ color: "#0000008A" }}
                          onClick={() => {
                            debugger
                            setIsRequesting(true);
                            loginWgoogleLink(() => {
                              setIsRequesting(false);
                            }, mainContext?.extra?.registerKey);
                          }}
                        >
                          <img src="/images/signin-google.svg" />
                          <div>{mainContext!.getTranslation('giris-kayit', 'google-ile')}</div>
                        </div>
                      </div>
                      <div className="pb-[24px] signContinueWith">
                        <div
                          style={{
                            backgroundColor: "#0077B5",
                            borderColor: "#0077B5",
                          }}
                          onClick={() => {
                            debugger
                            setIsRequesting(true);
                            loginWlinkedinLink(() => {
                              setIsRequesting(false);
                            }, mainContext?.extra?.registerKey);
                          }}
                        >
                          <img src="/images/signin-linkedin.svg" />
                          <div>{mainContext!.getTranslation('giris-kayit', 'linkedin-ile')}</div>
                        </div>
                      </div>
                      {enable3rdPartyLogin &&
                        <>
                          {faceLogin && (
                            <FacebookLogin
                              appId="497653915591046"
                              version="15.0"
                              xfbml
                              cookie
                              callback={responseFacebook}
                              autoLoad={true}
                            // render={(renderProps: any) => <></>}
                            />
                          )}
                          <div className="pb-[32px] signContinueWith">
                            <div
                              style={{
                                backgroundColor: "#1877F2",
                                borderColor: "#1877F2",
                              }}
                              onClick={() => {
                                setIsRequesting(true);
                                setFaceLogin(true);
                                setTimeout(() => {
                                  try {
                                    document
                                      .getElementsByClassName("kep-login-facebook")[0]
                                      //@ts-ignore
                                      .click();
                                  } catch (ex) {
                                  }
                                }, 200);
                              }}
                            >
                              <img src="/images/signin-facebook.svg" />
                              <div>{mainContext!.getTranslation('giris-kayit', 'facebook-ile')}</div>
                            </div>
                          </div>
                        </>}
                      <div className="flex gap-[4px] w-full items-center pb-[32px]">
                        <div className="full-dividerH h-[2px]" />
                        <div
                          className="text-14-24-500 text-light-grey">{mainContext!.getTranslation('common', 'veya')}</div>
                        {/*<div*/}
                        {/*  className="text-14-24-500 text-light-grey whitespace-nowrap">&nbsp;{mainContext!.getTranslation('common', 'giris-yap')}&nbsp;</div>*/}
                        <div className="full-dividerH h-[2px]" />
                      </div>
                    </>
                    }


                    <div
                      className="w-[100%] pb-[24px]"
                      style={requesting ? { pointerEvents: "none" } : {}}
                    >
                      <TextField
                        id="email"
                        label={mainContext!.getTranslation('common', 'input-eposta')}
                        variant="outlined"
                        className="basicInput !w-full"
                        autoComplete={'false'}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      {emailError && (
                        <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                          {emailError}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              {mainContext?.signinupState == PageState.Signin && (
                <>
                  <div
                    className="w-[100%] pb-[24px]"
                    style={requesting ? { pointerEvents: "none" } : {}}
                  >
                    <TextField
                      id="password"
                      autoComplete={"new-password"}
                      label={mainContext!.getTranslation('common', 'input-sifre')}
                      variant="outlined"
                      className="basicInput !w-full"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {passError && (
                      <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                        {passError}
                      </div>
                    )}
                  </div>
                  <div className="w-full flex items-center justify-between pb-[16px]">
                    <div
                      className={`basicCheckbox !pb-[0px] ${rememberMe ? "selected" : ""
                        }`}
                      style={requesting ? { pointerEvents: "none" } : {}}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={rememberMe}
                            onChange={(e) => {
                              setRememberMe(e.target.checked);
                            }}
                          />
                        }
                        label={mainContext!.getTranslation('common', 'beni-hatirla')}
                      />
                    </div>
                    <div
                      className="text-14-24-600 text-purple"
                      onClick={() => {
                        if (requesting) {
                          return;
                        }
                        setResetPassOpen(true);
                      }}
                    >
                      {mainContext!.getTranslation('common', 'sifremi-unuttum')}
                    </div>
                  </div>
                </>
              )}

              <div style={isExpert() && mainContext?.signinupState == PageState.Signup ? {} : { display: 'none' }}>
                <CExpertAgreements checkboxes={checkboxes} setCheckboxes={setCheckboxes} requesting={requesting} />
              </div>

              <div style={isCompany() && mainContext?.signinupState == PageState.Signup ? {} : { display: 'none' }}>
                <CCompanyAgreements checkboxes={checkboxes} setCheckboxes={setCheckboxes} requesting={requesting} />
              </div>

              {(mainContext?.signinupState != PageState.Signup ||
                registerContentVisible) && (
                  <div className={'flex-col'}>
                    {signinError && (
                      <div className="text-red-400 text-12-14-500 py-[8px] pl-[16px]">
                        {signinError}
                      </div>
                    )}
                    <Button
                      className="purpleButton w-full pb-[32px]"
                      disabled={!continueEnabled()}
                      onClick={() => {
                        if (requesting) {
                          return;
                        }
                        if (mainContext?.signinupState == PageState.Signup) {
                          setSignupDetail(true);
                        } else {
                          signIn();
                        }
                      }}
                    >
                      {requesting ? (
                        <CLoadingAnimation size={24} />
                      ) : (
                        <span>{mainContext!.getTranslation('common', 'devam')}</span>
                      )}
                    </Button>

                    <div className="">
                      <div className="full-dividerH h-[2px]" />
                    </div>
                  </div>
                )}
              {!mainContext?.isExpired('signinup2') &&
                <div className="pt-[16px]">
                  <CSignInUpSwitcher />
                </div>
              }
            </div>
          </div>
        </Box>
      </Modal>

      <Modal open={isRequesting}>
        <CLoadingAnimation fullscreen />
      </Modal>

      <CSignupDetailPopup
        isOpen={signupDetail && mainContext?.signinupState == PageState.Signup}
        onClose={() => {
          setSignupDetail(false);
          handleClose();
        }}
        onDecline={() => {
          setSignupDetail(false);
          mainContext?.setSigninupState(PageState.Signin);
        }}
        onBack={() => {
          setSignupDetail(false);
        }}
        signUp={signUp}
        registerType={registerType}
        changeRegisterType={setRegisterType}
      />

      <CForgotPassPopup
        isOpen={resetPassOpen}
        forgottenEmail={email}
        forgottenEmailError={emailError}
        setForgottenEmail={setEmail}
        setForgottenEmailError={setEmailError}
        onClose={() => setResetPassOpen(false)}
      />

      <CAgreementTextPopup
        isOpen={infoText != undefined}
        onClose={() => {
          setResetPassOpen(false);
          infoText?.onClose?.();
        }}
        onAccept={() => {
          setResetPassOpen(false);
          infoText?.onAccept?.();
        }}
        infoTitle={infoText?.title}
        infoText={infoText?.text}
        buttonText={infoText?.buttonText}
      />



      {registerSuccess && (
        <CSuccessPopup
          text={mainContext!.getTranslation('giris-kayit', 'kayit-basarili')}
          descText={mainContext!.getTranslation('giris-kayit', 'kayit-maili')}
          buttonText={firstLetterUppercase(mainContext!.getTranslation('common', 'kapat'))}
          onClose={() => {
            setRegisterSuccess(false);
            setSignupDetail(false);
          }}
        />
      )}
    </>
  );
}