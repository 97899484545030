import { useNavigate } from "react-router-dom";
import MenuArrow from "../assets/menu_arrow";

interface PPurpleTextArrow {
  text: string;
  link?: string;
  onClick?: () => void;
  isChosen?: boolean;
}

function CPurpleTextArrow({ text, link, onClick, isChosen }: PPurpleTextArrow) {
  const navigate = useNavigate();

  return (
    <div
      className={`text-arrow-div ${isChosen ? 'text-arrow-div-chosen' : ''}`}
      onClick={() => {
        if (link) {
          navigate(link);
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      <div className="text-16-24-600">{text}</div>
      <div className="ml-[8px] w-[16px] h-[16px]">
        <MenuArrow />
      </div>
    </div>
  );
}

export default CPurpleTextArrow;
