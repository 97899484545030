import {
  CompanyProfileData,
  parseCompanyJobListResponse,
  parseContactPerson,
  SocialMedias,
  TCompanyJobList
} from "../types/TCompanyData";
import {TError} from "../types/TError";
import {getV2} from "../utils/request";
import {EnumProfileUpdateType} from "../types/EnumProfileUpdateType";
import {parseMedioObjectToRequest, parseToMediaObject} from "../types/TMediaObject";
import {parsePhone} from "../utils/util";
import {initialSocialmedias, parseSocialMediaToRequest} from "./profileRequest";
import {parseCompanyJobOffersResponse, TProjectOffer} from "../types/TProjectOffer";

export function getMyCompanyData(): Promise<CompanyProfileData | TError> {
  return getV2('/Company/getdata').then(resp => {
    if (resp instanceof TError) {
      return resp
    }
    const r = resp.data

    let profilePhotos = []
    if (r.fileList && r.fileList.length > 0)
      profilePhotos = r.fileList.filter((f: any) => f.type == EnumProfileUpdateType.Logo);
    const profilePhoto = (profilePhotos.length > 0) ? parseToMediaObject(profilePhotos[profilePhotos.length - 1]) : undefined;
    let pagePhotos = []
    if (r.fileList && r.fileList.length > 0)
      pagePhotos = r.fileList.filter((f: any) => f.type == EnumProfileUpdateType.PageImage);
    const pagePhoto = (pagePhotos.length > 0) ? parseToMediaObject(pagePhotos[pagePhotos.length - 1]) : undefined;


    let socialMedias: SocialMedias = initialSocialmedias
    if (r.socialMediaList && r.socialMediaList.length > 0) {
      r.socialMediaList.forEach((s: any) => {
        const l = {
          socialMedia: s.socialMedia,
          socialMediaID: s.socialMediaID,
          link: s.value,
          oldLink: s.value,
        }
        socialMedias = {...socialMedias, [s.socialMedia.toLowerCase()]: l}
      })
    }

    let profile: CompanyProfileData = {
      name: r.name,
      address: r.address,
      phone: r.phone?parsePhone(r.phone):'-',
      date: r.transactionDate,
      contactPerson: parseContactPerson(r),
      profilePhoto: profilePhoto,
      pagePhoto: pagePhoto,
      allProfilePhotos: profilePhotos ? profilePhotos.map((p: any) => parseToMediaObject(p)) : [],
      smsPermission: r.smsPermission,
      callPermission: r.callPermission,
      emailPermission: r.emailPermission,
      countryID: r.countryID,
      cityID: r.cityID,
      about: r.remark,
      bannerText: r.remarkTitle,
      socialMedias: socialMedias
    }
    return profile
  })
}

export function parseCompanyDataRequest(company: CompanyProfileData) {
  const fileList = []

  if (company.profilePhoto) {
    fileList.push(parseMedioObjectToRequest(company.profilePhoto, 'Logo'))
  }
  if (company.pagePhoto) {
    fileList.push(parseMedioObjectToRequest(company.pagePhoto, 'PageImage'))
  }

  return {
    name: company.name,
    address: company.address,
    countryID: company.countryID,
    phone: company.phone,
    cityID: company.cityID,
    remark: company.about,
    remarkTitle: company.bannerText,
    smsPermission: company.smsPermission,
    callPermission: company.callPermission,
    emailPermission: company.emailPermission,
    contactPersonName: company.contactPerson.name,
    contactPersonSurname: company.contactPerson.surname,
    contactPersonEmail: company.contactPerson.email,
    contactPersonPhone: company.contactPerson.phone,
    socialMediaList: [
      parseSocialMediaToRequest(company.socialMedias.instagram),
      parseSocialMediaToRequest(company.socialMedias.facebook),
      parseSocialMediaToRequest(company.socialMedias.linkedin),
      parseSocialMediaToRequest(company.socialMedias.twitter),
      parseSocialMediaToRequest(company.socialMedias.behance),
      parseSocialMediaToRequest(company.socialMedias.dribble)
    ],
    fileList: fileList
  }
}


export function getCompanyJobList(): Promise<TCompanyJobList | TError> {
  return getV2('/Project/listdata').then(resp => {
    if (resp instanceof TError) {
      return resp
    }
    const r = resp.data
    return parseCompanyJobListResponse(r)
  })
}
export function getCompanyJobOffers(projectKey:string): Promise<TProjectOffer[] | TError> {
  return getV2('/ProjectOffer/listdata?projectKeyID='+projectKey).then(resp => {
    if (resp instanceof TError) {
      return resp
    }
    const r = resp.data
    return parseCompanyJobOffersResponse(r)
  })
}
