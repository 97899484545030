
interface IProps {
    noHorizontalSpacing?: boolean;
}
export function DocumentUzmanAydinlatmaMetni({ noHorizontalSpacing }: IProps) {
    return <>
        <div className={`documentContainer ${noHorizontalSpacing ? "!px-0 !mx-0" : ""}`}>
            <h1 className="documentHeader">KİŞİSEL VERİLERİN KORUNMASI HAKKINDA AYDINLATMA METNİ</h1>
            <p className="documentText">
                POFFT DİJİTAL ÇÖZÜMLER TİCARET ANONİM ŞİRKETİ (“Şirketimiz”), 6698 sayılı Kişisel
                Verilerin Korunması Kanunu’nda (“Kanun”) ve diğer uygulanabilir mevzuatta yer alan
                düzenlemelere uygun olarak kişisel veri işleme faaliyetlerini yürütmektedir.
            </p>

            <h2 className="documentTitle">VERİ SORUMLUSU</h2>
            <p className="documentText">
                Kanun’un uygulanması bakımından MASLAK MAH. MASLAK MEYDAN SK. BEYBI GIZ PLAZA A
                BLOK NO: 1 İÇ KAPI NO: 65 SARIYER / İSTANBUL adresinde mukim POFFT DİJİTAL
                ÇÖZÜMLER TİCARET ANONİM ŞİRKETİ unvanlı Şirketimiz veri sorumlusudur.
            </p>

            <h2 className="documentTitle">KİŞİSEL VERİLERİNİZİN İŞLENME AMACI</h2>
            <p className="documentText">
                Kanun ve Kanun’a bağlı ikincil düzenlemelere uygun olarak; Ad, Soyad, E-posta, Şifre, Telefon ve
                Adres şeklindeki kişisel verileriniz; üyeliğinizin oluşturulması, Şirketimiz ve web sitemiz
                aracılığıyla hizmet verebilmeniz, sizi doğru hizmet alan profilleriyle eşleştirebilmemiz ve iştigal
                konumuza ilişkin tüm gerekli hizmet ve işlemleri ifa edebilmemiz amaçlarıyla açık rızanız
                aranmaksızın işlenmektedir.
            </p>
            <p className="documentText">
                Ayrıca; Ad, Soyad, E-posta, Telefon ve Adres bilgileriniz; yeteneğinize uygun ilanlardan sizleri
                haberdar etmek ve genel olarak satış, pazarlama ve kampanya süreçlerimizi yerine getirmek
                amaçlarıyla açık rızanıza istinaden işlenebilecektir.
            </p>

            <h2 className="documentTitle">KİŞİSEL VERİLERİN AKTARILMASI</h2>
            <p className="documentText">
                Şirketimiz; Ad-Soyad bilgilerinizi, sizi uygun profillerle eşleştirebilmek amacıyla sözleşmenin
                kurulması veya ifası için gerekli olma hukuki nedenine dayalı olarak web sitemiz üzerinden hizmet
                almak isteyen kişi ve şirketlerle paylaşacaktır.
            </p>

            <h2 className="documentTitle">KİŞİSEL VERİLERİN TOPLANMASININ YÖNTEMİ VE HUKUKİ SEBEBİ</h2>
            <p className="documentText">
                Ad, Soyad, E-posta, Şifre, Telefon ve Adres verilerinizi, sözleşmenin kurulması veya ifası için
                gerekli olma hukuki sebebine dayalı olarak elektronik ortamda toplanmaktadır.
            </p>

            <h2 className="documentTitle">KİŞİSEL VERİ SAHİBİNİN HAKLARI</h2>
            <p className="documentText">
                Kişisel verisi işlenen gerçek kişilerin sahip olduğu haklar Kanun’un 11. Maddesinde sayılmıştır.
                Kişisel veri sahibi olarak ilgili Kanun maddesinde sayılan haklarınıza ilişkin taleplerinizi, Veri
                Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’de öngörülen başvuru usullerine uygun
                olarak; Veri sahibi olarak yukarıda sayılan haklarınıza ilişkin taleplerinizi, Veri Sorumlusuna
                Başvuru Usul ve Esasları Hakkında Tebliğ’de öngörülen başvuru usullerine uygun olarak;
                MASLAK MAH. MASLAK MEYDAN SK. BEYBI GIZ PLAZA A BLOK NO: 1 İÇ KAPI NO: 65
                SARIYER / İSTANBUL adresine kimlik teyidinizin yapılması sağlanarak bizzat veya Noter
                kanalıyla resmi usulde iletebilirsiniz. İşlemin ayrıca bir maliyeti gerektirmesi halinde, Kişisel
                Verileri Koruma Kurulu’nca belirlenen tarifedeki ücreti talep edebilecektir.
            </p>
        </div>
    </>
}