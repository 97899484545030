import { DocumentSorumsuzlukBeyani } from "src/components/Documents/document_sorumsuzluk_beyani";
import { isDesktop } from "src/utils/window";

export default function SorumsuzlukBeyani() {

    const IsDesktop = isDesktop();

    return (
        <div className={`min-h-[100vh] w-[100vw] flex gap-[32px] pt-[112px] lg:pt-[132px] ${(isDesktop()) ? 'pl-[20px]' : ''} md:pr-[24px]`}>

            <div className="flex flex-col flex-1 relative">

                <div className="h-[100%] w-[75%] self-center">
                    {(() => {
                        return <DocumentSorumsuzlukBeyani />;
                    })()}
                </div>
            </div>
        </div>
    );

}
