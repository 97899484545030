import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { ProfilTranslation, ProjeTranslation } from "src/objects/TranslationModel";
import CInspectOffersButton from "../components/CInspectOffersButton";
import CLoadingAnimation from "../components/CLoadingAnimation";
import { ModalButton } from "../components/Input/ModalButton";
import CProjectOfferByCompanyModal from "../components/Profile/CProjectOfferByCompanyModal";
import { CProjectOfferListByCompanyModal } from "../components/Profile/CProjectOfferListByCompanyModal";
import styles from '../components/Profile/Profile.module.scss';
import { NotificationType } from "../components/Toast/Toast";
import { MainContext } from "../context/MainContext";
import { useTranslation } from "../hooks/TranslationHook";
import {
  getCities,
  getCountries,
  getCurrencies,
  getLanguages,
  getProfessions,
  getSkills,
  getWorkingPriceTypes,
  getWorkingTypes
} from "../service/commonRequest";
import { getCompanyJobOffers, getMyCompanyData } from "../service/companyRequest";
import { GetProjectDetail } from "../service/projectRequest";
import { PageState } from "../types/Context";
import { CompanyProfileData } from "../types/TCompanyData";
import { TError } from "../types/TError";
import { JobStatus, ProjectOfferUpdate } from "../types/TJob";
import { ParseProjectToRequest, TProject } from "../types/TProject";
import { TProjectOffer } from "../types/TProjectOffer";
import { postV2 } from "../utils/request";
import { compareEqualityIn } from "../utils/util";

export function CompanyProject() {
  const params = useParams()
  const mainContext = useContext(MainContext);
  const getTranslation = useTranslation(ProjeTranslation);
  const getProfileTranslation = useTranslation(ProfilTranslation);
  const [profileData, setProfileData] = useState<CompanyProfileData | undefined>(undefined)
  const [project, setProject] = useState<TProject | undefined>(undefined)
  const [openSubPage, setOpenSubPage] = useState(false)
  const [offerDetail, setOfferDetail] = useState<undefined | TProjectOffer>(undefined)
  const [offersData, setOffersData] = useState<undefined | TProjectOffer[]>(undefined)
  const [projectState, setProjectState] = useState<JobStatus | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [requesting, setRequesting] = useState(false)

  const createdText = project ?
    (project.contactPerson.isDefined ?
      getTranslation.getTranslation('tarihinde-tarafindan-olusturuldu').replace('{val2}', `${project.contactPerson.name} ${project.contactPerson.surname}`)
        .replace('{val1}', project.createDate ?? '') :
      getTranslation.getTranslation('tarihinde-olusturuldu').replace('{val}', project.createDate ?? ''))
    : ''

  async function fetchCommonData(ProjectKey: string) {
    await getProfessions(mainContext)
    await getLanguages(mainContext)
    await getWorkingTypes(mainContext)
    await getWorkingPriceTypes(mainContext)
    await getSkills(mainContext)
    await getCountries(mainContext)
    await getCities(mainContext)
    await getCurrencies(mainContext)
    fetchProfileData(ProjectKey)
  }

  useEffect(() => {
    if (offersData != undefined && offersData.length > 0) {
      const offerParams = window.location.href.split('#offer=')
      if (offerParams.length == 2) {
        const foundOffer = offersData.find(o => o.offerKeyId == offerParams[1])
        if (foundOffer != undefined) {
          setOfferDetail(foundOffer)
        }
      }
      if (project != undefined && openSubPage) {
        setShowOffers(true)
      }
    }
  }, [offersData]);

  async function fetchProfileData(ProjectKey: string) {
    setProfileData(undefined)
    setIsLoading(true)
    await getMyCompanyData().then(r => {
      if (r instanceof TError) {
        setIsLoading(false)
        window.location.reload()
      } else {
        setProfileData(r)
        fetchProjectData(ProjectKey)
      }
    })
  }

  async function fetchProjectData(ProjectKey: string) {
    setIsLoading(true)
    setProject(undefined)
    GetProjectDetail(ProjectKey).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'getProject')
      } else {
        setProject(r)
      }
    })
  }

  useEffect(() => {
    if (project != undefined) {
      fetchOffers()
    }
  }, [project]);

  function fetchOffers() {
    getCompanyJobOffers(project!.projectKeyID).then((response) => {
      setIsLoading(false)
      if (response instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'getOffers')
      } else {
        setOffersData(response)
        let jobStatus = JobStatus.Waiting;
        response.forEach(o => {
          switch (o.status as JobStatus) {
            case JobStatus.Refuse:
              if (!compareEqualityIn(jobStatus, [JobStatus.WaitingForPayment, JobStatus.Confirmed, JobStatus.ConfirmedCompleted])) {
                jobStatus = JobStatus.Refuse
              }
              break;
            case JobStatus.Confirmed:
              if (!compareEqualityIn(jobStatus, [JobStatus.ConfirmedCompleted])) {
                jobStatus = JobStatus.Confirmed
              }
              break;
            case JobStatus.WaitingForPayment:
              if (!compareEqualityIn(jobStatus, [JobStatus.ConfirmedCompleted, JobStatus.Confirmed])) {
                jobStatus = JobStatus.WaitingForPayment;
              }
              break;
            case JobStatus.ConfirmedCompleted:
              jobStatus = JobStatus.ConfirmedCompleted;
              break;
          }
        })
        setProjectState(jobStatus)
      }
    })
  }

  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 150);

    let ProjectKey = params.id;
    if (params.id2) {
      ProjectKey = ProjectKey + '/' + params.id2
    }
    if (params.id3) {
      ProjectKey = ProjectKey + '/' + params.id3
    }
    if (params.id4) {
      ProjectKey = ProjectKey + '/' + params.id4
    }
    if (params.id5) {
      ProjectKey = ProjectKey + '/' + params.id5
    }
    if (ProjectKey == undefined) {
      navigate('/404')
      return
    }
    if (window.location.href.includes('#offer')) {
      setOpenSubPage(true)
    }
    if (mainContext?.userData?.token) {
      const isExpired = mainContext.isExpired('companyproject')
      //berkExpire
      if (!isExpired) {
        fetchCommonData(ProjectKey)
      } else {
        setTimeout(() => {
          mainContext.setSigninupState(PageState.Signin)
        }, 1500)
      }
    }
  }, [mainContext?.userData?.token]);

  function getCard(title: string, desc: any, customClass?: string) {
    return <div
      className={`flex-1 pl-[24px] pr-[16px] pt-[16px] pb-[14px] rounded-[16px] div-lightest-purple flex-col gap-[12px] ${customClass}`}>
      <p className={'text-20-24-700'}>{title}</p>
      <p className={'text-15-24-500'}>{desc}</p>
    </div>
  }

  const [showOffers, setShowOffers] = useState(false)

  function disableProject() {
    setRequesting(true)
    const data: any | TError = ParseProjectToRequest(project!, profileData)
    if (data instanceof TError) {
      mainContext?.showToast(NotificationType.Fail, 'deactivateProject')
      return
    }
    postV2('/Project/update', data).then(resp => {
      setRequesting(false);
      if (resp instanceof TError || resp.data == undefined || resp.data.result == false) {
        mainContext?.showToast(NotificationType.Fail, 'deactivateProject')
      } else if (resp.data.result == true) {
        mainContext?.showToast(NotificationType.Success, 'deactivateProject')
        navigate(-1)
      }
    });

  }

  return <>
    {requesting &&
      <div className={`h-[100vh] w-[100vw] fixed z-[9999] bg-[#33333388]`}><CLoadingAnimation fullscreen={true} />
      </div>}
    {project && project.offerCount > 0 &&
      <div className={'fixed z-[9999] bottom-16 right-16'}>
        <CInspectOffersButton mini={true} project={project} getTranslation={getTranslation} navigate={navigate}
          setShowOffers={setShowOffers} />
      </div>}
    <div
      className=" main-padding m-auto pt-[96px] pb-[48px] lg:pb-[72px] items-center h-full flex-col gap-[30px]">
      {isLoading ? <div className={`h-[100vh]`}><CLoadingAnimation /></div> : <>
        <div
          className={`flex flex-col gap-[24px] w-full h-[188px] bg-gray-300 px-[30px] pt-[72px] rounded-[16px] overflow-hidden whiteShadowCard relative`}>
          <div className={'absolute top-0 left-0 bottom-0 right-0 flex'}>
            <img className={'w-full object-cover'} src={profileData?.pagePhoto?.url} />
          </div>
          <p className={'z-10 text-white text-24-24-900'}>{profileData?.name}</p>
          <p className={'z-10 text-white text-24-24-500'}>{profileData?.bannerText}</p>
        </div>
        <div className={`${styles.content} max-w-[1080px] whiteShadowCard flex-col w-full py-[30px] px-[32px]`}>
          <p
            className={'text-18-24-600 text-purple'}>{getTranslation.getTranslation(project?.isActive ? 'aktif-ilan' : 'inaktif-ilan')}</p>
          <div className={'pt-[18px] flex gap-[24px]'}>
            <div className={'flex-1 px-[24px] py-[16px] rounded-[16px] div-lightest-purple flex-col min-w-[500px]'}>
              <div className={'flex justify-between gap-[24px]'}>
                <div className={'flex-col flex-1'}>
                  <p className={'text-18-24-700'}>{project?.title}</p>
                  <p className={'text-12-24-400'}>{createdText}</p>
                </div>
                <div className={'flex gap-[8px] items-center'}>
                  <img className={'w-[24px] h-[24px]'} src={'/images/Location.svg'} />
                  <div className={'flex-col justify-center'}>
                    <p className={'text-location'}>{project?.country?.label}</p>
                    <p className={'text-location'}>{project?.city?.label}</p>
                  </div>
                </div>
              </div>
              <div className={'flex-col gap-[4px] pt-[12px]'}>
                <h3>{mainContext?.getTranslation('common', 'aciklama')}</h3>
                {project &&
                  <h2 style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: project!.description }} />
                }
              </div>
            </div>
            <div className={'grid grid-cols-2 gap-x-[24px] gap-y-[12px] h-0'}>
              {getCard(getProfileTranslation.getTranslation('uzmanlik') ?? '',
                (project?.professionsMap && project?.professionsMap.length > 0) ? project.professionsMap[0].label : '-'
              )}
              {getCard(getTranslation.getTranslation('ucret-sekli') ?? '',
                project?.workingPriceType?.label ?? '')
              }
              {getCard(getTranslation.getTranslation('kullanilan-diller') ?? '',
                (project?.languagesMap && project?.languagesMap.length > 0) ? project.languagesMap.map(l => l.label).join(', ') : '-')
              }
              {getCard(getTranslation.getTranslation('calisma-sekli') ?? '',
                (project?.workingTypesMap && project?.workingTypesMap.length > 0) ? project.workingTypesMap.map(l => l.label).join(', ') : '-')
              }
            </div>
          </div>
          <div className={'pt-[40px] flex gap-[24px]'}>
            {getCard(getTranslation.getTranslation('proje-baslangic-tarihi'), <div
              className={'rounded-[24px] bg-white border-purple py-[17px] max-w-[228px] flex justify-center items-center gap-[16px] text-15-16-500'}>
              {project?.startDate ?? '-'}
            </div>, 'flex-1 !gap-[24px]'
            )}
            {getCard(getTranslation.getTranslation('proje-bitis-tarihi'), <div
              className={'rounded-[24px] bg-white border-purple py-[17px] max-w-[228px] flex justify-center items-center gap-[16px] text-15-16-500'}>
              {project?.endDate ?? '-'}
            </div>, 'flex-1 !gap-[24px]'
            )}
            {getCard((`${mainContext?.getTranslation('common', 'ucret-araligi')}${project?.currency ? ` (${project.currency.code})` : ''}`),
              <div
                className={'rounded-[24px] bg-white border-purple py-[17px] max-w-[228px] flex justify-center items-center gap-[16px] text-15-16-500'}>
                {project?.priceRange ? `${project.priceRange[0]} ${project.currency?.symbol} - ${project.priceRange[1]} ${project.currency?.symbol}` : '-'}
              </div>, 'flex-1 !gap-[24px]'
            )}
          </div>

          <div className={'flex gap-[14px] pt-[40px] pb-[24px]'}>
            <div className={'text-20-24-700'}>{getTranslation.getTranslation('yetenekler')}</div>
          </div>
          <div className={'flex gap-[16px] w-full max-w-[1010px] flex-wrap'}>
            {(project?.skillsMap && project.skillsMap.length > 0) ? project.skillsMap.map(s =>
              <div role={'button'} className={'roundLabel w-fit'}>{s.label}</div>
            ) : <p className={'text-20-24-700 text-black'}>-</p>}
          </div>

          <div className={'flex'}>
            <CInspectOffersButton mini={false} project={project} getTranslation={getTranslation} navigate={navigate}
              setShowOffers={setShowOffers} />
          </div>
          <div className={'flex justify-end gap-2'}>
            <ModalButton className={'div-grey'} loading={false} disabled={false}
              text={getTranslation.getTranslation('kaldir') ?? ''} onClick={() => {
                disableProject()
              }} />
            <ModalButton className={'div-green'} loading={false} disabled={false}
              text={mainContext?.getTranslation('common', 'guncelle') ?? ''} onClick={() => {
                navigate('/project-upsert#' + project?.projectKeyID)
              }} />
          </div>
        </div>
        {(!isLoading) &&
          <>
            <CProjectOfferListByCompanyModal projectTranslations={getTranslation!.getTranslation}
              offersData={offersData!}
              projectState={projectState!}
              project={project!}
              onOfferSelected={(offer) => {
                setOfferDetail(offer)
                navigate(`#offer=${offer.offerKeyId}`, { replace: true })
              }}
              onClose={(refresh) => {
                setShowOffers(false)
                navigate(``, { replace: true })
              }}
              isOpen={showOffers} />
            <CProjectOfferByCompanyModal
              project={project!}
              projectState={projectState}
              onClose={(newStatus) => {
                setOfferDetail(undefined)
                navigate(`#offers`, { replace: true })
                if (newStatus) {
                  if (newStatus == ProjectOfferUpdate.Confirmed || newStatus == ProjectOfferUpdate.Refuse) {
                    navigate(`#offers`, { replace: true })
                  }
                  fetchProjectData(project!.projectKeyID)
                }
              }}
              offer={offerDetail}
              projectTranslations={getTranslation?.getTranslation} />
          </>
        }
      </>
      }
    </div>
  </>
}
