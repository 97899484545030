export function DocumentOzelNitelikliVeriIsleme() {
    return <>
        <div className="documentContainer">
            <h1 className="documentHeader">ÖZEL NİTELİKLİ KİŞİSEL VERİ İŞLEME PROSEDÜRÜ</h1>

            <h2 className="documentTitle">1. Amaç</h2>
            <p className="documentText">
                Bu dokümanın amacı, POFFT DİJİTAL ÇÖZÜMLER TİCARET ANONİM ŞİRKETİ’nin (“Şirket”) gerçek kişilerden topladığı ve işlediği özel
                nitelikli kişisel verilerin işlenmesi ilişkin sürecin adımlarının tanımlanması ve bu süreçlerde görev alacak kişilerin rol ve
                sorumluluklarının tanımlanmasıdır.
            </p>

            <h2 className="documentTitle">2. Hedefler</h2>
            <p className="documentText">
                Bu prosedürün hedefi, Şirket bünyesinde işlenen özel nitelikli kişisel verilerin hukuka uygun ve adil yollardan toplandığından,
                işlenmesi için gereken izinlerin alındığından ve farklı bir amaç için kullanılmadığından emin olmaktır.
            </p>

            <h2 className="documentTitle">3. Kapsam ve Sorumluluk</h2>
            <p className="documentText">
                Bu dokümanın kapsamı, kanun hükümleri ile belirlenmiş olan özel nitelikli kişisel verileri Şirket tarafından işlenen gerçek kişiler ile
                bu verileri tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla
                işleyen Şirket bünyesindeki gerçek kişiler ve departmanlardır.
            </p>

            <h2 className="documentTitle">4. Tanımlar</h2>
            <ul className="documentList">
                <li><strong>Açık rıza:</strong> Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rıza.</li>
                <li><strong>Anonim hale getirme:</strong> Kişisel verilerin başka verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya belirlenebilir
                    bir gerçek kişiyle ilişkilendirilemeyecek hale getirilmesi.</li>
                <li><strong>Özel Nitelikli Kişisel Veri:</strong> Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, dini, mezhebi ve sağlık bilgileri gibi özel veriler.</li>
            </ul>

            <h2 className="documentTitle">5. Özel Nitelikli Kişisel Verilerin İşleyecek Çalışanlara İlişkin Alınması Gereken Önlemler</h2>
            <p className="documentText">
                Özel Nitelikli Kişisel Verilerin işlenmesi süreçlerinde çalışanlara eğitimler verilmesi, gizlilik sözleşmelerinin yapılması ve
                görev değişikliği veya işten ayrılma durumunda yetkilerin kaldırılması gibi önlemler alınır.
            </p>

            <h2 className="documentTitle">6. Özel Nitelikli Kişisel Verilerin İşlenme Şartları</h2>
            <p className="documentText">
                Özel Nitelikli Kişisel Veriler, ilgili kişinin açık rızası olmaksızın işlenemez. İstisnai durumlar dışında açık rıza gereklidir ve
                işleme sırasında gerekli izinler açıkça belirtilir.
            </p>

            <h2 className="documentTitle">7. Özel Nitelikli Kişisel Verilerin İşlendiği ve Erişildiği Ortamlar</h2>
            <p className="documentText"><strong>7.1. Elektronik Ortam:</strong> Veriler kriptografik yöntemlerle korunur, erişim logları tutulur ve iki kademeli
                kimlik doğrulama sistemi sağlanır.</p>
            <p className="documentText"><strong>7.2. Fiziksel Ortam:</strong> Verilerin bulunduğu ortamlar yetkisiz girişlere karşı korunur ve güvenlik önlemleri alınır.</p>

            <h2 className="documentTitle">8. Özel Nitelikli Kişisel Verilerin Aktarımı</h2>
            <ul className="documentList">
                <li>E-posta yoluyla aktarımda şifreleme uygulanır.</li>
                <li>Taşınabilir belleklerle aktarımda kriptografik yöntemler kullanılır.</li>
                <li>Kağıt ortamında aktarımda belgeler gizlilik dereceli olarak işaretlenir.</li>
            </ul>

            <h2 className="documentTitle">9. Bilgilendirme Süreci (Aydınlatma Yükümlülüğünün Kapsamı)</h2>
            <p className="documentText">
                Veri sorumluları, kişisel verilerin elde edilmesi sırasında ilgili kişileri, veri işleme amaçları ve aktarım bilgileri gibi konularda bilgilendirir.
            </p>

            <h2 className="documentTitle">10. Veri Toplama ve Açık Rıza Alma Sürecinin İşletilmesi</h2>
            <p className="documentText">
                Kişisel verilerin işlenmesi için açık rızalar, yazılı ya da elektronik ortamda alınır. Personel rızaları özlük dosyalarında saklanır.
            </p>

            <h2 className="documentTitle">11. YÜRÜRLÜK VE GÜNCEL TUTMA</h2>
            <p className="documentText">
                İşbu Prosedür, 01.05.2022 tarihinde yürürlüğe girmiş olup; her yıl KVK Komitesi tarafından gözden geçirilerek gerekirse güncellenir.
            </p>
        </div>

    </>
}