export function DocumentTuzelKisiTedarikciTaahhutname() {
    return <>
        <div className="documentContainer">
            <h1 className="documentHeader">KİŞİSEL VERİLERİN KORUNMASI TAAHHÜTNAMESİ</h1>

            <p className="documentText">
                …………….…………………… adresinde mukim ……………………… (kısaca “……………..…” olarak anılacaktır), Maslak Mah. Maslak Meydan Sk. Beybi Giz Plaza A Blok No: 1
                İç Kapı No: 65 Sarıyer / İstanbul adresinde mukim POFFT DİJİTAL ÇÖZÜMLER TİCARET ANONİM ŞİRKETİ (“Pofft” olarak anılacaktır) için vereceği
                hizmetler kapsamında aşağıda yer verilen yükümlülüklere eksiksiz olarak uyacağını kabul, beyan ve taahhüt eder:
            </p>

            <p className="documentText">
                “………………………….…., Pofft için vereceği hizmetler kapsamında, Pofft’a ve/veya çalışanlarına ve/veya temsilcilerine ve/veya müşterilerine dair edinmiş
                olduğu her türlü kişisel veriyi işlerken 6698 sayılı Kişisel Verilen Korunması Hakkında Kanunu’na uygun davranacağını, bu verileri, 6698 sayılı Kanun’da
                sayılan, veri işlemeyi hukuka uygun hale getiren nedenlerden biri olmadıkça kendisinden başka hiçbir kurum/kuruluş/kişi ile paylaşmayacağını, sadece kendi
                yetkili personelleri tarafından, Pofft’a sunacağı hizmetin amaç ve kapsamı ile sınırlı olmak kaydıyla ve yalnızca bu amaçla kullanılmak için edinileceğini
                ve amaçla bağlantılı süre kısıtlaması altında işlenebileceğini, söz konusu verilerle ilgili her türlü teknik ve idari tedbiri alacağını, yasal süreler
                veya işleme amacı ortadan kalktığında verileri derhal sileceğini veya yok edeceğini veya anonim hale getireceğini, bahsi geçen yükümlülüklere uyulmaması
                nedeni ile Pofft’a yöneltilebilecek olan her hangi bir idari veya cezai yaptırım olması halinde ilgili zararlar için kendisine rücu edileceğini kabul,
                beyan ve taahhüt eder.”
            </p>

        </div>

    </>
}