import {FilterModal} from "./Filter/FilterModal";
import {useContext, useEffect, useState} from "react";
import {MainContext} from "./../context/MainContext";
import {JobStatus, JobStatusInt, TJob, TJobWithOffer} from "../types/TJob";
import {TextField} from "@mui/material";
import {NumericFormatPrice} from "./Numeric/PriceInput";
import {postV2} from "../utils/request";
import {TError} from "../types/TError";
import {NotificationType} from "./Toast/Toast";
import {updateOffer} from "../service/projectRequest";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  close: () => void
  update: () => void
  job?: TJobWithOffer
}

export function CUpdateOfferModal({getTranslation, isOpen, close, update, job}: IProps) {
  const mainContext = useContext(MainContext)

  const [isUpdating, setIsUpdating] = useState(false)
  const [newAmount, setNewAmount] = useState<number | undefined>(undefined)

  const isUpdatable = newAmount && newAmount > 0 && (job?.price ? (job.price != newAmount) : true)

  function save() {
    if (mainContext?.isExpired('updateoffer')) {
      return
    }
    if (!isUpdatable) {
      return
    }
    setIsUpdating(true)
    updateOffer(job?.projectKeyID, job?.currencyID, newAmount, professionalMessage, true).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateOffer')
      } else {
        mainContext?.showToast(NotificationType.Success, 'updateOffer')
        update()
        close();
      }
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Fail, 'updateOffer')
    }).finally(() => {
      setIsUpdating(false)
    })
  }

  useEffect(() => {
    if (job) {
      setProfessionalMessage(job?.offer.professionalRemark ?? '')
    }
  }, [job]);

  const [priceError, setPriceError] = useState<undefined | string>(undefined)

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const price = parseFloat(event.target.value);
    setNewAmount(price);
    if (job?.priceRange && job.priceRange.length > 1) {
      const minPrice = job.priceRange[0]
      const maxPrice = job.priceRange[1]
      if (price < minPrice) {
        setPriceError(getTranslation('min-ucretten-kucuk-girilemez'))
        return
      } else if (price > maxPrice) {
        setPriceError(getTranslation('max-ucretten-buyuk-girilemez'))
        return
      }
    }
    setPriceError(undefined)
  };

  const [professionalMessage, setProfessionalMessage] = useState('')

  function acceptCompanyPrice() {
    setNewAmount(job?.offer!.companyRequestPrice)
  }
debugger
  return <FilterModal
    title={getTranslation('teklifi-guncelle')}
    header={''}
    isOpen={isOpen}
    onClose={close}
    onSave={save}
    requesting={isUpdating}
    saveDisabled={!isUpdatable || isUpdating || priceError != undefined}
    customHeaderClass={'!pb-[0px]'}
  >
    <div className={'flex-col'}>
      <div className={'flex justify-between'}>
        <div className={'flex-col gap-[20px]'}>
          <p
            className={'text-14-24-700'}>{getTranslation('teklif-verdigin-tutar')}</p>
          <div className={'input-text disabled w-[222px]'}>
            <TextField
              value={job?.price ?? 0}
              disabled
              InputProps={{
                inputComponent: NumericFormatPrice as any,
              }}
              variant="outlined"
            />
          </div>

        </div>
        {(job?.companyRequestPrice && job?.companyRequestPrice>0) ?
            <div className={'flex-col gap-[20px]'}>
                <p className={'text-14-24-700'}>{getTranslation('gelen-teklif-tutar')}</p>
                <div className={'input-text disabled w-[222px]'}>
                    <TextField
                        value={job?.companyRequestPrice ?? ''}
                        disabled
                        InputProps={{
                          inputComponent: NumericFormatPrice as any,
                        }}
                        variant="outlined"
                    />
                </div>
            </div>:<></>}
      </div>
      <div className={'flex flex-col gap-[12px] pt-[16px]'}>
        <p
          className={'text-12-24-700'}>{getTranslation('uzmana-mesajiniz')}</p>
        <div
          className={'w-full rounded-[16px] border-purple p-[16px] flex justify-center'}>
          <p className={'text-12-24-500 text-black'}>
            {(job?.offer?.professionalRemark ?? '').length == 0 ? mainContext?.getTranslation('common', 'girilmedi') : job?.offer?.professionalRemark}
          </p>
        </div>
      </div>
      <div className={'flex flex-col gap-[12px] pt-[16px]'}>
        <p
          className={'text-12-24-700'}>{getTranslation('sirket-proje-hakkinda-dusunceleri')}</p>
        <div
          className={'w-full rounded-[16px] border-purple p-[16px] flex justify-center'}>
          <p className={'text-12-24-500 text-black'}>
            {(job?.offer?.companyRemark ?? '').length == 0 ? mainContext?.getTranslation('common', 'girilmedi') : job?.offer?.companyRemark}
          </p>
        </div>
      </div>
      <div className={'flex flex-col gap-[12px] pt-[32px]'}>
        <p className={'text-12-24-700'}>
          {getTranslation('mesaj-gir')}
        </p>
        <TextField
          className={'purple-border-input-round16'}
          placeholder={mainContext?.getTranslation('common', 'giriniz')}
          value={professionalMessage}
          onChange={(e) => setProfessionalMessage(e.target.value)}
          variant="outlined"
        />
      </div>
      <div className={'pt-[16px]'}>
        <div className={'flex gap-[12px] items-center'}>
          <div className={'modalTitle'}>{getTranslation('teklifi-guncelle')}</div>
          {job?.offer?.price && job?.offer?.price > 0 && job?.offer?.companyRequestPrice > 0 && job?.offer?.price != job?.offer?.companyRequestPrice && (newAmount == undefined || newAmount != job?.offer?.companyRequestPrice) &&
            < p onClick={acceptCompanyPrice} role={'button'}
            className={'text-10-16-600 text-purple'}>
          {getTranslation('sirket-teklifini-kabul-et')}
            </p>
          }
        </div>
        <div className={'text-14-24-500 pb-[16px]'}>{getTranslation('tutari-sirket-gorecek')}</div>
      </div>
      <div className={'input-text w-[222px]'}>
        <TextField
          placeholder={mainContext?.getTranslation('common', 'giriniz')}
          value={newAmount}
          onChange={handlePriceChange}
          InputProps={{
            inputComponent: NumericFormatPrice as any,
          }}
          variant="outlined"
        />
        {priceError && (
          <div className="text-red-400 text-12-14-500 pt-[8px]">
            {priceError}
          </div>
        )}
      </div>
    </div>
  </FilterModal>
}
