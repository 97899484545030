import { useContext, useEffect, useState } from "react";
import CLoadingAnimation from "src/components/CLoadingAnimation";
import CPurpleTextArrow from "src/components/CPurpleTextArrow";
import { DocumentBasvuruYonetimProseduru } from "src/components/Documents/document_basvuru_yonetim_proseduru";
import { DocumentInternetPlatformuAydinlatmaMetni } from "src/components/Documents/document_internet_platformu_aydinlatma_metni";
import { DocumentKisiselVeriIhlali } from "src/components/Documents/document_kisisel_veri_ihlali";
import { DocumentKisiselVerileriKayitSaklama } from "src/components/Documents/document_kisisel_verileri_kayit_saklama";
import { DocumentKisiselVerilerinAktarimi } from "src/components/Documents/document_kisisel_verilerin_aktarimi";
import { DocumentKisiselVerilerinKorunmasiIslenmesiPolitikasi } from "src/components/Documents/document_kisisel_verilerin_korunmasi_islenmesi_politikasi";
import { DocumentOzelNitelikliVeriIsleme } from "src/components/Documents/document_ozel_nitelikli_veri_isleme";
import { DocumentTuzelKisiTedarikciTaahhutname } from "src/components/Documents/document_tuzel_kisi_tedarikci_taahhutname";
import { DocumentVeriIsleyenTaahhutnamesi } from "src/components/Documents/document_veri_isleyen_taahhutnamesi";
import { DocumentCerezPolitikasi } from "src/components/Documents/documents_cerez_politikasi";
import { MainContext } from "src/context/MainContext";
import { useTranslation } from "src/hooks/TranslationHook";
import { DokumanTranslation } from "src/objects/TranslationModel";
import { isDesktop } from "src/utils/window";

export default function Documents() {

    const mainContext = useContext(MainContext);

    const documentsTranslations = useTranslation(DokumanTranslation)

    const [selectedDocument, setSelectedDocument] = useState<string | undefined>(undefined);

    const IsDesktop = isDesktop();

    const getDocumentFromUrl = () => {
        const hash = window.location.hash;
        if (hash) {
            return hash.substring(1); // Remove the # character
        }
        return undefined;
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
            });
        }, 100);
        const docFromUrl = getDocumentFromUrl();
        if (docFromUrl) {
            setSelectedDocument(docFromUrl);
        }
    }, []);

    function selectDocument(document: string | undefined) {
        setSelectedDocument(document);
        if (document) {
            // Update the URL with the selected document as a hash
            window.history.pushState(null, '', `/sozlesmeler/#${document}`);
        } else {
            // Update the URL to remove the hash
            window.history.pushState(null, '', '/sozlesmeler');
        }
    }


    return (
        (documentsTranslations.translations == undefined || !mainContext?.translationsLoaded) ? <div className="flex justify-center items-center h-[100vh] w-[100vw]">
            <CLoadingAnimation fullscreen />
        </div>
            :
            <div className={`min-h-[100vh] w-[100vw] flex gap-[2vw] pt-[112px] lg:pt-[132px] ${(isDesktop() || selectedDocument == undefined) ? 'pl-[20px]' : ''} md:pr-[24px]`}>
                {(isDesktop() || selectedDocument == undefined) &&
                    <div className="flex flex-col gap-[32px] align-items-start w-[100vw] md:w-[max(35vw,430px)] pb-[48px]">
                        <p className="pl-[4px] pb-[8px] text-30-32-600_50-52-600 text-grey">{mainContext?.getTranslation("menu", "sozlesmeler")}</p>

                        <div className="self-start w-[100%]">
                            <CPurpleTextArrow isChosen={selectedDocument == 'kisisel-verilerin-korunmasi'} text={documentsTranslations.getTranslation('kisisel-verilerin-korunmasi-link')} onClick={() => selectDocument('kisisel-verilerin-korunmasi')} />
                        </div>
                        <div className="self-start w-[100%]">
                            <CPurpleTextArrow isChosen={selectedDocument == 'internet-platformu-aydinlatma'} text={documentsTranslations.getTranslation('internet-platformu-aydinlatma-link')} onClick={() => selectDocument('internet-platformu-aydinlatma')} />
                        </div>
                        <div className="self-start w-[100%]">
                            <CPurpleTextArrow isChosen={selectedDocument == 'cerez-politikasi'} text={documentsTranslations.getTranslation('cerez-politikasi-link')} onClick={() => selectDocument('cerez-politikasi')} />
                        </div>
                        <div className="self-start w-[100%]">
                            <CPurpleTextArrow isChosen={selectedDocument == 'veri-isleyen-taahhutnamesi'} text={documentsTranslations.getTranslation('veri-isleyen-taahhutnamesi-link')} onClick={() => selectDocument('veri-isleyen-taahhutnamesi')} />
                        </div>
                        <div className="self-start w-[100%]">
                            <CPurpleTextArrow isChosen={selectedDocument == 'tuzel-kisi-taahhutname'} text={documentsTranslations.getTranslation('tuzel-kisi-taahhutname-link')} onClick={() => selectDocument('tuzel-kisi-taahhutname')} />
                        </div>
                        <div className="self-start w-[100%]">
                            <CPurpleTextArrow isChosen={selectedDocument == 'kisisel-verilerin-aktarimina-iliskin'} text={documentsTranslations.getTranslation('kisisel-verilerin-aktarimina-iliskin-link')} onClick={() => selectDocument('kisisel-verilerin-aktarimina-iliskin')} />
                        </div>
                        <div className="self-start w-[100%]">
                            <CPurpleTextArrow isChosen={selectedDocument == 'kisisel-verileri-kayit-saklama'} text={documentsTranslations.getTranslation('kisisel-verileri-kayit-saklama-link')} onClick={() => selectDocument('kisisel-verileri-kayit-saklama')} />
                        </div>
                        <div className="self-start w-[100%]">
                            <CPurpleTextArrow isChosen={selectedDocument == 'kisisel-veri-ihlali'} text={documentsTranslations.getTranslation('kisisel-veri-ihlali-link')} onClick={() => selectDocument('kisisel-veri-ihlali')} />
                        </div>
                        <div className="self-start w-[100%]">
                            <CPurpleTextArrow isChosen={selectedDocument == 'basvuru-yonetim-proseduru'} text={documentsTranslations.getTranslation('basvuru-yonetim-proseduru-link')} onClick={() => selectDocument('basvuru-yonetim-proseduru')} />
                        </div>
                        <div className="self-start w-[100%]">
                            <CPurpleTextArrow isChosen={selectedDocument == 'kisisel-verilerin-korunmasi'} text={documentsTranslations.getTranslation('kisisel-verilerin-korunmasi-link')} onClick={() => selectDocument('kisisel-verilerin-korunmasi')} />
                        </div>
                        <div className="self-start w-[100%]">
                            <CPurpleTextArrow isChosen={selectedDocument == 'ozel-nitelikli-veri-isleme'} text={documentsTranslations.getTranslation('ozel-nitelikli-veri-isleme-link')} onClick={() => selectDocument('ozel-nitelikli-veri-isleme')} />
                        </div>
                    </div>
                }
                <div className="flex flex-col flex-1 relative">
                    {(!isDesktop() && selectedDocument != undefined) &&
                        <div className="flex fixed left-[16px] top-[102px]">
                            <div onClick={() => selectDocument(undefined)}><img src={'/images/leftarrow.svg'} /></div>
                        </div>
                    }
                    <div className="h-[100%]">
                        {(() => {
                            if (selectedDocument == 'internet-platformu-aydinlatma') {
                                return <DocumentInternetPlatformuAydinlatmaMetni />;
                            } else if (selectedDocument == 'kisisel-verilerin-korunmasi') {
                                return <DocumentKisiselVerilerinKorunmasiIslenmesiPolitikasi />;
                            } else if (selectedDocument == 'cerez-politikasi') {
                                return <DocumentCerezPolitikasi />;
                            } else if (selectedDocument == 'veri-isleyen-taahhutnamesi') {
                                return <DocumentVeriIsleyenTaahhutnamesi />;
                            } else if (selectedDocument == 'tuzel-kisi-taahhutname') {
                                return <DocumentTuzelKisiTedarikciTaahhutname />;
                            } else if (selectedDocument == 'kisisel-verilerin-aktarimina-iliskin') {
                                return <DocumentKisiselVerilerinAktarimi />;
                            } else if (selectedDocument == 'kisisel-verileri-kayit-saklama') {
                                return <DocumentKisiselVerileriKayitSaklama />;
                            } else if (selectedDocument == 'kisisel-veri-ihlali') {
                                return <DocumentKisiselVeriIhlali />;
                            } else if (selectedDocument == 'basvuru-yonetim-proseduru') {
                                return <DocumentBasvuruYonetimProseduru />;
                            } else if (selectedDocument == 'kisisel-verilerin-korunmasi') {
                                return <DocumentKisiselVerilerinKorunmasiIslenmesiPolitikasi />;
                            } else if (selectedDocument == 'ozel-nitelikli-veri-isleme') {
                                return <DocumentOzelNitelikliVeriIsleme />;
                            }
                            return <></>;
                        })()}
                    </div>
                </div>
            </div>
    );

}
