export function DocumentSorumsuzlukBeyani() {
    return <>
        <div className="documentContainer">

            <p className="documentText documentTextBig">
                <a href="https://www.pofft.com" style={{ textDecoration: 'none' }}>pofft.com</a> tarafından sunulan hizmetler hiçbir koşulda istihdama aracılık faaliyeti teşkil etmemekte olup <a href="https://www.pofft.com" style={{ textDecoration: 'none' }}>pofft.com</a> yalnızca, tüm tarafların bağımsız olarak hareket ettiği, her biri ayrı ayrı vergi mükellefi olması beklenen hizmet alan ve hizmet verenlerin bir araya gelmelerini kolaylaştıran bir platform işlevi görmektedir. Öte yandan <a href="https://www.pofft.com" style={{ textDecoration: 'none' }}>pofft.com</a>, verilen hizmetin içeriği, kalitesi ve muntazamlığı konusunda hiçbir sorumluluk taahhüdünde bulunmamaktadır.
            </p>

        </div>
    </>
}