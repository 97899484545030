export function DocumentKisiselVeriIhlali() {
    return <>
        <div className="documentContainer">
            <h1 className="documentHeader">KİŞİSEL VERİ İHLALİ MÜDAHALE ve KRİZ YÖNETİMİ PROSEDÜRÜ</h1>

            <h2 className="documentTitle">1. AMAÇ</h2>
            <p className="documentText">
                6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (“Kanun”) 12. maddesinin 5. fıkrasına göre POFFT DİJİTAL ÇÖZÜMLER TİCARET ANONİM ŞİRKETİ
                (“Şirket”) veri sorumlusu olarak, işlenen kişisel verilerin kanuni olmayan yollarla başkaları tarafından elde edilmesi halinde, bu durumu
                en kısa sürede (en geç 72 saat içerisinde) ilgilisine ve Kişisel Verileri Koruma Kurulu’na (“Kurul”) bildirmekle yükümlüdür.
            </p>
            <p className="documentText">
                İşbu Kişisel Veri İhlali Müdahale Prosedürü (“Prosedür”), kişisel verilerin kanuni olmayan yollarla başkaları tarafından elde edilmesi halinde
                oluşacak krize nasıl müdahale edileceği ve atılacak adımların neler olduğu konusunda çalışanları bilgilendirmek amacıyla hazırlanmıştır.
            </p>

            <h2 className="documentTitle">2. SORUMLULUK</h2>
            <p className="documentText">
                Şirket’in tüm çalışanları Prosedür’ün uygulanmasından sorumludur. Prosedür’e aykırı hareket eden çalışanlar “Disiplin Prosedürü”
                hükümlerine tabi olacaktır.
            </p>

            <h2 className="documentTitle">3. KİŞİSEL VERİ İHLALİ</h2>
            <p className="documentText">
                Kişisel veri ihlali, kişisel verilerin kanuna aykırı bir şekilde elde edilmesi, hukuka aykırı bir şekilde kişisel verilere yetkisiz erişim sağlanması, kişisel verilerin yanlışlıkla/kasten yetkisiz kişilere açıklanması, kişisel verilerin hukuka aykırı bir şekilde silinmesi, değiştirilmesi veya bütünlüğünün bozulması gibi durumlarda ortaya çıkmaktadır.
            </p>
            <p className="documentText">
                Aşağıda yer alan durumlar, sınırlı olmamak üzere, genel olarak kişisel veri ihlali olarak değerlendirilir:
            </p>
            <ul className="documentList">
                <li>Kişisel veri içeren fiziki dokümanların veya elektronik cihazların çalınması veya kaybolması,</li>
                <li>Kişisel veri içeren elektronik belgelerin, donanım veya yazılımlar aracılığıyla şirket dışına çıkarılması,</li>
                <li>Kişiye özel kullanıcı adı ve parolaların yetkisiz kişilerce ele geçirilmesi,</li>
                <li>Gizli bilgilerin hukuka aykırı şekilde ifşası,</li>
                <li>Kişisel veri ve/veya gizli bilgi içeren e-postaların yanlışlıkla şirket dışında ilgisiz kişilere iletilmesi, gönderimi,</li>
                <li>Bilgi Teknolojileri ekipmanlarına, sistemlerine ve ağlarına virüs veya diğer saldırıların (örneğin; siber saldırı) gerçekleşmesi suretiyle kişisel verilere hukuka
                    aykırı erişim sağlanması.</li>
            </ul>

            <h2 className="documentTitle">4. KRİZ MÜDAHALE EKİBİ</h2>
            <p className="documentText">
                Kişisel veri ihlali durumunda oluşan veya oluşabilecek kriz durumuna müdahale etmek ve Kanun kapsamında öngörülen yükümlülükleri yerine getirmek için aşağıdaki departmanlardan belirlenen katılımcıların dahil edileceği bir Kriz Müdahale Ekibi (“Ekip”) oluşturulur:
            </p>
            <ul className="documentList">
                <li>Veri Sorumlusu İrtibat Kişisi</li>
                <li>Veri Sorumlusu Yönetim Kurulu Başkanı</li>
                <li>İhlalin Meydana Geldiği Departmanın Yöneticisi</li>
                <li>Hukuk Danışmanı</li>
            </ul>

            <h2 className="documentTitle">5. KRİZ MÜDAHALE SÜRECİ</h2>

            <p className="documentText">Kişisel Veri İhlali Bildirim Usul ve Esaslarına İlişkin Kişisel Verileri Koruma Kurulu’nun 24.01.2019 Tarih ve 2019/10 Sayılı Kararı (“Karar”) uyarınca, Şirket’in kişisel veri ihlalini öğrendiği tarihten itibaren gecikmeksizin ve en geç yetmiş iki (72) saat içinde Kurul’a bildirmesi ve veri ihlalinden etkilenen kişilerin belirlenmesini müteakip ilgili kişilere de makul olan en kısa süre içerisinde ilgili kişinin iletişim adresine ulaşılabiliyorsa doğrudan, ulaşılamıyorsa Şirket’in kendi internet sitesi üzerinden yayımlanması gibi uygun yöntemlerle bildirim yapılması gerekmektedir.
            </p>
            <p className="documentText">Söz konusu yükümlülüklerin yerine getirilebilmesi için, bir veri ihlali durumunda öncelikle şirket içerisinde belirli adımlar takip edilmelidir:
            </p>
            <ul className="documentList">
                <li>Krize ilişkin ön değerlendirme,</li>
                <li>Engelleme ve kurtarma çalışmalarının yürütülmesi,</li>
                <li>Risklerin değerlendirilmesi,</li>
                <li>Bildirim,</li>
                <li>Değerlendirme ve iyileştirme.</li>
            </ul>

            <h3 className="subSection">5.1. Krize İlişkin Ön Değerlendirme</h3>
            <p className="documentText">
                Şirket nezdinde gerçek veya potansiyel bir veri ihlalinin söz konusu olması halinde, ilgili tüm çalışanlar, Veri Sorumlusu İrtibat Kişisi’ne derhal ve gecikmeksizin durumu bildirmekle yükümlüdür. Bu kapsamda ilgili çalışan aşağıdaki hususları içerir bir rapor hazırlayarak, veri ihlalini Veri Sorumlusu İrtibat Kişisi’ne bildirir:
            </p>
            <ul className="documentList">
                <li>Kişisel veri ihlalinin gerçekleşme tarihi ve saati,</li>
                <li>Kişisel veri ihlalinin tespiti tarihi ve saati,</li>
                <li>Kişisel veri ihlali olayına ilişkin açıklamalar,</li>
                <li>Eğer biliniyorsa kişisel veri ihlalinden etkilenen kişi ve kayıt sayısı,</li>
                <li>Kişisel veri ihlalinin tespit edildiği tarihte varsa atılan adımlara, alınan önlemlere ilişkin açıklamalar,</li>
                <li>Raporu hazırlayan çalışanın/çalışanların adı soyadı, iletişim bilgileri ve rapor tarihi.</li>
            </ul>
            <p className="documentText">Veri Sorumlusu İrtibat Kişisi, rapor kapsamında belirtilen hususları dikkate alarak bir ön değerlendirme yapar ve eş zamanlı olarak raporu Hukuk Danışmanı ile paylaşır. İrtibat Kişisi, bu değerlendirmeyi yaparken gerçekten bir veri ihlalinin söz konusu olup olmadığını, ihlalin kapsamını, oluşabilecek etkilerini de göz önünde bulundurarak, Ekip ile birlikte veri ihlalinin araştırılması için kapsamlı bir soruşturma başlatır.
            </p>



            <h3 className="subSection">5.2. Engelleme ve Kurtarma Çalışmalarının Yürütülmesi</h3>
            <p className="documentText">
                Veri ihlalinin, Şirket ve ilgili kişiler üzerindeki etkilerinin azaltılabilmesi için engelleme ve kurtarma çalışmaları ekibin gözetiminde yürütülür. Bu kapsamda öncelikle veri ihlalinden haberdar edilmesi gereken departmanlar tespit edilir ve bu kişilere ihlalin kontrol edilebilmesi, mümkünse engellenebilmesi ve zararların azaltılabilmesi için atılması gereken adımlara ilişkin rehberlik edilir. Akabinde veri ihlalinden etkilenecek kişilerin ve kayıtların neler olduğu tespit edilmeye çalışılır ve varsa bu kişilerin iletişim bilgileri de belirlenir. Eş zamanlı olarak, veri ihlali nedeniyle haberdar edilmesi gereken başka kurum ya da kuruluşlar olup olmadığı değerlendirilir.
            </p>

            <h3 className="subSection">5.3. Risklerin Değerlendirilmesi</h3>
            <p className="documentText">
                Kişisel veri ihlalleri, ihlalden etkilenen kişiler üzerinde kimlik hırsızlığı, hakların kısıtlanması dolandırıcılık, finansal kayıp, itibar kaybı, kişisel verilerin güvenliğinin kaybı, ayrımcılık gibi birçok olumsuz etki oluşturabilir. Bu nedenle kişisel veri ihlalinin olası sonuçlarının Şirket ve ihlalden etkilenen kişiler üzerinde ne gibi etkiler oluşturabileceğinin dikkatli bir şekilde değerlendirilmesi ve risklerin ortaya koyulması çok önemlidir.
            </p>
            <p className="documentText">
                Ekip tarafından riskler değerlendirilirken, ihlalden etkilenen kişisel verilerin niteliği, hassasiyeti ve hacmi ile etkilenen bireylerin sayısı ve kişi gruplarının kimler olduğu, veri ihlalinin Şirket’in faaliyetleri ile itibarına olan etkisi, veri ihlalinin etkisinin azaltılmasında alınan önlemler ve ihlalin olası sonuçları ayrı ayrı ele alınmalıdır. Bunların sonucuna göre veri ihlali “düşük düzeyde, orta düzeyde veya yüksek düzeyde risk” olarak nitelendirilir:
            </p>

            <ul className="documentList">
                <li><strong>Düşük düzeyde risk:</strong> İhlal ilgili kişiler üzerinde olumsuz herhangi bir etkiye neden olmamakta ya da bu etki ihmal edilebilir düzeyde kalmaktadır.</li>
                <li><strong>Orta düzeyde risk:</strong> İhlal ilgili kişiler üzerinde olumsuz etkilere neden olabilir fakat bu etki büyük değildir.</li>
                <li><strong>Yüksek düzeyde risk:</strong> İhlal etkilenen kişiler üzerinde ciddi düzeyde olumsuz etkilere neden olmaktadır.</li>
            </ul>

            <p className="documentText">Orta ve özellikle yüksek düzeyde risk olarak tanımlanan veri ihlallerine ilişkin veri sorumlusu üst yönetimine Ekip tarafından bilgi verilir.
            </p>

            <h3 className="subSection">5.4. Bildirim</h3>
            <p className="documentText">
                Veri ihlalinin gerek hukuki yükümlülük kapsamında gerekse veri ihlaline ilişkin tedbir alınması, ihlalin olası etkilerinin azaltılması gibi amaçlarla Şirket dışında üçüncü kişilere bildirilmesi gerekmektedir.
            </p>
            <h3 className="subSection">5.4.1.  Kurul’a Bildirim            </h3>
            <p className="documentText">
                Veri Sorumlusu İrtibat Kişisi, öncelikle kişisel veri ihlalinden haberdar olduğu andan itibaren gecikmeksizin ve en geç yetmiş iki (72) saat içerisinde Kurul’a bu durumu bildirmekle yükümlüdür. Bu nedenle, Şirket içerisinde tüm çalışanların herhangi bir veri ihlali durumunu vakit kaybetmeksizin Veri Sorumlusu İrtibat Kişisi’ne bildirmesi, Şirket’in herhangi bir yaptırımla karşı karşıya kalmaması için önem arz etmektedir.
            </p>
            <p className="documentText">
                Kurul’a yapılacak bildirimde Kişisel Verileri Koruma Kurumu’nun (“Kurum”) internet sitesinde yayınlanmış olan Kişisel Veri İhlali Başvuru Formu kullanılır. Formda yer alan bilgilerin aynı anda sağlanmasının mümkün olmadığı hallerde, bu bilgiler gecikmeye mahal verilmeksizin aşamalı olarak sağlanabilir. Haklı bir gerekçe ile yetmiş iki (72) saat içerisinde Kurul’a bildirim yapılamaması durumunda, yapılacak bildirimle birlikte gecikmenin nedenleri de Kurul’a açıklanır.
            </p>
            <h3 className="subSection">5.4.2. İhlalden Etkilenen Kişilere Bildirim
            </h3>
            <p className="documentText">
                Şirket, kişisel veri ihlalinden etkilen kişilerin belirlenmesini müteakip ilgili kişilere de makul olan en kısa süre içerisinde, ilgili kişinin iletişim adresine ulaşılabiliyorsa doğrudan, ulaşılamıyorsa uygun yöntemlerle (örneğin internet sitesi üzerinden duruma ilişkin bir duyuru yayınlanması) bildirim yapmalıdır. Söz konusu bildirimler, Ekibin desteğiyle Veri Sorumlusu İrtibat Kişisi tarafından gerçekleştirilir.
            </p>
            <p className="documentText">
                Veri sorumlusu tarafından ilgili kişiye yapılan veri ihlali bildiriminde yer alması gereken asgari unsurlara ilişkin, Kurul’un 18.09.2019 tarih ve 2019/271 sayılı Kararı uyarınca Şirket tarafından ilgili kişiye yapılacak olan ihlal bildiriminin açık ve sade bir dille yapılması ve asgari olarak aşağıdaki unsurları içermesi gerekir:
            </p>

            <ul className="documentList">
                <li>İhlalinin ne zaman gerçekleştiği,</li>
                <li>Kişisel veri kategorileri bazında (kişisel veri/özel nitelikli kişisel veri ayrımı yapılarak) hangi kişisel verilerin ihlalden etkilendiği,</li>
                <li>Kişisel veri ihlalinin olası sonuçları,</li>
                <li>Veri ihlalinin olumsuz etkilerinin azaltılması için alınan veya alınması önerilen tedbirler,</li>
                <li>İlgili kişilerin veri ihlali ile ilgili bilgi almalarını sağlayacak irtibat kişilerinin isim ve iletişim detayları ya da veri sorumlusunun internet sayfasının tam adresi, çağrı merkezi vb. iletişim yolları unsurlarına yer verilmesi.</li>
            </ul>
            <h3 className="subSection">5.4.3. Diğer Bildirimler
            </h3>
            <p className="documentText">
                Şirket’in hukuken yapması zorunlu olan bildirimlerin yanı sıra, veri ihlalinin niteliği, büyüklüğü, ihlalin suç teşkil edip etmediği gibi hususlar göz önünde bulundurularak üçüncü kişilere de bildirim yapılması gerekebilir. Bu kişiler, diğer veri sorumluları ya da veri işleyenler, dış danışmanlar, adli makamlar, bankalar olabilir. Ekip, böyle bir gereklilik olup olmadığını ayrıca değerlendirir ve gerekli ise bildirimleri yapar.
            </p>

            <h3 className="subSection">5.5 Değerlendirme ve İyileştirme</h3>
            <p className="documentText">
                Şirket tarafından kişisel veri ihlallerine ilişkin tüm bilgilerin, etkilerinin ve alınan önlemlerin kayıt altına alınması ve Kurul’un incelemesine hazır halde bulundurulması gerekmektedir. Veri Sorumlusu İrtibat Kişisi ve Ekip, veri ihlaline ilişkin atılan adımların uygun olup olmadığını ve olası bir veri ihlalinde geliştirilebilecek/iyileştirilebilecek hususların neler olabileceğini belirlemek adına bir değerlendirme yapar. Bu kapsamda Ekip, aşağıdaki unsurları içerir bir değerlendirme ve iyileştirme raporu hazırlar:

            </p>

            <ul className="documentList">
                <li>Olası kişisel veri ihlallerinin etkilerini azaltmak için hangi adımların atılması gerektiği,
                </li>
                <li>Kişisel veri ihlali nedeniyle herhangi bir politika, prosedür ya da raporlamada iyileştirme gerekip gerekmediği,
                </li>
                <li>Kişisel veri ihlalinin tekrarlanmasını önleyebilmek için ek bir idari ve/veya teknik tedbir alınmasının gerekli olup olmadığı,
                </li>
                <li>İhlalin tekrarlanmasını önleyecek bir personel farkındalık eğitimi gerekliliği,
                </li>
                <li>İhlallere maruz kalmayı ve maliyet etkilerini azaltmak için kaynaklara/altyapıya ek yatırım yapılmasının gerekli olup olmadığı.
                </li>
            </ul>

            <h2 className="documentTitle">6. İLGİLİ POLİTİKA VE PROSEDÜRLER</h2>
            <p className="documentText">
                Bu Prosedür, Şirket nezdinde kişisel verilerin korunması ve işlemesine ilişkin yürürlüğe konmuş tüm politika ve prosedürler ile birlikte ele alınmalıdır.
            </p>

            <h2 className="documentTitle">7. YÜRÜRLÜK VE GÜNCEL TUTMA</h2>
            <p className="documentText">
                İşbu Prosedür, 01.05.2022 tarihinde yürürlüğe girmiş olup; Kanun, ilgili ikincil mevzuat, Kurul Kararları ve Şirket iş süreçleri doğrultusunda KVK Komitesi tarafından her yıl başında yeniden değerlendirilecek ve gerekmesi halinde güncellenecektir.
            </p>
        </div>

    </>
}