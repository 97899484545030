import {
  Button,
  FocusLock,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainContext } from "src/context/MainContext";
import { useTranslation } from "src/hooks/TranslationHook";
import { DilTranslation } from "src/objects/TranslationModel";

interface PTopbarMenu {
  isMobile?: boolean;
  onClicked?: () => void;
}

function CLanguageMenu({ onClicked }: PTopbarMenu) {
  const mainContext = useContext(MainContext);

  const [selectedLang, setSelectedLang] = useState<string | undefined>(
    undefined
  );
  const navigate = useNavigate();

  const languageTranslations = useTranslation(DilTranslation);

  useEffect(() => {
    if (languageTranslations.translations) {
      const selectedKey = localStorage.getItem("language") ?? "tr";
      const selectedLanguage = languageTranslations.getWordsFor('key').find((item) => item.languageCode === selectedKey)?.value;
      setSelectedLang(selectedLanguage?.toLowerCase());
    }
  }, [languageTranslations.translations]);

  function updateLanguage(lang: string) {
    localStorage.setItem("language", lang);
    setSelectedLang(lang);
    window.location.reload();
  }

  return selectedLang == undefined ? (
    <></>
  ) : (
    <Popover placement="bottom-end" trigger={"click"}>
      <PopoverTrigger>
        <Button
          className="menu-title"
        >
          {selectedLang.toUpperCase()}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="menu-box">
        <FocusLock persistentFocus={false}>
          <PopoverArrow />
          {languageTranslations.getWordsFor("value").map((item, i) => {
            return (
              <PopoverBody
                key={item.value + i}
                className="menu-item menu-item-hover"
                role={"button"}
                onClick={() => {
                  updateLanguage(item.languageCode.toLowerCase());
                }}
              >
                <Button className="max-w-[220px] text-start !whitespace-break-spaces">
                  {item.value}
                </Button>
                <img src="/images/menu_arrow.svg" />
              </PopoverBody>
            );
          })}
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
}

export default CLanguageMenu;
