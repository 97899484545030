export function DocumentKisiselVerilerinAktarimi() {
    return <>
        <div className="documentContainer">
            <h1 className="documentHeader">Kişisel Verilerin Aktarımına İlişkin Politika</h1>

            <h2 className="documentTitle">1. KAPSAM</h2>
            <p className="documentText">
                Şirketimiz POFFT DİJİTAL ÇÖZÜMLER TİCARET ANONİM ŞİRKETİ (“Şirketimiz”) hukuka uygun olan kişisel veri işleme amaçları doğrultusunda gerekli güvenlik önlemlerini alarak kişisel veri sahibinin kişisel verilerini ve özel nitelikli kişisel verilerini üçüncü kişilere aktarabilmektedir. Şirketimiz bu doğrultuda, işlemekte olduğu kişisel verilerin üçüncü kişilere aktarımında 6698 sayılı Kişisel Verilerin Korunması Hakkında Kanun’da öngörülen yükümlülüklerine uygun şekilde hareket etmektedir.
            </p>
            <p className="documentText">
                İşbu Kişisel Verilerin Aktarımına İlişkin Politika (“Politika”), Şirketimiz tarafından toplanarak işlenmekte olan kişisel veriler ile özel nitelikli kişisel verilerin Türkiye’de yahut yurt dışında yerleşik üçüncü kişilere aktarılmasına ilişkin usul ve esasları düzenlemektedir.
            </p>

            <h2 className="documentTitle">2. KİŞİSEL VERİLERİN AKTARILMASI</h2>
            <p className="documentText">
                Aşağıda belirtilen şartlardan bir ya da birkaçının mevcut olmadığı durumlarda kural olarak ilgili kişinin kişisel verileri, sadece bu kişinin açık rızasının alınması halinde aktarılabilir. Ancak, kişinin açık rızası olmasa bile aşağıdaki şartlardan bir ya da birkaçının mevcut olması halinde, Şirketimiz tarafından azami özen gösterilerek ve Kişisel Verileri Koruma Kurulu (“Kurul”) tarafından öngörülen yöntemler de dahil gerekli tüm güvenlik önlemleri alınarak kişisel veriler iş ortaklarımız, tedarikçilerimiz, iştiraklerimiz, danışmanlarımız vb. gibi üçüncü kişilere aktarılmaktadır.
            </p>
            <ul className="documentList">
                <li>Kişisel verilerin aktarılmasına ilişkin ilgili faaliyetlerin kanunlarda açıkça öngörülmesi,</li>
                <li>Kişisel verilerin Şirketimiz tarafından aktarılmasının bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili ve gerekli olması,</li>
                <li>Kişisel verilerin aktarılmasının Şirketimizin hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,</li>
                <li>Kişisel verilerin veri sahibi tarafından alenileştirilmiş olması şartıyla, alenileştirme amacıyla sınırlı bir şekilde Şirketimiz tarafından aktarılması,</li>
                <li>Kişisel verilerin Şirket tarafından aktarılmasının Şirketimizin veya veri sahibinin veya üçüncü kişilerin haklarının tesisi, kullanılması veya korunması için zorunlu olması,</li>
                <li>Veri sahibinin temel hak ve özgürlüklerine zarar vermemek kaydıyla Şirketimizin meşru menfaatleri için kişisel veri aktarımı faaliyetinde bulunulmasının zorunlu olması,</li>
                <li>Fiili imkansızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünü koruması için zorunlu olması.</li>
            </ul>
            <h2 className="documentTitle">3. Kişisel Verilerin Yurt Dışına Aktarılması</h2>
            <p className="documentText">
                kişisel veriler, ilgili kişinin açık rızası olması durumunda Türkiye dışındaki ülkelere aktarılabilecektir. Yukarıda 1. maddede sayılan koşulların varlığı halinde ise, ilgili kişinin açık rızası olmayan durumlarda; kişisel veri aktarımının Kurul tarafından yeterli korumaya sahip olduğu ilan edilen yabancı ülkelerden birine yapılması veya yeterli korumanın bulunmaması durumunda ise Türkiye’deki ve ilgili yabancı ülkedeki veri sorumlularının yeterli bir korumayı yazılı olarak taahhüt etmesi ve Kurul’un izninin bulunması halinde, kişisel veriler yurtdışına aktarılabilecektir.
            </p>

            <h2 className="documentTitle">4. Özel Nitelikli Kişisel Verilerin Aktarılması</h2>
            <p className="documentText">
                Şirketimiz tarafından işlenen özel nitelikli kişisel veriler, Şirketimiz tarafından, işbu Politika’da belirtilen ilkelere uygun olarak ve Kurul’un belirleyeceği yöntemler <strong>de dahil olmak üzere gerekli her türlü idari ve teknik tedbirler alınarak ve aşağıdaki şartların varlığı halinde üçüncü kişilere aktarılabilecektir:</strong>
            </p>
            <ol className="documentList" style={{ listStyleType: 'lower-roman' }}>
                <li><strong>Sağlık ve cinsel hayat dışındaki özel nitelikli kişisel veriler,</strong> kanunlarda açıkça öngörülmesi halinde veri sahibinin açık rızası aranmaksızın ilgili üçüncü kişilere aktarılabilecektir. Aksi halde, veri sahibinin açık rızası alınacaktır.</li>
                <li><strong>Sağlık ve cinsel hayata ilişkin özel nitelikli kişisel veriler,</strong> kamu sağlığının korunması, koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi gibi amaçlarla sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurumlar tarafından aktarılabilir.</li>
            </ol>
            <p className="documentText">
                Şirketimiz çalışanlarına ait, sağlık verileri de dahil olmak üzere özel nitelikli kişisel veriler, Şirketimiz ile arasındaki hizmet ilişkisinin sürdürülmesi bakımından ve 6331 sayılı İş Sağlığı ve Güvenliği kanunu ile sair iş sağlığı ve güvenliği mevzuatı uyarınca, Şirketimiz işyeri hekimine ve Ortak Sağlık ve Güvenlik Birimi hizmeti veren firmaya aktarılmaktadır.
            </p>
            <p className="documentText">
                Özel nitelikli kişisel veriler, e-posta yoluyla aktarılması gerekiyorsa şifreli olarak kurumsal e-posta adresiyle veya KEP hesabı kullanılarak aktarılmalıdır. Taşınabilir bellek, CD, DVD gibi ortamlar yoluyla aktarılması gerekiyorsa kriptografik yöntemlerle şifrelenmeli ve kriptografik anahtar farklı ortamda tutulmalıdır. Farklı fiziksel ortamlardaki sunucular arasında aktarma gerçekleştiriliyorsa, sunucular arasında VPN kurularak veya sFTP yöntemiyle veri aktarımı gerçekleştirilmelidir. Kağıt ortamı yoluyla aktarımı gerekiyorsa evrakın çalınması, kaybolması ya da yetkisiz kişiler tarafından görülmesi gibi risklere karşı gerekli önlemler alınmalı ve evrak “gizli” formatta gönderilmelidir.
            </p>

            <h2 className="documentTitle">5. Yürürlük ve Güncel Tutma</h2>
            <p className="documentText">
                İşbu Politika, 01.05.2022 tarihinde yürürlüğe girmiş olup; Kanun, ilgili ikincil mevzuat, Kurul Kararları ve Şirketimiz iş süreçleri doğrultusunda KVK Komitesi tarafından her yıl başında yeniden değerlendirilecek ve gerekmesi halinde güncellenecektir.
            </p>

        </div>
    </>
}