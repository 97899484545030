import { Button } from "@chakra-ui/button";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageState } from "src/types/Context";
import { isDesktop } from "src/utils/window";
import { MainContext } from "../context/MainContext";
import OTopMenu from "../objects/OMenu";
import { CLoggedInTopbarMenuDesktop } from "./CLoggedInTopbarMenuDesktop";
import { CSignInUp } from "./CSignInUp";
import CTopbarMenu from "./CTopbarMenu";

function CTopBar() {
  const navigate = useNavigate();
  const mainContext = useContext(MainContext);

  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const topMenu = OTopMenu(mainContext!.getTranslation);

  return (
    <>
      {mainContext?.translationsLoaded && (
        <>
          <div className={`fixed z-50 w-[100vw] lg:w-full ${isAtSozlesmeler() ? 'bg-white' : ''}`}>
            <div
              className={`lg:m-auto flex justify-between  ${isDesktop() ? "main-width" : ""
                } py-[16px] main-padding `}
              style={
                scrollPosition > 0 || mobileMenuOpened
                  ? {
                    backgroundColor: `rgba(256,256,256,${(mobileMenuOpened ? 999 : scrollPosition) /
                      (isDesktop() ? 100 : 75)
                      })`,
                  }
                  : {}
              }
            >
              <img
                onClick={() => {
                  navigate("/");
                }}
                className="h-[49px]"
                src="/images/pofft_logo.svg"
              />
              {isDesktop() && (
                <>
                  {mainContext?.loggedIn ? (
                    <CLoggedInTopbarMenuDesktop />
                  ) : (
                    <div className="topbar_menu">
                      {topMenu.map(
                        (item, index) => (
                          <CTopbarMenu key={item.link + index} data={item} />
                        )
                      )}
                      <Button
                        className="text-purple text-14-16-600"
                        onClick={() => {
                          mainContext?.setSigninupState(PageState.Signin);
                        }}
                      >
                        Oturum Aç
                      </Button>
                      <Button
                        className="register"
                        onClick={() => {
                          mainContext?.setSigninupState(PageState.Signup);
                        }}
                      >
                        Üye Ol
                      </Button>
                    </div>
                  )}
                </>
              )}

              {!isDesktop() && (
                <div
                  onClick={() => {
                    setMobileMenuOpened(!mobileMenuOpened);
                  }}
                >
                  <img
                    src={
                      mobileMenuOpened
                        ? "/images/burger-close.svg"
                        : "/images/burger-menu.svg"
                    }
                  />
                </div>
              )}
            </div>
            {
              <div
                style={
                  mobileMenuOpened
                    ? { backgroundColor: "#00000033", height: "100vh" }
                    : {}
                }
              >
                <div
                  className="burger-menu"
                  style={
                    mobileMenuOpened
                      ? { height: "360px" }
                      : {
                        height: "0px",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                      }
                  }
                >
                  <Button
                    className="register"
                    onClick={() => {
                      setMobileMenuOpened(false);
                      mainContext?.setSigninupState(PageState.Signup);
                    }}
                  >
                    Üye Ol
                  </Button>
                  <Button
                    className="text-purple text-14-16-600"
                    onClick={() => {
                      setMobileMenuOpened(false);
                      mainContext?.setSigninupState(PageState.Signin);
                    }}
                  >
                    Oturum Aç
                  </Button>
                  {topMenu.map((item, index) => (
                    <div key={item.link + index}>
                      <CTopbarMenu
                        isMobile={true}
                        data={item}
                        onClicked={() => {
                          setMobileMenuOpened(false);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            }
          </div>
          <CSignInUp />
        </>
      )}
    </>
  );

  function isAtSozlesmeler() {
    return window.location.pathname.includes('/sozlesmeler');
  }
}

export default CTopBar;
