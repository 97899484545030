import {dateStrToString} from "../utils/date";
import {cities, countries, workingTypes} from "../service/commonRequest";
import {parseCompanyJobOfferResponse, TProjectOffer} from "./TProjectOffer";

export enum JobStatusInt {
  Waiting,
  Confirmed,
  Refuse,
  CompanyUpdateOffer,
  ProfessionalUpdateOffer,
}

export enum JobStatus {
  Waiting = 'Waiting',
  Confirmed = 'Confirmed',
  Refuse = 'Refuse',
  CompanyUpdateOffer = 'CompanyUpdateOffer',
  ProfessionalUpdateOffer = 'ProfessionalUpdateOffer',
  WaitingForPayment = 'ConfirmedProcessing',
  ConfirmedCanceled = 'ConfirmedCanceled',
  ConfirmedCompleted = 'ConfirmedCompleted',
}

export enum ProjectOfferUpdate {
  Waiting = 0,
  Confirmed = 1,
  Refuse = 2,
  CompanyUpdateOffer = 3,
  ProfessionalUpdateOffer = 4
}

export function isOfferableJobStatus(status: JobStatus): boolean {
  return status == JobStatus.ProfessionalUpdateOffer || status == JobStatus.CompanyUpdateOffer || status == JobStatus.Waiting
}

export function isActiveJobStatus(status: JobStatus): boolean {
  return status == JobStatus.Confirmed || status == JobStatus.WaitingForPayment
}

export function isCompletedJobStatus(status: JobStatus): boolean {
  return status == JobStatus.ConfirmedCompleted
}


export interface TJobWithOffer extends TJob {
  offer: TProjectOffer
}

export interface TJob {
  id: string | number,
  title: string,
  firm: string,
  workType: string,
  startDate?: string,
  startDateObj?: Date,
  endDate?: string,
  endDateObj?: Date,
  description: string,
  location?: string,
  medias?: string[],
  visible: boolean,
  status: JobStatus
  statusStr?: string
  price?: number
  companyRequestPrice?: number
  currencyID?: number,
  projectKeyID?: string
  companyKeyID?: string
  priceRange?: number[]
}

export function parseJobsResponse(responseData: any, halenText?: string): TJob[] {
  let map = responseData.map((item: any): TJob | undefined => parseJobResponse(item, halenText)).filter((item: any) => item != undefined);
  return map ?? []
}

export function parseJobsWithOfferResponse(responseData: any, halenText?: string): TJobWithOffer[] {
  let map = responseData.filter((item: any) => item.project != undefined).map((item: any): TJobWithOffer | undefined => {
      const job = parseJobResponse(item, halenText);
      if (job == undefined) {
        return undefined
      }
      return {
        ...job,
        offer: parseCompanyJobOfferResponse(item)
      }
    }
  ).filter((item: any) => item != undefined);
  return map ?? []
}

export function parseJobResponse(item: any, halenText?: string): TJob | undefined {
  if (item == undefined) {
    return undefined
  }
  let country = countries.find((c) => c.id == item.project.countryID)?.label;
  let city = cities.find((c) => c.id == item.project.cityID)?.label;
  let workType = workingTypes.find((c) => ("" + c.id) == item.project.workingTypes[0])?.label;
  let startDateStr = item.project.transactionDate ? dateStrToString(item.project.transactionDate) : '-';
  let startDateObj = item.project.transactionDate ? new Date(item.project.transactionDate) : undefined;
  let endDateStr = item.project.deadline ? dateStrToString(item.project.deadline) : (halenText ?? '-');
  let endDateObj = item.project.deadline ? new Date(item.project.deadline) : undefined;
  let priceRange: number[] = [];
  if (item.project.priceRangeBegin != undefined && item.project.priceRangeEnd != undefined) {
    priceRange = [item.project.priceRangeBegin, item.project.priceRangeEnd];
  }
  return {
    // id: item.projectOfferKeyID,
    id: item.projectKeyID,
    currencyID: item.currencyID,
    title: item.project.name,
    firm: item.companyStr??'',
    workType: workType ?? '',
    startDate: startDateStr,
    startDateObj: startDateObj,
    endDate: endDateStr,
    endDateObj: endDateObj,
    description: item.project.remark,
    location: (city ? (city + ', ') : '') + (country ?? ''),
    medias: item.medias ?? [],
    visible: item.visible ?? false,
    status: item.status,
    statusStr: item.statusStr,
    price: item.price ?? 0,
    companyRequestPrice: item.companyRequestPrice ?? 0,
    projectKeyID: item.projectKeyID,
    companyKeyID: item.companyKeyID,
    priceRange: priceRange,
  }
}
